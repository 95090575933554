import {
  Box,
  CardMedia,
  Divider,
  Grid,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import defaultProfileImg from "../../assets/image/defaultProfileImg.png";
import {
  AnalyticsIcon,
  CommentsIcon,
  LikeIcon,
  LinkIcon,
  VisibilityIcon,
} from "../../utils/materialIcon";
import materialIcons from "../../utils/materialIcon";
import { formatDate } from "../../utils/common";
const PostDetails = ({ data, name, userData }) => {
  console.log("userData", userData);

  return (
    <Box
      minWidth={336}
      maxWidth={400}
      //   height={578}
      borderRadius={"12px"}
      boxShadow={{
        xs: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
      }}
      paddingTop={"16px"}
    >
      <div
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          display: "flex",
          flexDirection: "row",
          marginBottom: "12px"
        }}
      >
        <div
          style={{
            display: "flex"
          }}
        >
          <img
            src={`${userData?.[0].profileImage}`}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
          />
        </div>
        <div
          style={{
            borderRadius: "18px",
            background: " rgba(202, 196, 208, 0.20)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px",
            paddingLeft: "16px",
            paddingRight: "16px"
          }}
        >
          <span
            style={{
              color: "var(--Schemes-On-Surface, #1D1B20)",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "var(--Label-Large-Line-Height, 20px)",
            }}
          >
            {userData?.[0]?.firstName}
          </span>
        </div>
      </div>
      {
        name.toLowerCase() !== "story" && (
          <CardMedia
            className="c-bookingCardMedia"
            component="img"
            alt="green iguana"
            image={data?.permalink || defaultProfileImg}
            sx={{
              widows: "100%",
              height: 339,
            }}
          />
        )
      }
      <Stack p={"24px 28px 6px 28px "} gap={"12px"}>
        <Stack direction={"row"} alignItems={"center"}>
          <AnalyticsIcon sx={{ color: "#c0bdc6" }} />
          <Typography color={"#79747E"} fontSize={16} fontWeight={500}>
            Performance
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            sx={{
              ml: "auto",
              backgroundColor: "#edeced",
              p: "6px 16px 6px 8px",
              borderRadius: "20px",
            }}
          >
            <materialIcons.clockIcon sx={{ color: "#625B71" }} />
            <Typography color={"#625B71"} fontSize={12} fontWeight={400}>
              {formatDate(data?.timestamp, "DD MMM")}
            </Typography>
          </Box>
        </Stack>
        <Divider />
        <Box>
          <Grid
            container
            columnSpacing={1.5} // Gap between columns
            rowSpacing={1.5}    // Gap between rows
            mb={"12px"}
            style={{ margin: 0, width: "100%" }} // Removes container padding
          >
            {/* Views */}
            <Grid item xs={6} sm={6}>
              <Box
                sx={{
                  borderRadius: "20px",
                  background: "rgba(73, 69, 79, 0.05)",
                  display: "flex",
                  width: "max-content",
                  height: "32px",
                  padding: "6px 16px 6px 8px",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <VisibilityIcon className="visibilityIcon" />
                <Typography color={"#8F3A98"} fontSize={12} fontWeight={400}>
                  View
                </Typography>
                <Typography color={"#8F3A98"} fontSize={12} fontWeight={700}>
                  {data?.view_count}
                </Typography>
              </Box>
            </Grid>

            {/* Comments */}
            <Grid item xs={6} sm={6}>
              <Box
                sx={{
                  borderRadius: "20px",
                  background: "rgba(73, 69, 79, 0.05)",
                  display: "flex",
                  width: "max-content",
                  height: "32px",
                  padding: "6px 16px 6px 8px",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <CommentsIcon sx={{ color: "#8F3A98" }} />
                <Typography color={"#8F3A98"} fontSize={12} fontWeight={400}>
                  Comments
                </Typography>
                <Typography color={"#8F3A98"} fontSize={12} fontWeight={700}>
                  {data?.comments_count}
                </Typography>
              </Box>
            </Grid>

            {/* Likes */}
            <Grid item xs={6} sm={6}>
              <Box
                sx={{
                  borderRadius: "20px",
                  background: "rgba(73, 69, 79, 0.05)",
                  display: "flex",
                  width: "max-content",
                  height: "32px",
                  padding: "6px 16px 6px 8px",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <SvgIcon component={LikeIcon} sx={{ mt: 1, color: "#8F3A98" }} />
                <Typography color={"#8F3A98"} fontSize={12} fontWeight={400}>
                  Likes
                </Typography>
                <Typography color={"#8F3A98"} fontSize={12} fontWeight={700}>
                  {data?.like_count || 0}
                </Typography>
              </Box>
            </Grid>

            {/* Shares */}
            <Grid item xs={6} sm={6}>
              <Box
                sx={{
                  borderRadius: "20px",
                  background: "rgba(73, 69, 79, 0.05)",
                  display: "flex",
                  width: "max-content",
                  height: "32px",
                  padding: "6px 16px 6px 8px",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <VisibilityIcon className="visibilityIcon" />
                <Typography color={"#8F3A98"} fontSize={12} fontWeight={400}>
                  Shared
                </Typography>
                <Typography color={"#8F3A98"} fontSize={12} fontWeight={700}>
                  {data?.share_count || 0}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Link */}
          <Box mt={"12px"} mb={"6px"}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <LinkIcon />
              </Grid>
              <Grid item xs>
                <Typography
                  title={data?.permalink}
                  className="textEllipsis"
                  color={"#8F3A98"}
                  fontSize={12}
                  fontWeight={700}
                >
                  <Link
                    href={`${data?.permalink}`}
                    underline="hover"
                    target="_blank"
                    rel="noopener"
                  >
                    {data?.permalink}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>


      </Stack>
    </Box >
  );
};
export default PostDetails;
