import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  influencerOfferFilter: "new",
  bookingFilter: "requestToBusiness",
  companyOfferFilter: "active",
  companyOfferDetailsFilter: "requestToBusiness",
};
export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setInfluencerOfferFilter: (state, action) => {
      state.influencerOfferFilter = action?.payload;
    },
    setBookingFilter: (state, action) => {
      state.bookingFilter = action?.payload;
    },
    setCompanyOfferFilter: (state, action) => {
      state.companyOfferFilter = action?.payload;
    },
    setCompanyOfferDetailsFilter: (state, action) => {
      state.companyOfferDetailsFilter = action?.payload;
    },
  },
});
export const {
  setInfluencerOfferFilter,
  setBookingFilter,
  setCompanyOfferFilter,
  setCompanyOfferDetailsFilter,
} = filterSlice.actions;
export default filterSlice.reducer;
