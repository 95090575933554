import {
  Box,
  Button,
  CardMedia,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import MyButton from "../../MyButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  useGetOfferByIdMutation,
  useGetOffersMutation,
  useUpdateBookingStatusMutation,
} from "../../../services/apiService/userApiService";
import useNotification from "../../../hooks/usePushNotification";
import useLoading from "../../../hooks/useLoading";
import { useEffect, useState } from "react";
import toaster from "../../Toaster/toaster";
import { formatDate } from "../../../utils/common";
import { VisibilityIcon } from "../../../utils/materialIcon";
import CompanyBookings from "../../bookings/CompanyBookings";
import { notificationCode, offerFilterCode } from "../../../utils/constants";
import BookingDetails from "../Booking/BookingDetails";
import InfluencerOfferView from "../../Influencer/Offers/InfluencerOfferView";
import { setCompanyOfferDetailsFilter } from "../../../store/Slicers/filterSlice";

const NewCompanyOfferView = ({ isOffer }) => {
  const { offerId } = useParams(); // To get the offer id from URL
  const navigate = useNavigate(); // To navigate
  const { userDtls, instagramData } = useSelector((state) => state?.auth); //To get user details from store
  const dispatch = useDispatch();
  const { companyOfferDetailsFilter } = useSelector((state) => state?.filter);
  const [getOffers] = useGetOffersMutation();
  const [getOfferById] = useGetOfferByIdMutation(); // API to fetch the offer by user and offer id
  const [updateBookingStatus] = useUpdateBookingStatusMutation(); // API to update the booking status
  const { sendNotification } = useNotification(); //To sent notification
  const { startLoading, stopLoading } = useLoading(); // To loader
  const [offerTitle, setOfferTitle] = useState("");
  const [offerDtls, setOfferDtls] = useState({}); // Offer details
  const [showOfferDetails, setShowOffersDetails] = useState(false);
  const [tabName, setTabName] = useState("status");

  /**
   * To fetch offer by id
   */
  const fetchOfferById = async (id) => {
    try {
      startLoading();
      const response = await getOfferById({
        offerId: id,
        influencerId: userDtls?.userId,
      }).unwrap();
      if (response?.data) {
        setOfferTitle(response?.data?.[0]?.title);
        offersListFormatter(response.data, setOfferDtls, true);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To update booking status
   */
  const handlerUpdateBookingStatus = async (e, data, type) => {
    try {
      startLoading();
      e.stopPropagation();
      e.preventDefault();
      let payload = {
        offerId: data?._id,
        influencerId: data?.userId,
        newStatus: type || "offeredToInfluencer",
        message: notificationCode?.offerAccepted,
      };
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        if (response?.data.message) {
          toaster("error", response?.data.message)
          return
        }
        fetchOfferById(offerId);
        sendNotification({
          senderId: userDtls?.userId,
          recipientId: data.influencerDetails.userId,
          message: type
            ? notificationCode?.offerCompletedByCompany
            : notificationCode?.offerAccepted,
          isRead: false,
          type: "remainder",
        });
      } else {
        toaster("error", response?.data?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle report offer
   */
  useEffect(() => {
    if (offerId) {
      fetchOfferById(offerId);
    }
  }, [offerId]);

  /**
   * To handler fetch offers list
   */
  const handlerFetchOffers = async (setList, businessId, userId) => {
    try {
      startLoading();
      let filter = {};
      filter.status = "active";
      if (businessId) {
        filter.businessId = businessId;
      }
      if (userId) {
        filter.influencerId = userId;
      }
      filter.followerCount = instagramData?.followers_count1 || 0;
      const response = await getOffers({
        id: userDtls?.userId,
        filter,
      }).unwrap();
      if (response?.data) {
        if (businessId) {
          offersListFormatter(
            response?.data?.filter((ele) => ele?._id !== offerId),
            setList
          );
        } else {
          const arr = response?.data?.filter((ele) => ele?._id !== offerId);
          if (arr.length > 3) {
            offersListFormatter(arr.slice(0, 3), setList);
          }
        }
      } else {
        setList([]);
      }
    } catch (err) {
      setList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  const offersListFormatter = (data, setList, isObject) => {
    const formattedData = data?.map((item) => {
      let ele = { ...item };
      if (ele?.isRequestToBusiness) {
        ele.offerStatus = "pending";
      } else if (ele?.isOfferedToInfluencer) {
        ele.offerStatus = "check";
      } else if (ele?.isCompletedByInfluencer) {
        ele.offerStatus = "complete";
      } else {
        ele.offerStatus = "accept";
      }
      return ele;
    });
    if (isObject) {
      setList(formattedData[0]);
    } else {
      setList(formattedData);
    }
  };
  /**
   * To set the tab name
   */
  const handleSetTabName = (tabName) => {
    setTabName(tabName);
  };
  /**
   * To set the filter code
   */
  const handleFilterCode = (tabName) => {
    dispatch(setCompanyOfferDetailsFilter(tabName));
  };
  /**
   * To close the offer details modal
   */

  const handleCloseOfferDetails = () => {
    setShowOffersDetails(false);
  };
  return (
    <Box sx={{ height: 92, py: { sm: "6px", xs: "8px" } }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)", paddingY: "6px" }}
      >
        <MyButton
          //   className="w-ColorBlack"
          color="#1D1B20"
          type="backButton"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            paddingLeft: "12px"
          }}
        />
        <CardMedia
          className="bookingCardMedia hideOnMobile"
          component="img"
          alt="green iguana"
          height={"80px"}
          width={"80px"}
          image={offerDtls?.offerImages?.[0]}
          sx={{ width: "80px", borderRadius: "6px", minWidth: "80px" }}
        />
        <Typography
          title={offerDtls?.title}
          className={` textEllipsis offerTitle `}
          margin={" 0px 0px 0px 3px"}
          width={"250px"}
          maxWidth={"500px"}
          color={"text.main"}
          fontWeight="400"
          fontSize={module === "booking" ? "14px" : "20px"}
          variant="body1"
          textTransform={"capitalize"}
        >
          {offerDtls?.title}
        </Typography>
        <Typography
          className="hideOnMobile"
          display={"flex"}
          variant="body1"
          fontSize={14}
          fontWeight={700}
          color="text.secondary"
          height={"32px"}
          width={"max-content"}
          alignItems={"center"}
          justifyContent={"center"}
          border={1}
          borderColor={"#CAC4D0"}
          borderRadius={20}
          sx={{ ml: "auto" }}
          padding={"6px 8px"}
        >
          {`${formatDate(
            offerDtls?.availableDates?.from,
            "DD/MM"
          )}-${formatDate(offerDtls?.availableDates?.to, "DD/MM")}`}
        </Typography>
        <Typography
          className="influencerBooking hideOnMobile"
          padding={"6px 12px"}
          display={"flex"}
          variant="body1"
          fontSize={14}
          fontWeight={500}
          color="text.secondary"
          height={"32px"}
          maxWidth={"97px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={20}
          bgcolor={
            offerDtls?.spots?.booked ? "#feedad" : "rgba(176, 220, 255, 0.50)"
          }
        >
          {`${offerDtls?.spots?.booked}/${offerDtls?.spots?.availableSpots} `}
          <span style={{ marginLeft: "2px" }} className="hideOnMobile">
            booked
          </span>
        </Typography>
        <IconButton
          sx={{
            // "&:hover": {
            //   backgroundColor: "#8F3A98",
            // },
            width: 40,
            height: 40,
            display: "flex",
          }}
          color="white"
          onClick={() => {
            // handlerChat(column);
          }}
        >
          <VisibilityIcon
            className="visibilityIconDark"
            onClick={() => setShowOffersDetails(true)}
          />
        </IconButton>
        <Button
          // onClick={handleClick}
          sx={{
            height: "32px",
            ml: {
              xs: "auto",
              sm: 0,
            },
          }}
        //   className="w-ColorBlack"
        >
          <MoreVertIcon sx={{ color: "#1D1B20" }} />
        </Button>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Stack direction={"row"} sx={{ m: { md: " 6px 40px", xs: "5px 8px" } }}>
          <MyButton
            onClick={() => handleSetTabName("status")}
            className={`filterBtn btn ${tabName === "status" ? "filterActiveBtn" : "inactive-btn"
              }`}
            color={"black"}
            sx={{
              //   minWidth: "50px",
              fontSize: "14px",
              fontWeight: 400,
              width: {
                xs: "50%",
                sm: "fit-content",
              },
            }}
            variant="text"
          >
            Status
          </MyButton>
          <MyButton
            onClick={() => handleSetTabName("performance")}
            className={` btn ${tabName === "performance" ? "filterActiveBtn" : ""
              }`}
            color={"black"}
            sx={{
              //   sm: { width: "60px" },
              fontSize: "14px",
              fontWeight: 400,
              width: {
                xs: "50%",
                sm: "fit-content",
              },
            }}
            variant="text"
          >
            Performance
          </MyButton>
        </Stack>
      </Box>
      <Box>
        {tabName === "status" && (
          <Box>
            <Stack
              direction={"row"}
              gap={2}
              sx={{ m: { md: " 6px 40px", xs: " 8px 12px" }, height: 32 }}
            >
              <Button
                onClick={() => handleFilterCode("requestToBusiness")}
                className={`${companyOfferDetailsFilter === "requestToBusiness"
                    ? "activeFilter"
                    : "inactiveFilter"
                  }`}
                fontSize={"14px"}
                sx={{
                  textTransform: "none",
                  borderRadius: "8px",
                  boxShadow:
                    "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                  //   bgcolor: tabName === "status" ? "#f4ebf5" : "white",
                }}
              >
                Pending
              </Button>
              <Button
                className={`${companyOfferDetailsFilter === "offeredToInfluencer"
                    ? "activeFilter"
                    : "inactiveFilter"
                  }`}
                onClick={() => handleFilterCode("offeredToInfluencer")}
                fontSize={"14px"}
                sx={{
                  textTransform: "none",
                  borderRadius: "8px",
                  boxShadow:
                    "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                }}
              >
                Active
              </Button>
              <Button
                className={`${companyOfferDetailsFilter === "completedByInfluencerUsers"
                    ? "activeFilter"
                    : "inactiveFilter"
                  }`}
                onClick={() => handleFilterCode("completedByInfluencerUsers")}
                fontSize={"14px"}
                sx={{
                  textTransform: "none",
                  borderRadius: "8px",
                  boxShadow:
                    "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                  //   bgcolor: tabName === "complete" ? "#f4ebf5" : "white",
                }}
              >
                Completed
              </Button>
            </Stack>
            <Box sx={{ m: { md: " 6px 40px", xs: "8px" } }}>
              {offerDtls[companyOfferDetailsFilter]?.map((ele) => {
                return (
                  <CompanyBookings
                    key={ele?._id}
                    data={ele}
                    isOfferModule={true}
                    tabValue={companyOfferDetailsFilter}
                    offerTitle={offerTitle}
                    // showApproveReject={
                    //   offerFilterCode[companyOfferDetailsFilter] === "requestToBusiness"
                    // }
                    handlerUpdateBookingStatus={handlerUpdateBookingStatus}
                  // handlerFetchOffers={fetchBookingData}
                  // children={getChildren(ele)}
                  // className={"bookingTitle"}
                  // ctnClassName={"bookingOfferListView"}
                  ></CompanyBookings>
                );
              })}
            </Box>
            {offerDtls[offerFilterCode[companyOfferDetailsFilter]]?.length ===
              0 && (
                <Box
                  mt={10}
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontWeight="700" variant="body1" color="primary">
                    No data found.
                  </Typography>
                </Box>
              )}
          </Box>
        )}
        {tabName === "performance" && (
          <Box>
            <BookingDetails isCard={true} />
          </Box>
        )}
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showOfferDetails}
        onClose={handleCloseOfferDetails}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        // height={"calc(100vh - 66px)"}
        sx={{ backgroundColor: "white" }}
        className="offerViewModal"
      >
        <InfluencerOfferView
          offerIdByModal={offerId}
          handleCloseOfferDetails={handleCloseOfferDetails}
        />
      </Modal>
    </Box>
  );
};
export default NewCompanyOfferView;
