import { Box, Button, Stack, Typography } from "@mui/material";
import OTPInput from "../../components/OTPInput/OTPInput";
import MyButton from "../../components/MyButton";
import { useSelector } from "react-redux";
import materialIcons from "../../utils/materialIcon";

const EmailOtpVerification = ({
  otp,
  setOtp,
  email,
  handlerGenerateOtp,
  handlerVerifyOtp,
  handleBack,
}) => {
  const { userDtls } = useSelector((state) => state.auth);
  return (
    <Box
      height={" calc(100% - 240px)"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Typography
        variant="body2"
        padding={1}
        textAlign="center"
        letterSpacing={"-0.01em"}
        fontWeight={500}
        mt={5}
        fontSize={"16px"}
        color={"primary"}
      >
        One-time password
      </Typography>
      <Typography
        variant="body2"
        padding={1}
        textAlign="center"
        letterSpacing={"-0.01em"}
        mb={2}
        sx={{ fontSize: "14px", fontWeight: 400 }}
        color={"primary"}
      >
        {/* We sent a code to {email || userDtls?.email} */}
        Fill the password we just sent you
      </Typography>
      {/* <Box
        component="section"
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{ width: "100%" }}
        padding={1}
      > */}
      <OTPInput otp={otp} setOtp={setOtp} />
      <Typography
        variant="body2"
        padding={1}
        textAlign="center"
        letterSpacing={"-0.01em"}
        fontWeight={700}
      >
        <Box
          component="section"
          display="flex"
          flexDirection={"row"}
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", fontSize: "16px", fontWeight: 400 }}
          padding={1}
        >
          Don't receive the OTP?
          <MyButton
            sx={{ fontSize: "16px", fontWeight: 400 }}
            variant={"text"}
            onClick={handlerGenerateOtp}
          >
            Resent OTP
          </MyButton>
        </Box>
      </Typography>
      <Stack marginTop={"auto"} direction={"row"} gap={2}>
        <>
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "capitalize",
              color: "primary.main",
              height: 56,
              width: 162,
              borderRadius: 4,
              bgcolor: "white.main",
            }}
            size="large"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            endIcon={<materialIcons.rightArrow />}
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "capitalize",
              height: 56,
              width: 162,
              borderRadius: 4,
            }}
            size="large"
            onClick={handlerVerifyOtp}
            disabled={!(otp?.length > 5)}
          >
            Verify
          </Button>
        </>
      </Stack>
    </Box>
    // </Box>
  );
};

export default EmailOtpVerification;
