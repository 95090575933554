import * as React from "react";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import TodayIcon from "@mui/icons-material/Today";
import MyButton from "../../MyButton";
import { formatDate } from "../../../utils/common";
import { useSelector } from "react-redux";
import { useSaveOfferMutation } from "../../../services/apiService/userApiService";
import toaster from "../../Toaster/toaster";
import materialIcons, {
  BookMarkIcon,
  SavedBookMarkIcon,
} from "../../../utils/materialIcon";
import completedIcon from "../../../assets/Icons/completedIcon.png";
import "../../../style/CommonStyleNew.css";
import { SvgIcon } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, useMediaQuery, useTheme } from "@mui/material";
const PhotoView = ({
  handleSelectOffersCard,
  data,
  handlerFetchOffers,
  handlerUpdateBookingStatus,
  isSimilarView = false,
  noOption,
  isSavedOffer,
  HomePagePhotoCardCtn,
  isHome
}) => {
  const navigate = useNavigate();
  const { userDtls } = useSelector((state) => state?.auth);
  const location = useLocation();
  const [saveOffer] = useSaveOfferMutation();
  const isMobile = useMediaQuery("(max-width:768px)");
  // ! changes here
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handlerSaveOffer = async (e) => {
    try {
      e?.stopPropagation();
      e?.preventDefault();
      const payload = {
        offer_id: data?._id,
        // newStatus: "offeredToInfluencer",
        user_id: userDtls?.userId,
      };
      const response = await saveOffer(payload).unwrap();
      if (response?.success) {
        handlerFetchOffers(true);
        toaster("info", "Offer successfully saved");
      } else {
        toaster("info", "Offer failed to saved");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to save offer:", err);
        toaster("error", "Something went wrong");
      }
    }
  };
  return (
    <>
      <Box
        onClick={(e) => {
          navigate(`/offers/${data?._id}`);
        }}
        className={
          isSimilarView
            ? `similarPhotoCardCtn ${HomePagePhotoCardCtn && "HomePagePhotoCardCtn"
            } `
            : "PhotoCardCtn"
        }
        variant="contained"
        component="section"
        display="flex"
        flexDirection={"column"}
        // p={"5px"}
        sx={{
          boxShadow: 2,
          // p: 1,
          borderRadius: "4px",
          // margin: "5px",
          bgcolor: "white.main",
          overflow: "unset",
          position: "relative",
        }}
      >
        {/* Top Section - Image with overlay icons */}
        <Box sx={{ height: "100%", position: "relative" }}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
            className="cardMediaCtn"
          >
            {/* <CarouselView
            handleSelectOffersCard={handleSelectOffersCard}
            imageList={data?.offerImages}
          /> */}
            <CardMedia
              // onClick={
              // module === "offer" ? () => handleSelectOffersCard(data) : null
              // }
              className="CardMedia"
              component="img"
              alt="green iguana"
              width={"100%"}
              image={data?.offerImages?.[0]}
              sx={{
                borderRadius: "6px",
                objectFit: "cover",
                height: `${location.pathname === "/offers" &&
                  userDtls.type === "influencer" &&
                  isMobile
                  ? "310px"
                  : "100%"
                  }`,
              }}
            />
            {/* Save icon and buttons on top */}

            {(
              <Box
                // className="card-tag"
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                sx={{
                  p: 1,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  // bgcolor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 11,
                  // backdropFilter: "blur(1px)",
                  // background: "rgba(255, 255, 255, 0.2)",
                }}
              >
                {isSimilarView && !HomePagePhotoCardCtn && !isMobile && <CheckIcon sx={{ ml: "auto" }} className="mobileTextPrimary" />}
                {(!isSimilarView) && (
                  <>
                    <MyButton
                      // className="hideOnMobile  "
                      // className="hideOnWeb"
                      sx={{
                        height: "32px",
                        boxShadow: 0,
                        // padding: "8px",
                        bgcolor: "rgba(0, 0, 0,0)",
                        ml: "auto",
                      }}
                      startIcon={<TodayIcon color="white" />}
                    >
                      <Typography
                        color="white.main"
                        // className="hideOnMobile  "
                        display={"flex"}
                        variant="body1"
                        fontSize={12}
                        fontWeight={700}
                        font-family={"Roboto Mono"}
                        height={"32px"}
                        // width={"89px"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        {`${formatDate(
                          data?.availableDates?.from,
                          "DD/MM"
                        )} - ${formatDate(data?.availableDates?.to, "DD/MM")}`}
                      </Typography>
                    </MyButton>

                    {HomePagePhotoCardCtn && (
                      <IconButton
                        role="button"
                        sx={{
                          height: "32px",
                          padding: "8px",
                          bgcolor: "rgba(0, 0, 0,0)",
                        }}
                        color="white"
                        onClick={(e) => handlerSaveOffer(e)}
                      ></IconButton>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>


        </Box>
        {(location.pathname === "/home" || userDtls?.type === "influencer") && (
          <div
            style={{
              position: "absolute",
            }}
          >
            <IconButton
              role="button"
              sx={{
                height: "32px",
                padding: "8px",
                bgcolor: "rgba(0, 0, 0,0)",
              }}
              color="white"
              onClick={(e) => handlerSaveOffer(e)}
            >
              {isSavedOffer ? (
                <SvgIcon component={SavedBookMarkIcon} sx={{ mr: 1 }} />
              ) : (
                <SvgIcon component={BookMarkIcon} sx={{ mr: 1 }} />
              )}
            </IconButton>
          </div>
        )}
        <Box
          className="photoViewSection2"
        >
          {(((isMobile && isHome && (!isSimilarView)) || (!isMobile && isHome) || (isMobile && !isHome)) || (!isMobile && !isSimilarView && !isHome) || (isSimilarView && !isHome && !isMobile)) && (
            <>
              <Typography
                className={`photCardTitle  ${!isSimilarView && "textWhite"}`}
                fontWeight="600"
                fontSize={16}
                variant="body1"
                color={`${location.pathname === "/offers" &&
                  userDtls.type === "influencer" &&
                  isMobile
                  ? "black.main"
                  : "white.main"
                  }`}
              >
                {data?.title}
              </Typography>
              <Typography
                className="mobile-WidthFitContent photoViewBookingBtn "
                padding={"6px 12px"}
                display={"flex"}
                variant="body1"
                fontSize={14}
                fontWeight={500}
                // width={"89px"}
                ml={"auto"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={20}
                bgcolor={
                  data?.spots?.booked ? "#feedad" : "rgba(176, 220, 255, 0.50)"
                }
              >
                {`${data?.spots?.booked}/${data?.spots?.availableSpots} `}
                <span style={{ marginLeft: "2px" }} className="hideOnMobile">
                  booked
                </span>
              </Typography>
              <Box
                width={"140px"}
                display={"flex"}
                height={32}
                alignItems={"center"}
                justifyContent={"center"}
                className="companyName"
              >
                <Typography
                  className={`textEllipsis mobile-WidthFitContent ${!isSimilarView && "textWhite"
                    }`}
                  fontWeight="500"
                  fontSize={"12px"}
                  variant="body1"
                  // marginLeft={"auto"}
                  color={`${location.pathname === "/offers" &&
                    userDtls.type === "influencer" &&
                    isMobile
                    ? "black.main"
                    : "white.main"
                    }`}
                  borderRadius={20}
                >
                  {`by ${data?.createdBy?.[0]?.username ||
                    data?.companyDetails?.username ||
                    "Company"
                    }`}
                </Typography>
              </Box>
              {((!isSimilarView || (isHome && !isMobile)) || (isHome && isMobile && !isSimilarView)) && (
                <MyButton
                  onClick={(e) => handlerUpdateBookingStatus(e, data)}
                  className={`checkIcon btn list-btn mobileTextPrimary ${(isMobile && isHome) ? "photoStatusBtnBlack" : "photoStatusBtn"}`}
                  // color={offerStatusCode[data?.offerStatus]?.bg}
                  sx={{
                    color: "white.main",
                    borderRadius: "50px",
                    fontWeight: 400,
                    fontSize: "12px",
                    height: "46px",
                    // width: "124px",
                    // bgcolor: "black.main",
                    boxShadow: 0,
                    marginLeft: `${isMobile ? "100px" : ""}`
                  }}
                  minWidth={"40px"}
                  disabled={!(data?.offerStatus === "accept")}
                >
                  <Box m={"8px 5px 0 0"}>
                    {data?.offerStatus === "accept" ? (
                      <CheckIcon className="mobileTextPrimary" />
                    ) : data?.offerStatus === "pending" ? (
                      <materialIcons.clockIcon className="mobileTextPrimary" />
                    ) : (
                      <img
                        alt="loading"
                        src={completedIcon}
                        className="mr-auto "
                      ></img>
                    )}
                  </Box>
                  <span className="mobileTextPrimary">
                    {data?.offerStatus || "test"}
                  </span>
                </MyButton>
              )}
            </>
          )}
        </Box>

        {/* Button Section */}
        {/* {!isSimilarView && (
        <Box
          sx={{ height: "12%", p: 1, textAlign: "center" }}
          className="checkIcon btn"
        >
          <MyButton
            onClick={(e) => handlerUpdateBookingStatus(e, data)}
            variant="contained"
            color="primary"
            sx={{ borderRadius: "20px", width: "100%" }}
            startIcon={<CheckIcon />}
            disabled={!(data?.offerStatus === "accept")}
          >
            {data?.offerStatus || "test"}
          </MyButton>
        </Box>
      )} */}
      </Box>
    </>
  );
};

export default PhotoView;
