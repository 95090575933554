import { Box, Button, Modal, Stack, styled, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import toaster from "../../components/Toaster/toaster";
import useLoading from "../../hooks/useLoading";
import React, { useState } from "react";
import {
  useUpdateUserProfileMutation,
  useUploadImageMutation,
} from "../../services/apiService/userApiService";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../store/Slicers/authSlice";
import { useGetInstagramDataByFieldsMutation } from "../../services/apiService/socialMediaApiService";

const UploadProfile = ({ setStep }) => {
  const dispatch = useDispatch();
  const [imageUpload] = useUploadImageMutation();
  const [updateProfileImage] = useUpdateUserProfileMutation();
  const [fetchInstagramImage] = useGetInstagramDataByFieldsMutation();
  const { userDtls } = useSelector((state) => state?.auth);
  const [openImageModal, setOpenImageModal] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const [uploadFile, setUploadFile] = useState(null);
  const [imageList, setImageList] = useState([]);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  /**
   * To fetch instagram image
   */
  const getInstagramImageList = async () => {
    try {
      startLoading();
      const response = await fetchInstagramImage({
        fields: "media_url,media_type",
        access_token: userDtls?.socialLogins?.instagram?.access_token,
      }).unwrap();
      if (response?.data) {
        const formateData = response?.data.filter(
          (ele) => ele?.media_type === "IMAGE"
        );
        setImageList(formateData);
      } else {
        setImageList([]);
        toaster("error", "Fetch instagram image failed");
      }
    } catch (err) {
      console.error("Fetch instagram image failed", err);
      toaster("error", "Something went wrong");
    } finally {
      stopLoading();
    }
  };
  /**
   * To upload the profile image
   */
  const handlerUploadImage = async () => {
    try {
      startLoading();
      const formData = new FormData();
      formData?.append("files", uploadFile);
      const imageUrl = await imageUpload(formData)?.unwrap();
      if (imageUrl?.data?.length > 0) {
        const response = await updateProfileImage({
          userId: userDtls?.userId || "51c8fc37-df37-4064-b9c7-dd31b636ec6c",
          imageId: imageUrl?.data[0],
        }).unwrap();
        if (response?.data) {
          dispatch(setUserDetails(response?.data));
          setStep("accountConfirm");
        } else {
          toaster("error", "Reset password failed");
        }
      } else {
        toaster("error", imageUrl?.message);
        return;
      }
    } catch (err) {
      console.error("Failed to Reset password:", err);
      toaster("error", "Something went wrong");
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle the upload image from instagram
   */
  const handlerUploadFromInstagram = async () => {
    await getInstagramImageList();
    setOpenImageModal(true);
  };
  return (
    <>
      <Box
        height={" calc(100% - 240px)"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          variant="h5"
          padding={1}
          sx={{ height: "63px" }}
          fontWeight={500}
          fontSize={16}
          color={"primary"}
          mt={8}
        >
          Upload you profile
        </Typography>
        {uploadFile && (
          <Box width={200} height={200}>
            <img
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={URL.createObjectURL(uploadFile)}
              alt="loading"
            ></img>
          </Box>
        )}
        <Stack gap={2}>
          <Button
            sx={{ textTransform: "capitalize", m: "auto", width: "100%" }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            color={"secondary"}
            startIcon={<AddOutlinedIcon sx={{ mr: "auto" }} />}
          >
            Upload From local
            <VisuallyHiddenInput
              type="file"
              accept=".jpg, .jpeg, .png,.webp"
              onChange={(e) => {
                setUploadFile(e?.target?.files[0]);
              }}
              // multiple
            />
          </Button>
          <Button
            sx={{ textTransform: "capitalize", m: "auto" }}
            // component="label"
            // role={undefined}
            variant="contained"
            tabIndex={-1}
            color={"secondary"}
            startIcon={<AddOutlinedIcon />}
            onClick={handlerUploadFromInstagram}
          >
            Upload from instagram
          </Button>
        </Stack>
        <Stack
          marginTop={"auto"}
          direction={"row"}
          gap={2}
          width={"60%"}
          justifyContent={"center"}
        >
          <>
            <Button
              variant="contained"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "capitalize",
                color: "primary.main",
                height: 56,
                width: 162,
                borderRadius: 4,
                bgcolor: "white.main",
              }}
              size="large"
              onClick={() => setStep("signUp")}
            >
              Back
            </Button>
            <Button
              disabled={!uploadFile}
              variant="contained"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "capitalize",
                height: 56,
                width: 162,
                borderRadius: 4,
              }}
              size="large"
              onClick={() => {
                handlerUploadImage();
              }}
            >
              Next
            </Button>
          </>
        </Stack>
      </Box>
      <Modal
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="profileImageCtn">
          <Box
            maxWidth={360}
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            {imageList?.map((ele) => {
              return (
                <Button key={ele?.id}>
                  <img
                    src={ele?.media_url}
                    className="c-offers-scroll"
                    style={{
                      display: "block",
                      height: "100%",
                      margin: "auto",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt="loading"
                  />
                </Button>
              );
            })}
          </Box>
        </div>
      </Modal>
    </>
  );
};
export default UploadProfile;
