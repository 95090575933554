import {
  Box,
  Button,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Stack,
  styled,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { EditIcon, GalleryIcon, UploadIcon } from "../../utils/materialIcon";
import { useDispatch, useSelector } from "react-redux";
import MyButton from "../MyButton";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useEffect, useState } from "react";
import {
  useGetBookingListMutation,
  useGetOffersMutation,
  useGetUserByIdMutation,
  useUpdateUserMutation,
  useUploadImageMutation,
} from "../../services/apiService/userApiService";
import useLoading from "../../hooks/useLoading";
import toaster from "../Toaster/toaster";
import PhotoView from "../Influencer/Offers/PhotoView";
import CheckIcon from "@mui/icons-material/Check";
import LocationAutoComplete from "../LocationAutoComplete/LocationAutoComplete";
import CloseIcon from "@mui/icons-material/Close";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import defaultProfileImage from "../../assets/image/defaultProfileImg.png";
import {
  useGetInstagramDataByFieldsMutation,
  useGetInstagramUserDataByFieldsMutation,
} from "../../services/apiService/socialMediaApiService";
import { setUserDetails } from "../../store/Slicers/authSlice";
import { useNavigate, useParams } from "react-router-dom";

const Profile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [getBookingList] = useGetBookingListMutation();
  const [getOffers] = useGetOffersMutation();
  const [imageUpload] = useUploadImageMutation();
  const [getProfile] = useGetInstagramUserDataByFieldsMutation();
  const [fetchInstagramImage] = useGetInstagramDataByFieldsMutation();
  const [updateUserInfo] = useUpdateUserMutation(); // api for update user details
  const [getUserById] = useGetUserByIdMutation();
  const { startLoading, stopLoading } = useLoading();
  const { userDtls, instagramData } = useSelector((state) => state.auth);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [isShowInstagramImage, setIsShowInstagramImage] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [profileImage, setProfileImage] = useState({});
  console.log("profileImage", profileImage);
  const [miniBio, setMiniBio] = useState(userDtls?.miniBio);
  const [location, setLocation] = useState(userDtls.location);
  console.log("location", location);

  const [previousWorkList, setPreviousWorkList] = useState();
  const [imageList, setImageList] = useState([]);
  const [profileDtls, setProfileDtls] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [instagramModal, setInstagramModal] = useState(false)
  const [inputValue, setInputValue] = useState("");

  // styled component for input field
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  /**
   * To fetch the user data by id for the company profile
   */
  const fetchUserById = async (id) => {
    try {
      startLoading();
      const response = await getUserById(id);
      if (response?.data) {
        setProfileDtls(response?.data?.data);
        console.log("profile data", response?.data);
      } else {
        setProfileDtls({});
      }
    } catch (error) {
      toaster("error", "fetch previous work list api failed");
      return;
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    if (userDtls?.type === "influencer") {
      if (id) {
        fetchUserById(id);
        handlerFetchOffers(id);
      } else {
        setProfileDtls({ ...userDtls });
        fetchBookingData();
      }
    } else if (userDtls?.type === "company") {
      if (id) {
        fetchUserById(id);
        fetchBookingData();
      } else {
        setProfileDtls({ ...userDtls });
        handlerFetchOffers(userDtls?.userId);
      }
      // }
      // if (id) {
      //   fetchUserById(id);
      //   handlerFetchOffers(id);
      // } else {
      //   setProfileDtls({ ...userDtls });
      //   if (userDtls?.type === "influencer") {
      //     fetchBookingData();
      //   } else if (userDtls?.type === "company") {
      //     handlerFetchOffers(userDtls?.type);
      //   }
    }
  }, [id, userDtls]);

  /**
   * To handler fetch offers list
   */
  const handlerFetchOffers = async (id) => {
    try {
      startLoading();
      // if()
      const response = await getOffers({
        id,
        filter: { businessId: id },
      }).unwrap();
      if (response?.data?.length > 3) {
        setPreviousWorkList(response?.data.splice(0, 3));
      } else {
        setPreviousWorkList([]);
      }
    } catch (err) {
      setPreviousWorkList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseInstagram = () => {
    setInstagramModal(false);
  };

  const handleNext = () => {
    if (!inputValue.trim()) {
      alert("Please enter a URL!");
      return;
    }

    // Prepend "https://" if it's not already included in the input
    const url = inputValue.startsWith("http://") || inputValue.startsWith("https://")
      ? inputValue
      : `https://${inputValue}`;

    try {
      // Validate the URL using the URL constructor
      new URL(url);
      setInstagramModal(false);
      console.log("Opening in new tab:", url);
      window.open(url, "_blank"); // Open in a new tab
      setInputValue("")
    } catch (error) {
      alert("Invalid URL! Please enter a valid URL.");
    }
  };



  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 500,
      },
    },
  };
  /**
   * To update the user details
   */
  const handlerUpdateUserDetails = async () => {
    try {
      const toBeUpdated = {};
      if (profileImage?.id) {
        toBeUpdated.profileImage = profileImage?.media_url;
      }
      if (profileImage?.formData) {
        const formData = new FormData();
        formData?.append("files", profileImage?.formData);
        const imageUrl = await imageUpload(formData)?.unwrap();
        if (imageUrl?.data?.length > 0) {
          toBeUpdated.profileImage = imageUrl?.data[0];
        } else {
          toaster("error", imageUrl?.message);
          return;
        }
      }
      if (miniBio !== userDtls?.miniBio) {
        toBeUpdated.miniBio = miniBio;
      }
      if (location?.locationName !== userDtls?.location?.locationName) {
        toBeUpdated.location = location;
      }
      if (Object.keys(toBeUpdated).length === 0) {
        setProfileImage({});
        // setMiniBio("");
        // setLocation({});
        return;
      }
      const resp = await updateUserInfo({
        userId: userDtls?.userId,
        toBeUpdated,
      });
      if (resp?.data) {
        console.log("resp?.data", resp?.data);

        dispatch(
          setUserDetails({
            ...JSON.parse(JSON.stringify(resp?.data)),
            isLoggedIn: true,
          })
        );
      } else {
        toaster("error", "Update user data failed");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  const fetchBookingData = async (id) => {
    try {
      startLoading();
      const payload = {
        status: "active",
      };
      if (userDtls?.type === "influencer") {
        payload.influencerId = userDtls?.userId;
      } else if (userDtls?.type === "company") {
        if (id) {
          payload.influencerId = id;
        }
        payload.businessId = userDtls?.userId;
      }

      const response = await getBookingList({
        id: userDtls?._id,
        filter: payload,
      });
      if (response?.data?.data?.completedByInfluencer?.length > 2) {
        const completedOfferList =
          response.data.data.completedByInfluencer?.slice(0, 3);

        const list = [];
        completedOfferList?.forEach((offer) => {
          offer?.completedByInfluencer?.postDetails?.forEach((postDetail) => {
            if (postDetail?.name?.toLowerCase() === "post") {
              postDetail?.details?.forEach((detail) => {
                if (detail?.media_url) {
                  list.push(detail.media_url);
                }
              });
            }
          });
        });

        setPreviousWorkList(list);
      } else {
        setPreviousWorkList([]);
      }
    } catch (error) {
      toaster("error", "fetch previous work list api failed");
      return;
    } finally {
      stopLoading();
    }
  };

  const handlerEdit = () => {
    if (isEdit) {
      handlerUpdateUserDetails();
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };

  /**
   * To fetch instagram image
   */
  const getInstagramImageList = async () => {
    try {
      startLoading();
      const profileDtls = await getProfile({
        fields: "profile_picture_url",
        access_token: userDtls?.socialLogins?.instagram?.access_token,
      }).unwrap();
      let imageList = [];
      if (profileDtls?.profile_picture_url) {
        imageList.push({
          id: profileDtls?.id,
          media_url: profileDtls?.profile_picture_url,
        });
      }
      const response = await fetchInstagramImage({
        fields: "media_url,media_type,profile_picture_url",
        access_token: userDtls?.socialLogins?.instagram?.access_token,
      }).unwrap();
      if (response?.data) {
        const formateData = response?.data.filter(
          (ele) => ele?.media_type === "IMAGE"
        );
        setImageList([...formateData, ...imageList]);
        setIsShowInstagramImage(true);
      } else {
        setImageList([]);
        toaster("error", "Fetch instagram image failed");
      }
    } catch (err) {
      console.error("Fetch instagram image failed", err);
      toaster("error", "Something went wrong");
    } finally {
      stopLoading();
    }
  };
  /**
   * To close the profile image select modal
   */
  const handlerCloseImageModal = () => {
    setOpenImageModal(false);
    setIsShowInstagramImage(false);
  };
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const naviagte = useNavigate();

  const handleClickBackButton = () => {
    naviagte(-1);
  };

  const handleClickInstagram = () => {
    setInstagramModal(true)
  }

  return (
    <>
      <Box className="profileCtn">
        {/* {id ? (
          <Box display={"flex"}>
            
          </Box>
        ) : ( */}
        <Box
          width={"100%"}
          height={"48px"}
          display={"flex"}
          alignItems={"center"}
          position={id ? "absolute" : ""}
          zIndex={9999}
        >
          {(userDtls?.type === "company" && id) ||
            (userDtls?.type === "influencer" && id) ? (
            <>
              <MyButton
                color="white"
                type="backButton"
                onClick={handleClickBackButton}
              />
              <Button
                onClick={handleClick}
                sx={{ height: "32px", ml: "auto" }}
                className="w-ColorBlack"
              >
                <MoreVertIcon color="white" onClick={handleClick} />
              </Button>
            </>
          ) : (
            <>
              {isEdit ? (
                <IconButton sx={{ ml: "auto", mr: 1 }} onClick={handlerEdit}>
                  <CheckIcon sx={{ color: "gray.main" }} />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ ml: "auto", mr: 1 }}
                  onClick={() => setIsEdit(!isEdit)}
                >
                  <SvgIcon component={EditIcon} />
                </IconButton>
              )}
            </>
          )}
        </Box>
        {/* )} */}
        <Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Box
              position={"relative"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
            >
              <CardMedia
                className="test"
                component="img"
                alt="green iguana"
                height={id ? "339" : "146px"}
                width={id ? "100%" : "146px"}
                image={profileDtls?.profileImage || defaultProfileImage}
                sx={{
                  width: id ? "100%" : "146px",
                  borderRadius: id ? "0" : "50%",
                  minWidth: "146px",
                }}
              />
              {isEdit && (
                <IconButton
                  sx={{
                    position: "absolute",
                    zIndex: 999,
                    width: 68,
                    height: 68,
                  }}
                  onClick={() => setOpenImageModal(true)}
                >
                  <SvgIcon
                    component={UploadIcon}
                    sx={{ mr: 1, width: "100%", height: "100%" }}
                  />
                </IconButton>
              )}
            </Box>
            <Stack direction={"row"} mt={"12px"}>
              <Typography
                display={"flex"}
                className={"textEllipsis mobile-WidthFitContent text-userName "}
                // width={"140px"}
                height={16}
                color={"#1D1B20"}
                fontWeight="700"
                fontSize={"16px"}
                variant="body1"
                alignItems={"center"}
                justifyContent={"center"}
              // bgcolor={"#f4f3f6"}
              >
                {`${profileDtls?.firstName} ${profileDtls?.lastName}`}
              </Typography>
              {!id && instagramData?.username && (
                <Typography
                  display={"flex"}
                  className={
                    "textEllipsis mobile-WidthFitContent text-userName "
                  }
                  // width={"140px"}
                  height={16}
                  color={"#1D1B20"}
                  fontWeight="400"
                  fontSize={"14px"}
                  variant="body1"
                  alignItems={"center"}
                  justifyContent={"center"}
                  ml={1}
                >
                  {`@${instagramData?.username}`}
                </Typography>
              )}
            </Stack>
            <Typography
              display={"flex"}
              className={"textEllipsis mobile-WidthFitContent text-userName "}
              width={"61px"}
              height={6}
              color={"#79747E"}
              fontWeight="600"
              fontSize={"6px"}
              variant="body1"
              alignItems={"center"}
              justifyContent={"center"}
              mt={1}
            >
              {`MEMBER SINCE ${2024}`}
            </Typography>
            {!isEdit && profileDtls?.location?.locationName && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={180}
                height={44}
                px={1}
                my={2}
                boxShadow={3}
                borderRadius={"54px"}
                bottom={"20px"}
                bgcolor={"white.main"}
              >
                <LocationOnOutlinedIcon sx={{ color: "primary2.main" }} />
                <Button
                  // title={offerDtls?.location?.locationName}
                  sx={{
                    textTransform: "capitalize",
                    textWrap: "nowrap",
                    width: "auto",
                    height: "32px",
                    padding: "6px 2px",
                  }}
                  className="textEllipsis"
                >
                  <Typography
                    className="textEllipsis"
                    width={"auto"}
                    fontSize={"12px"}
                    color={"gray.main"}
                    fontWeight="400"
                  // margin={"6px 10px 0  0"}
                  >
                    {profileDtls?.location?.locationName.split(",").at(-2) ||
                      profileDtls?.location?.locationName.split(",").at(-1) ||
                      profileDtls?.location?.locationName.split(",")[0]}
                  </Typography>
                </Button>
              </Box>
            )}
            {isEdit ? (
              <>
                <Typography
                  color={"#1D1B20"}
                  fontWeight="700"
                  fontSize={"18px"}
                  mr={"auto"}
                  ml={1}
                  my={1}
                >
                  Mini bio
                </Typography>
                <TextField
                  width={"340px"}
                  id="outlined-multiline-static"
                  multiline
                  rows={2}
                  variant="outlined"
                  sx={{
                    width: 340,
                    fontSize: "12px",
                    fontWeight: 400,
                    border: "none",
                  }}
                  // fullWidth
                  value={miniBio || userDtls?.miniBio}
                  onChange={(e) => setMiniBio(e?.target?.value)}
                />
              </>
            ) : (
              <>
                {profileDtls?.miniBio && (
                  <Box
                    width={"380px"}
                    sx={{
                      p: { xs: 2 },
                      minHeight: 60,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      multiline
                      rows={4}
                      variant="body1"
                      component="p"
                      noWrap={!isExpanded}
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: isExpanded ? "initial" : 4,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        alignSelf: "stretch",
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#49454F",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {profileDtls?.miniBio}
                    </Typography>
                    {(profileDtls?.miniBio)?.length > 50 && <Button
                      variant="text"
                      onClick={toggleReadMore}
                      sx={{
                        color: "#49454F",
                        width: "fitContent",
                        fontSize: "14px",
                        fontWeight: 1000,
                        mr: "auto",
                        textTransform: "capitalize",
                        p: 0,
                        minWidth: "fit-content",
                      }}
                    >
                      {isExpanded ? "Less" : "More"}
                    </Button>}
                  </Box>
                )}
              </>
            )}
            {isEdit && (
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                // alignItems={"center"}
                mb={1}
              >
                <Typography
                  color={"#1D1B20"}
                  fontWeight="700"
                  fontSize={"18px"}
                  mr={"auto"}
                  ml={1}
                  my={1}
                >
                  Location
                </Typography>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 1,
                      width: "336px",
                      borderBottom: "1px solid gray",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <LocationAutoComplete
                    setLocation={setLocation}
                    value={
                      location?.locationName && location
                    }
                  />
                </Box>
              </Box>
            )}
            {/* {!id && ( */}
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {isEdit && (
                <Typography
                  color={"#1D1B20"}
                  fontWeight="700"
                  fontSize={"18px"}
                  mr={"auto"}
                  ml={1}
                  my={1}
                >
                  Social media
                </Typography>
              )}
              <Box
                sx={{
                  width:
                    (userDtls?.type === "company" && !id) ||
                      (userDtls?.type === "influencer" && id)
                      ? 48
                      : { xs: 324, sm: 222 },
                  height: 48,
                  p: 1,
                  boxShadow:
                    "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InstagramIcon
                  sx={{ color: { sm: "#d2b0d6", xs: "#8F3A98" } }}
                  onClick={() => {
                    handleClickInstagram()
                  }}
                />
                {instagramModal && (
                  <Modal
                    aria-labelledby="instagram-modal-title"
                    aria-describedby="instagram-modal-description"
                    open={instagramModal}
                    onClose={handleClose}
                    closeAfterTransition
                  >
                    <Box
                      sx={{
                        position: "relative",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        border: "2px solid #e8def8",
                        borderRadius: "15px",
                        boxShadow: 24,
                        p: 3,
                        width: "400px",
                      }}
                    >
                      <Stack direction="row" justifyContent="space-between" mb={2}>
                        <Typography id="instagram-modal-title" variant="h6">
                          Instagram Modal
                        </Typography>
                        <IconButton onClick={handleCloseInstagram}>
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Enter your input"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        sx={{ mb: 3 }}
                      />
                      <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          sx={{
                            borderRadius: "8px",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{
                            borderRadius: "8px",
                          }}
                          disabled={!inputValue}
                        >
                          Next
                        </Button>
                      </Stack>
                    </Box>
                  </Modal>
                )}
                {((userDtls?.type === "influencer" && !id) ||
                  (userDtls?.type === "company" && id)) && (
                    <>
                      <Typography
                        color={"#8F3A98"}
                        fontWeight="500"
                        fontSize={"12px"}
                        ml={1}
                      >
                        {`@${instagramData?.username ||
                          profileDtls?.socialLogins?.instagram?.username
                          }`}
                      </Typography>
                      <Typography
                        display={"flex"}
                        className={
                          "textEllipsis mobile-WidthFitContent followersCount "
                        }
                        color={"#8F3A98"}
                        fontWeight="700"
                        fontSize={"12px"}
                        variant="body1"
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius={20}
                        mx={1}
                        textTransform={"capitalize"}
                        sx={{ ml: { xs: "auto" } }}
                      >
                        {`${instagramData?.followers_count ||
                          profileDtls?.socialLogins?.instagram?.followers_count ||
                          0
                          } `}
                        <Typography fontWeight="400" fontSize={"12px"} ml={1}>
                          followers
                        </Typography>
                      </Typography>
                    </>
                  )}
                {userDtls?.type !== "company" && isEdit && (
                  <IconButton
                    onClick={() => {
                      // setSelectedEditField("firstName");
                      // handlerOpenEditModal("first name");
                    }}
                  >
                    <SvgIcon component={EditIcon} sx={{ color: "#8F3A98" }} />
                  </IconButton>
                )}
              </Box>
            </Box>
            {/* )} */}
            {previousWorkList?.length > 0 && (
              <>
                <Typography
                  color={"#1D1B20"}
                  fontWeight="700"
                  fontSize={"18px"}
                  ml={1}
                  mt={4}
                  sx={{ mr: { xs: "auto", sm: "0" } }}
                >
                  {(userDtls?.type === "company" && !id) ||
                    (userDtls?.type === "influencer" && id)
                    ? "Offer"
                    : "Previous work"}
                </Typography>
                {(userDtls?.type === "company" && !id) ||
                  (userDtls?.type === "influencer" && id) ? (
                  <div className={"profilePhotoViewContainer"}>
                    {previousWorkList?.map((ele, index) => {
                      return (
                        <PhotoView
                          style={{ overFlow: "auto" }}
                          data={ele}
                          key={index}
                          isSimilarView={true}
                          noOption={false}
                          isSavedOffer={ele?.isOfferSaved || false}
                        ></PhotoView>
                      );
                    })}
                  </div>
                ) : (
                  <Box
                    className="moreFromCompanyCtn"
                    mb={2}
                    p={1}
                    width={"100%"}
                  >
                    {previousWorkList?.map((ele, index) => {
                      return (
                        <CardMedia
                          component="img"
                          image={ele}
                          alt="Loading"
                          width={347}
                          height={339}
                        ></CardMedia>
                      );
                    })}
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        className="flex"
        open={openImageModal}
        onClose={() => handlerCloseImageModal()}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          minWidth={340}
          width={340}
          height={374}
          sx={{ bgcolor: "#ece6f0", borderRadius: "20px" }}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          position={"relative"}
        >
          <DialogTitle
            id="responsive-dialog-title"
            textAlign={"center"}
            fontSize={24}
            fontWeight={400}
          >
            Update profile pic
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handlerCloseImageModal()}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ width: 300, p: 0 }}>
            {isShowInstagramImage ? (
              <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                {imageList?.map((ele) => {
                  return (
                    <CardMedia
                      onClick={() => {
                        if (ele?.id === profileImage?.id) {
                          setProfileImage({});
                        } else {
                          setProfileImage(ele);
                        }
                      }}
                      className={
                        profileImage?.id === ele?.id ? "selectedImage" : ""
                      }
                      component="img"
                      alt="green iguana"
                      height={"94px"}
                      width={"94px"}
                      image={ele?.media_url}
                      sx={{
                        width: "94px",
                        borderRadius: "5px",
                        minWidth: "94px",
                      }}
                    />
                  );
                })}
              </Box>
            ) : (
              <Stack gap={2}>
                {userDtls?.type === "influencer" && (
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      textTransform: "capitalize",
                      color: "primary.main",
                      height: 56,
                      width: "100%",
                      borderRadius: 2,
                      bgcolor: "white.main",
                    }}
                    size="large"
                    onClick={getInstagramImageList}
                    startIcon={<InstagramIcon sx={{ mr: "auto" }} />}
                  >
                    From instagram
                  </Button>
                )}
                <Button
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    textTransform: "capitalize",
                    color: "primary.main",
                    height: 56,
                    width: "100%",
                    borderRadius: 2,
                    bgcolor: "white.main",
                  }}
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<GalleryIcon sx={{ color: "#8F3A98" }} />}
                >
                  Add image
                  <VisuallyHiddenInput
                    type="file"
                    accept=".jpg, .jpeg, .png,.webp"
                    onChange={(e) => {
                      setProfileImage({ formData: e?.target?.files[0] });
                    }}
                    multiple
                  />
                </Button>
              </Stack>
            )}
          </DialogContent>
          <DialogActions sx={{ mt: "auto" }}>
            <Button
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "capitalize",
                color: "primary.main",
                height: 56,
                width: 162,
                borderRadius: 4,
                bgcolor: "white.main",
              }}
              size="large"
              onClick={(e) => handlerCloseImageModal(e)}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => handlerCloseImageModal(e)}
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "capitalize",
                color: "white.main",
                height: 56,
                width: 162,
                borderRadius: 4,
                // bgcolor: "white.main",
              }}
              variant="contained"
              disabled={!profileImage?.id && !profileImage?.formData}
            >
              Update
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </>
  );
};
export default Profile;
