import { TabContext, TabList } from "@mui/lab";
import { Box, IconButton, Stack, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SimpleTable from "../../../components/simpleTable/SimpleTable";
import MyButton from "../../../components/MyButton";
import ChatIcon from "@mui/icons-material/Chat";
import { useNavigate } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import toaster from "../../../components/Toaster/toaster";
import { useSelector } from "react-redux";
import {
  useCreateChatMutation,
  useGetReportListMutation,
  useUpdateBookingStatusMutation,
  useUpdateReportMutation,
} from "../../../services/apiService/userApiService";
import { notificationCode } from "../../../utils/constants";

const Reports = () => {
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useLoading(); //To set loading
  const [createChat] = useCreateChatMutation();
  const [getReportList] = useGetReportListMutation();
  const [updateReport] = useUpdateReportMutation();
  const [updateBookingStatus] = useUpdateBookingStatusMutation(); // API to update the booking status
  const { userDtls } = useSelector((state) => state.auth);
  const [tabName, setTabName] = useState("all");
  const [reportList, setReportList] = useState([]);

  /**
   * To handle the chat navigation
   */
  const fetchReportList = async () => {
    try {
      const payload = { isReport: true };
      if (tabName === "pending") {
        payload.status = "pending";
      } else if (tabName === "approved") {
        payload.status = "approved";
      } else if (tabName === "rejected") {
        payload.status = "rejected";
      }
      const response = await getReportList(payload).unwrap();
      if (response?.data) {
        const formatDate = response?.data?.map((ele) => {
          const data = {
            ...ele,
            influencerName: `${ele?.influencerDetails?.firstName} ${ele?.influencerDetails?.lastName}`,
            influencerEmail: ele?.influencerDetails?.email,
            companyName: `${ele?.companyDetails?.firstName} ${ele?.companyDetails?.lastName}`,
            companyEmail: ele?.companyDetails?.email,
            title: ele?.offerDetails?.title,
          };
          delete data?.companyDetails;
          delete data?.influencerDetails;
          delete data?.offerDetails;
          return data;
        });
        setReportList(formatDate);
      } else {
        setReportList();
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to fetch report list:", err);
        toaster("error", "Failed to fetch report list");
      }
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    fetchReportList();
  }, [userDtls?.userId, tabName]);
  /**
   * To handle the chat navigation
   */
  const handlerChat = async (data) => {
    try {
      startLoading();
      const payload = {
        isGroupChat: false,
        id: userDtls?.userId,
        userId: data?.influencer_id,
      };
      const response = await createChat(payload).unwrap();
      if (response?.data[0]?._id || response?.data?._id) {
        navigate(`/chat/${response?.data[0]?._id || response?.data?._id}`);
      } else {
        toaster("error", "Chat creation failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };

  /**
   * To handle to approve the report
   */
  const handlerApproveReport = async (data) => {
    try {
      startLoading();
      let payload = {
        offerId: data?.offer_id,
        influencerId: data?.influencer_id,
        newStatus: "rejectedOffers",
      };
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        fetchReportList();
        handlerRejectReport(data, "approved");
        toaster("info", "report approved successfully");
      } else {
        toaster("error", "report rejected failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        toaster("error", "report rejected failed");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle to reject the the report
   */
  const handlerRejectReport = async (data, status) => {
    try {
      startLoading();
      const payload = {};
      payload.filter = { _id: data?._id };
      payload.toBeUpdated = {
        reason: data?.reason,
        explanation: data?.explanation,
        isReport: data?.isReport,
        status,
        influencer_id: data?.influencer_id,
        offer_id: data?.offer_id,
      };
      const response = await updateReport(payload).unwrap();
      if (response?.data) {
        toaster("info", "report rejected successfully");
      } else {
        toaster("error", "report rejected failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        toaster("error", "report rejected failed");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To set the tab name
   */
  const handlerSetTabName = (e, tabName) => {
    setTabName(tabName);
  };
  /**
   * To set column
   */
  const columns = [
    {
      width: 100,
      label: "Company Name",
      dataKey: "companyName",
    },
    {
      width: 100,
      label: "Company Email",
      dataKey: "companyEmail",
    },
    {
      width: 100,
      label: "Offer title",
      dataKey: "title",
    },
    {
      width: 100,
      label: "Influencer Name",
      dataKey: "influencerName",
    },
    {
      width: 100,
      label: "Reason",
      dataKey: "reason",
    },

    {
      width: 100,
      label: "Status",
      dataKey: "status",
      cell: (tableData) => {
        return tableData["status"] === "pending" ? (
          <Stack direction={"row"} gap={1}>
            <MyButton
              sx={{ padding: 1 }}
              onClick={() => handlerApproveReport(tableData)}
            >
              Approved
            </MyButton>
            <MyButton
              sx={{ padding: 1 }}
              onClick={() => handlerRejectReport(tableData, "rejected")}
            >
              reject
            </MyButton>
          </Stack>
        ) : (
          <Typography> {tableData["status"]}</Typography>
        );
      },
    },
    {
      width: 50,
      label: "",
      dataKey: "chat",
      cell: (column) => {
        return (
          <IconButton
            sx={{ height: "32px" }}
            color="primary"
            onClick={() => {
              handlerChat(column);
            }}
          >
            <ChatIcon />
          </IconButton>
        );
      },
    },
  ];
  return (
    <Box height={"100%"}>
      <TabContext value={tabName} sx={{ padding: "5px", width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handlerSetTabName}
            aria-label="lab API tabs example"
          >
            <Tab
              sx={{ textTransform: "capitalize" }}
              fontWeight={700}
              label="All"
              value="all"
              color="white"
            />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Pending"
              value="pending"
            />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Approved"
              value="approved"
            />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Rejected"
              value="rejected"
            />
          </TabList>
        </Box>
        <Box
          width={"100%"}
          height={"calc(100% - 49px)"}
          padding={1}
          boxShadow={3}
        >
          <SimpleTable
            tableData={reportList}
            columns={columns}
            // handlerApproveUser={handlerApproveUser}
          ></SimpleTable>
        </Box>
      </TabContext>
    </Box>
  );
};
export default Reports;
