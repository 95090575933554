import {
  Box,
  Button,
  Card,
  CardMedia,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/common";
import CheckIcon from "@mui/icons-material/Check";
import InstagramIcon from "@mui/icons-material/Instagram";
import defaultProfileImg from "../../assets/image/defaultProfileImg.png";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
// import messageIcon from "../../assets/Icons/messageIcon.png";
import {
  CrossIcon,
  MessageIcon,
  NotesIcon,
  OfferIcon,
  SyncIcon,
  WhatIcon,
} from "../../utils/materialIcon";
import MyButton from "../MyButton";
import { offerStatusCode } from "../../utils/constants";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import toaster from "../Toaster/toaster";
import { useState } from "react";
import useLoading from "../../hooks/useLoading";
import { useSelector } from "react-redux";
import { useCreateChatMutation } from "../../services/apiService/userApiService";
import materialIcons from "../../utils/materialIcon";

const CompanyBookings = ({
  data,
  offerTitle,
  handlerUpdateBookingStatus,
  isOfferModule,
  setShowDialog,
  setdateRequestDtls,
}) => {
  const { startLoading, stopLoading } = useLoading();
  const { userDtls } = useSelector((state) => state?.auth);
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const [createChat] = useCreateChatMutation();
  const { bookingFilter } = useSelector((state) => state?.filter);

  /**
   * navigation handler
   */
  const handlerNavigation = (id) => {
    if (!isOfferModule) {
      navigate(`/booking/${id}`);
    }
  };
  /**
   * To check the approve content is enable
   */
  const isApproveContentDisabled = () => {
    const requireCount =
      data?.requirement?.reduce((acc, item) => acc + item.count, 0) || 0;
    const presentCount = data?.completedByInfluencer?.links?.length || 0;
    return requireCount === presentCount ? false : true;
  };

  /**
   * To handle the approve the content
   */
  const handlerApproveContent = async (e) => {
    handlerUpdateBookingStatus(e, data, "completedByCompany");
  };

  /**
   * @returns date change request button jsx element
   */
  const dateChangeRequestBtn = (data) => {
    return (
      <Button
        onClick={(e) => {
          e?.stopPropagation();
          e?.preventDefault();
          setdateRequestDtls && setdateRequestDtls(data);
          setShowDialog(true);
        }}
        sx={{
          textTransform: "none",
          p: 1,
          m: 1,
          borderRadius: 4,
          bgcolor: isMobile ? " " : "#f4ecf5",
        }}
      >
        <PublishedWithChangesIcon sx={{ mr: 1, marginLeft: isMobile ? 3 : 0 }} />
        <span className="hideOnMobile">Date change request</span>
      </Button>
    );
  };

  /**
   * To handle the navigation of the company profile
   */
  const handlerInfluencerProfile = (e, data) => {
    e?.preventDefault();
    e?.stopPropagation();
    navigate(`/profile/${data?.influencerDetails?.userId}`);
  };

  /**
   * To handle the chat navigation
   */
  const handlerChat = async (e, data) => {
    e?.preventDefault();
    e?.stopPropagation();
    try {
      startLoading();
      const payload = {
        isGroupChat: false,
        id: userDtls?.userId,
      };
      if (userDtls?.type === "influencer") {
        payload.userId = data?.createdBy;
      } else {
        payload.userId = data?.influencerDetails?.userId;
      }
      const response = await createChat(payload).unwrap();
      if (response?.data[0]?._id || response?.data?._id) {
        navigate(`/chat/${response?.data[0]?._id || response?.data?._id}`);
        // navigate("/message");
      } else {
        toaster("error", "Chat creation failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  return (
    <Card
      className="card-ctn"
      sx={{
        padding: "6px",
        height: 92,
        boxShadow:
          "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
        width: "100%",
        borderRadius: "12px",
        overflow: "unset",
        cursor: "pointer",
        marginTop: "15px",
        marginBottom: "15px"
      }}
    >
      <Box
        onClick={() =>
          bookingFilter !== "requestToBusiness" && handlerNavigation(data?._id)
        }
        className={"companyBooking"}
        // variant="contained"
        // component="section"
        // display="flex"
        // alignItems="center"
        // gap={"5px"}
        sx={{
          // borderRadius: "2px",
          width: "100%",
          bgcolor: "white.main",
          position: "relative",
        }}
      >
        <Box
          className={"dateCard"}
          p={1}
          borderRadius={"8px"}
          border={"1px solid #CAC4D0"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gridArea={"span 2 / 1"}
          bgcolor={bookingFilter === "offeredToInfluencer" && "#f4ebf5"}
          gap={"8px"}
        >
          {/* Determine the date to display based on conditions */}
          <Typography
            title={data?.title}
            fontWeight="400"
            fontSize={"14px"}
            variant="body1"
            color={
              bookingFilter === "offeredToInfluencer"
                ? "#8F3A98"
                : bookingFilter === "completedByInfluencer"
                  ? "var(--Schemes-Outline-Variant, #CAC4D0)"
                  : "#625B71"
            }
          >
            {formatDate(
              bookingFilter === "offeredToInfluencer" && data?.dateRequest?.isDataRequestAccepted
                ? data?.dateRequest?.date
                : data?.availableDates?.from,
              "ddd DD/M"
            ).split(" ")[0]}
          </Typography>
          <Typography
            title={data?.title}
            fontWeight="700"
            fontSize={"24px"}
            variant="body1"
            color={
              bookingFilter === "offeredToInfluencer"
                ? "#8F3A98"
                : bookingFilter === "completedByInfluencer"
                  ? "var(--Schemes-Outline-Variant, #CAC4D0)"
                  : "#625B71"
            }
          >
            {formatDate(
              bookingFilter === "offeredToInfluencer" && data?.dateRequest?.isDataRequestAccepted
                ? data?.dateRequest?.date
                : data?.availableDates?.from,
              "ddd DD/M"
            ).split(" ")[1]}
          </Typography>
        </Box>

        <Box
          width={"409px"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
        >
          <CardMedia
            className="c-bookingCardMedia"
            component="img"
            alt="green iguana"
            image={data?.offerImages?.[0] || defaultProfileImg}
            sx={{ borderRadius: "50%" }}
            onClick={(e) => handlerInfluencerProfile(e, data)}
          />
          <Typography
            display={"flex"}
            className={"textEllipsis mobile-WidthFitContent "}
            width={"fitContent "}
            height={32}
            color={"text.main"}
            fontWeight="400"
            fontSize={"20px"}
            variant="body1"
            alignItems={"center"}
            mx={1}
            marginLeft={"16px"}
            textTransform={"capitalize"}
            onClick={(e) => handlerInfluencerProfile(e, data)}
          >
            {data?.influencerDetails?.firstName}
          </Typography>
          <InstagramIcon
            className="hideOnMobile"
            color="#8F3A98"
            // onClick={handlerInstagramLogin}
            sx={{ cursor: "pointer", color: "primary.main", mr: 1 }}
          />

          <Typography
            display={"flex"}
            className={"textEllipsis mobile-WidthFitContent followersCount "}
            minWidth={"52px"}
            // width={"110px"}
            height={32}
            color={"text.main"}
            fontWeight="500"
            fontSize={"12px"}
            variant="body1"
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={20}
            bgcolor={"#f4f3f6"}
            mx={1}
          // textTransform={"capitalize"}
          >
            {`${data?.influencerDetails?.socialLogins?.instagram
              ?.followers_count || 0
              } `}
            <Typography
              fontWeight="500"
              fontSize={"12px"}
              className="hideOnMobile"
              ml={1}
            >
              followers
            </Typography>
          </Typography>
        </Box>
        {/* {data?.title && (
          <Typography
            display={"flex"}
            className={"textEllipsis mobile-WidthFitContent c-offerTitle "}
            // width={"242px"}
            height={32}
            color={"text.main"}
            fontWeight="900"
            fontSize={"12px"}
            variant="body1"
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={20}
            bgcolor={"#F7F2FA"}
            // mx={1}
            // ml={"auto"}
            textTransform={"capitalize"}
          >
            <SvgIcon
              component={OfferIcon}
              sx={{ mr: 1, color: "primary.main" }}
            />
            {data?.title}
          </Typography>
        )} */}
        <Box
          display={"flex"}
          alignItems={"center"}
          // className="hideOnMobile"
          ml={"auto"}
          gridArea={"2 / 2"}
          borderRadius={20}
          bgcolor={"#F7F2FA"}
          px={1}
        >
          <SvgIcon
            component={WhatIcon}
            sx={{ mr: 1, backgroundColor: "#F7F2FA" }}
          />
          <Typography
            title={
              // formatDate(data?.dateRequest?.date, "DD/MM") ||
              data?.title || offerTitle
            }
            // display={"flex"}
            className={"textEllipsis mobile-WidthFitContent  "}
            width={"120px"}
            maxWidth={178}
            height={32}
            color={"text.main"}
            fontWeight="900"
            fontSize={"12px"}
            variant="body1"
            alignItems={"center"}
            justifyContent={"center"}
            lineHeight={2.5}
            // mx={1}
            // ml={"auto"}
            textTransform={"capitalize"}
          >
            {data?.title || offerTitle}
          </Typography>
          {data?.dateRequest?.isDataRequestAccepted && (
            <Tooltip title={formatDate(data?.dateRequest?.date, "DD/MM/YYYY")}>
              <ReportProblemOutlinedIcon
                sx={{
                  color: "#8F3A98",
                }}
              />
            </Tooltip>
          )}
        </Box>
        {/* <Typography
          className={` textEllipsis offerTitle `}
          width={"242"}
          height={"32px"}
          color={"#1D1B20"}
          fontWeight="900"
          fontSize={"12px"}
          variant="body1"
          ml={2}
          textTransform={"capitalize"}
        >
          Completed
        </Typography> */}
        {bookingFilter === "requestToBusiness" && (
          <>
            {data?.dateRequest?.isDateRequested ? (
              <>{dateChangeRequestBtn(data)}</>
            ) : (
              <MyButton
                onClick={(e) => handlerUpdateBookingStatus(e, data)}
                className="checkIcon btn list-btn c-updateStatusBtn"
                color={offerStatusCode[data?.offerStatus]?.bg}
                sx={{
                  borderRadius: "50px",
                  fontWeight: 600,
                  fontSize: "16px",
                  height: "45px",
                  // width: "124px",
                  bgcolor: "white.main",
                  boxShadow: 0,
                  // ml: "auto",
                  padding: 0,
                }}
                minWidth={"40px"}
              >
                <CheckIcon
                  className="c-updateStatusIcon"
                  sx={{ color: "primary.main" }}
                />
                <span className="hideOnMobile textColor">Approve</span>
              </MyButton>
            )}
            {/* <IconButton
              className="c-ignoreIcon"
              sx={{ width: "24px", mx: 1, gridArea: " 2 / 3 " }}
            >
              <CrossIcon />
            </IconButton> */}
          </>
        )}
        {bookingFilter === "offeredToInfluencer" && (
          <Box className="c-statusPending">
            {data?.dateRequest?.isDateRequested ? (
              dateChangeRequestBtn(data)
            ) : (
              <>
                {isMobile && (
                  <IconButton
                    bgcolor={
                      bookingFilter === "offeredToInfluencer" &&
                        isApproveContentDisabled()
                        ? "#d3d3d3"
                        : "#D3F5E1"
                    }
                    sx={{
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                      // borderRadius: "16px",
                      width: 24,
                      height: 24,
                      // bgcolor: "#6FCF97",
                      // bgcolor:
                      //   bookingFilter === "offeredToInfluencer" &&
                      //   isApproveContentDisabled()
                      //     ? "#d3d3d3"
                      //     : "#6FCF97",
                      // boxShadow:
                      //   "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)",
                      display: "flex",
                      ml: "auto",
                    }}
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      if (
                        !(
                          bookingFilter === "offeredToInfluencer" &&
                          isApproveContentDisabled()
                        )
                      ) {
                        handlerApproveContent(e);
                      }
                    }}
                  >
                    {data?.requirement?.reduce(
                      (total, item) => total + item.count,
                      0
                    ) === data?.completedByInfluencer?.links?.length ? (
                      <CheckIcon sx={{ color: "#8F3A98" }} />
                    ) : (
                      <materialIcons.clockIcon
                        color="primary.main"
                        sx={{ marginLeft: 5, gridArea: "2/3", mr: 1 }}
                      />
                    )}
                  </IconButton>
                )}
                <Typography
                  className="completeOffer hideOnMobile"
                  padding={"6px 12px"}
                  display={"flex"}
                  variant="body1"
                  fontSize={14}
                  fontWeight={400}
                  color="#36A162"
                  height={"32px"}
                  width={"147px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={20}
                  bgcolor={
                    bookingFilter === "offeredToInfluencer" &&
                      isApproveContentDisabled()
                      ? "#d3d3d3"
                      : "#D3F5E1"
                  }
                  sx={{ cursor: "pointer" }}
                  role={"button"}
                  disabled={
                    bookingFilter === "offeredToInfluencer" &&
                    isApproveContentDisabled()
                  }
                  onClick={handlerApproveContent}
                >
                  Approve content
                </Typography>
              </>
            )}
            <IconButton
              className="hideOnMobile"
              sx={{
                "&:hover": {
                  backgroundColor: "#8F3A98",
                },
                borderRadius: "16px",
                width: 56,
                height: 56,
                bgcolor: "#8F3A98",
                boxShadow:
                  "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)",
                display: "flex",
              }}
              // sx={{ height: "32px" }}
              color="white"
              onClick={(e) => {
                handlerChat(e, data);
              }}
            >
              <NotesIcon />
            </IconButton>
          </Box>
        )}
        {(bookingFilter === "completedByInfluencer" ||
          bookingFilter === "completedByInfluencerUsers") && (
            <Box
              // width={"228px"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"auto"}
              className="c-statusIconComplete"
              marginTop={"11px"}
            >
              <CheckIcon
                className="c-statusIconComplete"
                sx={{ color: isMobile ? "gray.main" : "#a9e2c1", mb: 1 }}
              />
              <Typography
                className={` textEllipsis offerTitle hideOnMobile `}
                width={"83px"}
                minWidth={"83px"}
                height={"36px"}
                color={"#6FCF97"}
                fontWeight="700"
                fontSize={"16px"}
                variant="body1"
                ml={1}
                textTransform={"capitalize"}
              >
                Completed
              </Typography>
              <Typography
                className={` textEllipsis offerTitle  hideOnMobile`}
                width={"188px"}
                height={"36px"}
                color={"#6FCF97"}
                fontWeight="400"
                fontSize={"14px"}
                variant="body1"
                // ml={1}
                textTransform={"capitalize"}
              >
                {` ${formatDate(
                  data?.availableDates?.from,
                  "DD/MM"
                )}-${formatDate(data?.availableDates?.to, "DD/MM")}`}
              </Typography>
            </Box>
          )}
        {bookingFilter !== "requestToBusiness" &&
          bookingFilter !== "completedByInfluencer" && (
            <Box
              className="hideOnWeb"
              width={28}
              height={28}
              onClick={(e) => handlerChat(e, data)}
              sx={{ gridArea: "2 / 3", ml: "auto" }}
            >
              <MessageIcon />
            </Box>
          )}
      </Box>
    </Card>
  );
};

export default CompanyBookings;
