import {
  Button,
  IconButton,
  Paper,
  Stack,
  styled,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Signin.css";
import InputBase from "@mui/material/InputBase";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import ConnectSocialMedia from "./ConnectSocialMedia";
// import AccountConfirm from "./AccountConfirm";
import ContractPendingIcon from "../../assets/Icons/contractPendingIcon.png";
import materialIcons, {
  emailIcon,
  globeIcon,
  passwordIcon,
  UserNameIcon,
} from "../../utils/materialIcon";
import checkBigIcon from "../../assets/Icons/checkBigIcon.svg";
import {
  useSignInMutation,
  useSignUpMutation,
  useResetPasswordMutation,
  useGenerateOtpMutation,
  useVerifyOtpMutation,
  useUploadImageMutation,
  useUpdateUserProfileMutation,
  useUpdateUserMutation,
} from "../../services/apiService/userApiService";
import {
  setClearUserDtls,
  setInstagramAccessToken,
  setInstagramData,
  setIsNewUser,
  setUserDetails,
} from "../../store/Slicers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import toaster from "../../components/Toaster/toaster";
import MyButton from "../../components/MyButton";
import SignUp from "./Signup";
import EmailOtpVerification from "./EmailOtpVerification";
import ResetPassword from "./ResetPassword";
import useLoading from "../../hooks/useLoading";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import symeLogo from "../../assets/image/symeLogo1.png";
import axios from "axios";
import {
  useFindUserByInstagramOpenIdMutation,
  useGetInstagramAccessTokenMutation,
  useGetInstagramLongLiveTokenMutation,
  useGetInstagramUserDataMutation,
  useInstagramOauthMutation,
  useUpdateSocialMediaOpenIdMutation,
} from "../../services/apiService/oauthApiService";
import { setTemporaryUserDtls } from "../../store/Slicers/authSlice";
import MyIcon from "../../components/MyIcon/MyIcon";
import UploadProfile from "./UploadProfile";
// import InstagramRedirect from "./loginWithSocial/InstagramRedirect";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [signIn] = useSignInMutation();
  const [signUp] = useSignUpMutation();
  const [resetPassword] = useResetPasswordMutation();
  const [generateOtp] = useGenerateOtpMutation();
  const [verifyOtp] = useVerifyOtpMutation();
  const [instagramOauth] = useInstagramOauthMutation();
  const { startLoading, stopLoading } = useLoading();
  const [imageUpload] = useUploadImageMutation();
  const [updateProfileImage] = useUpdateUserProfileMutation();
  const [updateOpenId] = useUpdateSocialMediaOpenIdMutation();
  const [updateUserInfo] = useUpdateUserMutation(); // api for update user details
  const [uploadFile, setUploadFile] = useState(null);
  const [showLogo, setShowLogo] = useState(true);
  const [userId, setUserId] = useState("");
  const { userDtls, tempUserDtls, isLoggedIn, isNewUser } = useSelector(
    (state) => state?.auth
  );
  // const { tempUserDtls } = useSelector((state) => state?.user);
  const [isForgetPassword, setIsForgetPassword] = useState(false); // To set is registration or not
  const [step, setStep] = useState("selectUserType"); //steps - selectUserType,signIn,signUp,resetPassword,otpVerify,
  // connectSocialMedia, uploadProfile,accountConfirm, welcomePage
  const [socialMediaOpenId, setSocialMediaOpenId] = useState({});
  const [tabView, setTabView] = useState("influencer");
  const initialFormValue = {
    firstName: {
      value: "",
      error: false,
      errorMessage: "You must enter first name",
    },
    lastName: {
      value: "",
      error: false,
      errorMessage: "You must enter last name",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "You must enter email",
    },
    password: {
      value: "",
      error: false,
      errorMessage: "You must enter your password",
    },
    passwordAgain: {
      value: "",
      error: false,
      errorMessage: "You must enter your password",
    },
    companyWebsite: {
      value: "",
      error: false,
    },
    userType: {
      value: tabView,
      error: false,
    },
    termsAndConditions: {
      value: false,
    },
    privacy: {
      value: false,
    },
  };
  const initialValidationErrorValue = {
    email: false,
    passwordAgain: false,
    message: "",
  };
  const [validationError, setValidationError] = useState(
    initialValidationErrorValue
  );
  const [formValues, setFormValues] = useState({ ...initialFormValue });
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/signIn");
    } else {
      if (userDtls?.type === "influencer") {
        navigate("/home");
      } else {
        navigate("/bookings");
      }
    }
  }, [isLoggedIn]);
  useEffect(() => {
    // Hide the logo after 5 seconds
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 2000); // 5000ms = 5 seconds

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  /**
   * To handle sign in
   */
  const handlerSignIn = async () => {
    try {
      startLoading();
      const response = await signIn({
        email: formValues?.email.value,
        password: formValues?.password.value,
      }).unwrap();
      console.log("response.data", response.data);

      if (response.data) {
        if (!response?.data?.isEmailVerified) {
          handlerGenerateOtp();
          setStep("otpVerify");
        } else if (response.data?.socialLogins?.instagram?.access_token) {
          // if (response.data?.socialLogins?.instagram?.access_token) {
          const instagramUserData = await getInstagramUserData(
            response.data?.socialLogins?.instagram?.access_token
          ).unwrap();
          if (instagramUserData?.id) {
            dispatch(setInstagramData(instagramUserData));
            await updateOpenId({
              userId: response.data?.userId,
              openId: instagramUserData?.id,
              access_token:
                response.data?.socialLogins?.instagram?.access_token,
              followers_count: instagramUserData?.followers_count,
              username: instagramUserData?.username,
            });
          } else {
            toaster("error", response?.message);
          }
          toaster("success", "Sign in successfully completed");
          navigateToOffer(response.data);
          // }
        } else if (response.data?.type === "influencer") {
          setStep("connectSocialMedia");
          toaster("info", "Link social media");
        } else {
          navigateToOffer(response.data);
          // toaster("success", "Sign in successfully completed");
        }
      } else {
        toaster("error", response?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to sign in:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };

  /**
   * To handle sign up
   */
  const handlerSignUp = async (openId = socialMediaOpenId?.id) => {
    try {
      startLoading();
      const response = await signUp({
        firstName:
          formValues?.firstName?.value || tempUserDtls?.firstName?.value,
        lastName: formValues?.lastName?.value || tempUserDtls?.lastName?.value,
        email: formValues?.email?.value || tempUserDtls?.email?.value,
        companyWebsite:
          formValues?.companyWebsite?.value ||
          tempUserDtls?.companyWebsite?.value,
        password: formValues?.password?.value || tempUserDtls?.password?.value,
        type: formValues?.userType?.value || tempUserDtls?.userType?.value,
        openId,
      }).unwrap();
      if (response?.data) {
        // dispatch(setTemporaryUserDtls({}));
        // if (!isSingIn) {
        handlerGenerateOtp();
        setStep("otpVerify");
        // }
        dispatch(setUserDetails(response?.data));
      } else {
        dispatch(setInstagramData({}));
        dispatch(setUserDetails());
        // dispatch(setTemporaryUserDtls({}));
        setStep("signUp");
        dispatch(setIsNewUser(true));
        toaster("error", response?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        dispatch(setInstagramData({}));
        dispatch(setUserDetails());
        dispatch(setTemporaryUserDtls({}));
        setStep("signUp");
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle the forget password
   */
  const handlerResetPassword = async () => {
    try {
      startLoading();
      const response = await resetPassword({
        email: formValues?.email?.value,
        newPassword: formValues?.password?.value,
      }).unwrap();
      if (response?.data) {
        toaster("success", "Reset password successfully completed");
        navigateToOffer(response.data);
      } else {
        toaster("error", "Reset password failed");
      }
    } catch (err) {
      console.error("Failed to Reset password:", err);
      toaster("error", "Something went wrong");
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle update the user details
   * @param {e} function
   */
  const handleUpdateUserData = async () => {
    try {
      const resp = await updateUserInfo({
        userId: userId,
        toBeUpdated: { isFirstTimeLogin: false },
      });
      if (resp?.data) {
        navigateToOffer({ ...resp.data, isLoggedIn: true });
      } else {
        toaster("error", "Update user data failed");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  /**
   * To navigate offers
   */
  const navigateToOffer = (data) => {
    if (data?.type === "admin" || data?.isApproved) {
      if (data?.isFirstTimeLogin) {
        // dispatch(setUserDetails(data));
        setUserId(data?.userId);
        setStep("welcomePage");
      } else {
        dispatch(setUserDetails(data));
        navigate("/offers", { replace: true });
      }
    } else {
      toaster("info", "Your request is pending try after sometime");
      dispatch(setClearUserDtls());
    }
  };

  /**
   * To handle the input changes
   * @param {event}
   */
  const handleChangeFields = (e) => {
    const { name, value } = e.target;
    if (name === "email" || name === "passwordAgain") {
      setValidationError({});
    }
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value: name === "email" ? value.toLowerCase() : value,
        error: false,
      },
    });
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          error: value ? false : true,
        },
      });
    }
  };

  /**
   * To handle sent email
   */
  const handlerGenerateOtp = async () => {
    try {
      startLoading();
      const response = await generateOtp({
        email: formValues?.email.value || tempUserDtls?.email.value,
      }).unwrap();
      if (response?.data) {
        toaster("success", "Email sent successfully");
        setStep("otpVerify");
      } else {
        toaster("error", response?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Email sent:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };

  /**
   * To handle verify otp
   */
  const handlerVerifyOtp = async () => {
    try {
      startLoading();
      const response = await verifyOtp({
        email: formValues?.email?.value,
        otp,
        isEmailVerification: true,
      }).unwrap();
      if (response?.data) {
        if (isNewUser && response?.data?.type === "influencer") {
          setStep("connectSocialMedia");
          return;
        }
        if (isForgetPassword) {
          toaster("success", "OTP verification successfully completed");
          navigateToOffer(response.data);
        } else {
          dispatch(setUserDetails({ ...response?.data }));
          toaster("success", "OTP verification successfully completed ");
          setStep("accountConfirm");
        }
      } else {
        toaster("error", "OTP verification failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to OTP verification:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle the forget password
   */
  const handlerForgetPassword = () => {
    setIsForgetPassword(true);
  };

  /**
   * To handle the back to signin
   */
  const handlerBackToSignIn = () => {
    setStep("signIn");
    dispatch(setTemporaryUserDtls({}));
    setFormValues(initialFormValue);
    dispatch(setIsNewUser(false));
    setUserId("");
  };

  /**
   * To validate email
   */
  const validateEmail = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(e?.target?.value)) {
      setValidationError({
        ...validationError,
        email: false,
        emailErrorMessage: "",
      });
    } else {
      setValidationError({
        ...validationError,
        email: true,
        emailErrorMessage: "Invalid email",
      });
    }
  };

  /**
   * To validate email
   */
  const validatePassword = (value1, value2) => {
    if (value1 !== value2) {
      setValidationError({
        ...validationError,
        passwordAgain: true,
        passwordErrorMessage: "Passwords don’t match",
      });
    } else {
      setValidationError({
        ...validationError,
        passwordAgain: false,
        passwordErrorMessage: "",
      });
    }
  };
  /**
   * To get input field for the respective tab
   * @returns array of jsx element
   */
  // const inputField = () => {
  //   const fieldList = [];
  //   if (step === "signUp" && !isForgetPassword) {
  //     fieldList.push(
  //       <Paper
  //         component="form"
  //         sx={{
  //           p: "2px 4px",
  //           display: "flex",
  //           alignItems: "center",
  //           width: 336,
  //           height: 56,
  //           boxShadow: 0,
  //         }}
  //       >
  //         <SvgIcon component={UserNameIcon} sx={{ mr: 1 }} />
  //         <InputBase
  //           onChange={handleChangeFields}
  //           onBlur={handleOnBlur}
  //           value={formValues.firstName.value}
  //           name="firstName"
  //           type={"text"}
  //           variant="outlined"
  //           size="small"
  //           placeholder="First name"
  //           required={true}
  //           error={formValues.firstName.error}
  //           helperText={
  //             formValues.firstName.error && formValues.firstName.errorMessage
  //           }
  //           sx={{ flex: 1 }}
  //           inputProps={{ "aria-label": "search google maps" }}
  //         />
  //       </Paper>,
  //       <Paper
  //         component="form"
  //         sx={{
  //           p: "2px 4px",
  //           display: "flex",
  //           alignItems: "center",
  //           width: 336,
  //           height: 56,
  //           boxShadow: 0,
  //         }}
  //       >
  //         <SvgIcon component={UserNameIcon} sx={{ mr: 1 }} />
  //         <InputBase
  //           onChange={handleChangeFields}
  //           onBlur={handleOnBlur}
  //           value={formValues.lastName.value}
  //           name="lastName"
  //           type={"text"}
  //           variant="outlined"
  //           size="small"
  //           placeholder="Last name"
  //           required={true}
  //           error={formValues.lastName.error}
  //           helperText={
  //             formValues.lastName.error && formValues.lastName.errorMessage
  //           }
  //           sx={{ flex: 1 }}
  //           inputProps={{ "aria-label": "search google maps" }}
  //         />
  //       </Paper>
  //     );
  //   }
  //   if (step !== "resetPassword") {
  //     fieldList.push(
  //       <Paper
  //         component="form"
  //         sx={{
  //           p: "2px 4px",
  //           display: "flex",
  //           alignItems: "center",
  //           width: 336,
  //           height: 56,
  //           boxShadow: 0,
  //         }}
  //       >
  //         <SvgIcon component={emailIcon} sx={{ mr: 1 }} />
  //         <InputBase
  //           onChange={(e) => {
  //             handleChangeFields(e);
  //             validateEmail(e);
  //           }}
  //           value={formValues.email.value}
  //           name="email"
  //           type={"text"}
  //           variant="outlined"
  //           size="small"
  //           placeholder={tabView === "company" ? "Company email" : "Email"}
  //           required={true}
  //           onBlur={(e) => {
  //             handleOnBlur(e);
  //           }}
  //           error={formValues.email.error || validationError?.email}
  //           helperText={
  //             (formValues.email.error && formValues.email.errorMessage) ||
  //             (validationError?.email && validationError?.emailErrorMessage)
  //           }
  //         />
  //       </Paper>
  //     );
  //   }
  //   if (tabView === "company" && step === "signUp") {
  //     fieldList.push(
  //       <Paper
  //         component="form"
  //         sx={{
  //           p: "2px 4px",
  //           display: "flex",
  //           alignItems: "center",
  //           width: 336,
  //           height: 56,
  //           boxShadow: 0,
  //         }}
  //       >
  //         <SvgIcon component={globeIcon} sx={{ mr: 1 }} />
  //         <InputBase
  //           onChange={handleChangeFields}
  //           onBlur={handleOnBlur}
  //           value={formValues.companyWebsite.value}
  //           name="CompanyWebsite"
  //           type={"text"}
  //           variant="outlined"
  //           size="small"
  //           placeholder="Company Website"
  //           required={true}
  //           error={formValues.companyWebsite.error}
  //           helperText={
  //             formValues.companyWebsite.error &&
  //             formValues.companyWebsite.errorMessage
  //           }
  //           sx={{ flex: 1 }}
  //           inputProps={{ "aria-label": "search google maps" }}
  //         />
  //       </Paper>
  //     );
  //   }
  //   if (!isForgetPassword) {
  //     fieldList.push(
  //       <Paper
  //         component="form"
  //         sx={{
  //           p: "2px 4px",
  //           display: "flex",
  //           alignItems: "center",
  //           width: 336,
  //           height: 56,
  //           boxShadow: 0,
  //         }}
  //       >
  //         <SvgIcon component={passwordIcon} sx={{ mr: 1 }} />
  //         <InputBase
  //           onChange={(e) => {
  //             handleChangeFields(e);
  //             if (formValues.passwordAgain.value)
  //               validatePassword(
  //                 e?.target?.value,
  //                 formValues.passwordAgain.value
  //               );
  //           }}
  //           value={formValues.password.value}
  //           name="password"
  //           type={"password"}
  //           variant="outlined"
  //           size="small"
  //           placeholder="Password"
  //           required={true}
  //           onBlur={handleOnBlur}
  //           error={formValues.password.error}
  //           helperText={
  //             formValues.password.error && formValues.password.errorMessage
  //           }
  //         />
  //       </Paper>
  //     );
  //   }

  //   if ((step === "signUp" || step === "resetPassword") && !isForgetPassword) {
  //     fieldList.push(
  //       <Paper
  //         component="form"
  //         sx={{
  //           p: "2px 4px",
  //           display: "flex",
  //           alignItems: "center",
  //           width: 336,
  //           height: 56,
  //           boxShadow: 0,
  //         }}
  //       >
  //         <SvgIcon component={passwordIcon} sx={{ mr: 1 }} />
  //         <InputBase
  //           onChange={(e) => {
  //             handleChangeFields(e);
  //             validatePassword(e?.target?.value, formValues?.password?.value);
  //           }}
  //           value={formValues.passwordAgain.value}
  //           name="passwordAgain"
  //           type={"password"}
  //           size="small"
  //           variant="outlined"
  //           placeholder={
  //             isForgetPassword ? "Confirm password" : "Password again"
  //           }
  //           onBlur={(e) => {
  //             handleOnBlur(e);
  //           }}
  //           error={
  //             formValues?.passwordAgain?.error || validationError?.passwordAgain
  //           }
  //           helperText={
  //             (formValues.passwordAgain.error &&
  //               formValues.passwordAgain.errorMessage) ||
  //             (validationError?.passwordAgain &&
  //               validationError?.passwordErrorMessage)
  //           }
  //         />
  //       </Paper>
  //     );
  //   }
  //   return fieldList;
  // };

  const inputField = () => {
    const fieldList = [];

    const inputStyle = {
      p: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 336,
      height: 56,
      boxShadow: 0,
    };

    const errorTextStyle = { color: "red", fontSize: "12px", marginTop: "4px" };

    if (step === "signUp" && !isForgetPassword) {
      fieldList.push(
        <div>
          <Paper component="form" sx={inputStyle}>
            <SvgIcon component={UserNameIcon} sx={{ mr: 1 }} />
            <InputBase
              onChange={handleChangeFields}
              onBlur={handleOnBlur}
              value={formValues.firstName.value}
              name="firstName"
              type={"text"}
              placeholder="First name"
              required
              sx={{ flex: 1 }}
            />
          </Paper>
          {formValues.firstName.error && (
            <div style={errorTextStyle}>
              {formValues.firstName.errorMessage}
            </div>
          )}
        </div>,

        <div>
          <Paper component="form" sx={inputStyle}>
            <SvgIcon component={UserNameIcon} sx={{ mr: 1 }} />
            <InputBase
              onChange={handleChangeFields}
              onBlur={handleOnBlur}
              value={formValues.lastName.value}
              name="lastName"
              type={"text"}
              placeholder="Last name"
              required
              sx={{ flex: 1 }}
            />
          </Paper>
          {formValues.lastName.error && (
            <div style={errorTextStyle}>{formValues.lastName.errorMessage}</div>
          )}
        </div>
      );
    }

    if (step !== "resetPassword") {
      fieldList.push(
        <div>
          <Paper component="form" sx={inputStyle}>
            <SvgIcon component={emailIcon} sx={{ mr: 1 }} />
            <InputBase
              onChange={(e) => {
                handleChangeFields(e);
                validateEmail(e);
              }}
              onBlur={
                (e) => {
                  handleOnBlur(e)
                }
              }
              value={formValues.email.value}
              name="email"
              type={"text"}
              placeholder={tabView === "company" ? "Company email" : "Email"}
              required
            />
          </Paper>
          {(formValues.email.error || validationError?.email) && (
            <div style={errorTextStyle}>
              {(formValues.email.error && formValues.email.errorMessage) ||
                validationError?.emailErrorMessage}
            </div>
          )}
        </div>
      );
    }

    if (tabView === "company" && step === "signUp") {
      fieldList.push(
        <div>
          <Paper component="form" sx={inputStyle}>
            <SvgIcon component={globeIcon} sx={{ mr: 1 }} />
            <InputBase
              onChange={handleChangeFields}
              onBlur={handleOnBlur}
              value={formValues.companyWebsite.value}
              name="companyWebsite"
              type={"text"}
              placeholder="Company Website"
              required
              sx={{ flex: 1 }}
            />
          </Paper>
          {formValues.companyWebsite.error && (
            <div style={errorTextStyle}>
              {formValues.companyWebsite.errorMessage}
            </div>
          )}
        </div>
      );
    }

    if (!isForgetPassword) {
      fieldList.push(
        <div>
          <Paper component="form" sx={inputStyle}>
            <SvgIcon component={passwordIcon} sx={{ mr: 1 }} />
            <InputBase
              onChange={(e) => {
                handleChangeFields(e);
                if (formValues.passwordAgain.value)
                  validatePassword(
                    e?.target?.value,
                    formValues.passwordAgain.value
                  );
              }}
              value={formValues.password.value}
              name="password"
              type={"password"}
              placeholder="Password"
              required
              onBlur={handleOnBlur}
              sx={{ flex: 1 }}
            />
          </Paper>
          {formValues.password.error && (
            <div style={errorTextStyle}>{formValues.password.errorMessage}</div>
          )}
        </div>
      );
    }

    if ((step === "signUp" || step === "resetPassword") && !isForgetPassword) {
      fieldList.push(
        <div>
          <Paper component="form" sx={inputStyle}>
            <SvgIcon component={passwordIcon} sx={{ mr: 1 }} />
            <InputBase
              onChange={(e) => {
                handleChangeFields(e);
                validatePassword(e?.target?.value, formValues?.password?.value);
              }}
              value={formValues.passwordAgain.value}
              name="passwordAgain"
              type={"password"}
              placeholder={
                isForgetPassword ? "Confirm password" : "Password again"
              }
              onBlur={handleOnBlur}
              sx={{ flex: 1 }}
            />
          </Paper>
          {(formValues.passwordAgain.error ||
            validationError?.passwordAgain) && (
              <div style={errorTextStyle}>
                {(formValues.passwordAgain.error &&
                  formValues.passwordAgain.errorMessage) ||
                  validationError?.passwordErrorMessage}
              </div>
            )}
        </div>
      );
    }

    return fieldList;
  };

  /**
   * To handle set tab view
   * @param (tabName)
   * @returns null
   */
  const handleSetTabView = (tabName) => {
    setFormValues(initialFormValue);
    setTabView(tabName);
    setFormValues({
      ...initialFormValue,
      userType: {
        value: tabName,
        error: false,
      },
    });
    setStep("signUp");
    dispatch(setIsNewUser(true));
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  /**
   * To handle instagram login
   */
  const handlerInstagramLogin = async () => {
    try {
      startLoading();
      const resp = await instagramOauth();
      if (resp?.data?.url) {
        window.location.href = `${resp?.data.url}`;
      }
    } catch (err) {
      console.log(err);
    } finally {
      // stopLoading();
    }
  };

  useEffect(() => {
    if (location?.pathname === "/signUp") {
      setStep("signUp");
    }
  }, [location.pathname]);

  //Social medial login
  const [getAccessToken] = useGetInstagramAccessTokenMutation();
  const [getInstagramUserData] = useGetInstagramUserDataMutation();
  const [getUserByInstagramOpenId] = useFindUserByInstagramOpenIdMutation();
  const [getLongLiveToken] = useGetInstagramLongLiveTokenMutation();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  /**
   * To fetch the access token
   */
  const fetchAccessToken = async (code) => {
    try {
      startLoading();
      const response = await getAccessToken({ code }).unwrap();
      if (response?.data) {
        fetchInstagramUserData(response?.data);
      } else {
        toaster("error", "Instagram login failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Instagram login :", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To fetch the instagram user data
   */
  const fetchInstagramUserData = async (responseData) => {
    try {
      startLoading();
      const resp = await getLongLiveToken(responseData?.access_token);
      if (resp?.data) {
        dispatch(setInstagramAccessToken(responseData));
      } else {
        toaster("error", "Instagram login failed");
        handlerBackToSignIn();
        return;
      }
      const instagramUserData = await getInstagramUserData(
        resp?.data?.access_token
      ).unwrap();
      if (instagramUserData?.id) {
        dispatch(setInstagramData(instagramUserData));
        setSocialMediaOpenId(instagramUserData?.id);

        if (!isNewUser) {
          // setStep()
          fetchUserByInstagramOpenId(instagramUserData?.id);
        } else {
          //update open id to that user
          await updateOpenId({
            userId: userDtls?.userId,
            openId: instagramUserData?.id,
            access_token: resp?.data?.access_token,
            followers_count: resp?.data?.followers_count,
          });
          // handlerSignUp(instagramUserData?.id);
        }
      } else {
        toaster("error", "Instagram login failed");
        handlerBackToSignIn();
      }
    } catch (err) {
      handlerBackToSignIn();
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Instagram login :", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };

  /**
   * To fetch the access token
   */
  const fetchUserByInstagramOpenId = async (openId) => {
    try {
      startLoading();
      const response = await getUserByInstagramOpenId({ openId }).unwrap();
      if (response?.data) {
        toaster("success", "Sign in successfully completed");
        navigateToOffer(response.data);
      } else {
        setStep("signUp");
        toaster("error", "Failed to Instagram login");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Instagram login :", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    if (code) {
      // for local testing purpose
      // const frontendHost = process.env.REACT_APP_FRONTEND_HOST;
      // if (window.location.origin !== frontendHost) {
      //   window.location.href = `${frontendHost}/signIn?code=${code}`;
      //   console.log(">>>>>>>>>>>>>;", window.location.origin, frontendHost);
      // } else {
      //   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", frontendHost);
      fetchAccessToken(code);
      if (isNewUser) {
        setStep("connectSocialMedia");
      } else {
        setStep("signIn");
      }
      // }
    }
  }, [code]);

  return (
    <div className="signIn-ctn">
      <div className="signIn-overlay">
        {/* <div className="section-1">
          <div className="login-img-content1">
            <img
              src={LoginImage}
              className="login-image1 img1"
              alt="loading"
            ></img>
          </div>
        </div> */}
        {showLogo && (
          <div className="logoLoader">
            <img alt="loading" src={symeLogo}></img>
          </div>
        )}
        <Container height="100%" className="section-2">
          <Box
            color="primary"
            variant="contained"
            width={"100%"}
            height={"100%"}
            className="mobile-image"
            sx={{
              // boxShadow: { xs: "none", md: 3 },
              bgcolor: "white",
            }}
            onKeyDown={(e) => {
              // className="boxing"
              if (e.key === "Enter") {
                e.preventDefault();
                const isEmailValid =
                  formValues?.email?.value && !formValues.email.error;
                const isPasswordValid =
                  formValues?.password?.value && !formValues.password.error;

                if (isEmailValid && isPasswordValid) {
                  handlerSignIn();
                }
              }
            }}
          >
            <Box component="section" sx={{ width: "100%", height: "100%" }}>
              <Box textAlign={"center"} height={200}>
                <img
                  alt="loading"
                  src={symeLogo}
                  style={{ marginTop: 60 }}
                ></img>
              </Box>
              {step === "signIn" && (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  height={" calc(100% - 220px)"}
                  marginTop={"8px"}
                  marginBottom={"8px"}
                >
                  <Typography
                    variant="h5"
                    padding={1}
                    textAlign="center"
                    letterSpacing="-0.01em"
                    fontWeight={500}
                    fontSize={16}
                    color="primary"
                    sx={{ lineHeight: "var(--Label-Large-Line-Height, 20px)", textDecoration: "underline", letterSpacing: "var(--Label-Large-Tracking, 0.1px)" }}
                  >
                    {isForgetPassword ? "I forgot my password" : "Log in"}
                  </Typography>

                  {/* <Divider
                    sx={{ width: "80%" }}
                    component="div"
                    role="presentation"
                  /> */}
                  {/* <Typography
                    padding={1}
                    textAlign="left"
                    letterSpacing={"-0.01em"}
                    fontSize={20}
                    fontWeight={500}
                  >
                    {isForgetPassword
                      ? ""
                      : ""}
                  </Typography> */}
                  <Box pl={1}>
                    {inputField().map((ele) => {
                      return ele;
                    })}
                  </Box>

                  {isForgetPassword ? (
                    <Stack direction={"row"} gap={2} marginTop={"auto"}>
                      <MyButton
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          textTransform: "capitalize",
                          color: "primary.main",
                          height: 56,
                          width: 162,
                          borderRadius: 4,
                          bgcolor: "white.main",
                        }}
                        onClick={() => {
                          setStep("signIn");
                          setIsForgetPassword(false);
                          setFormValues(initialFormValue);
                          setValidationError(initialValidationErrorValue);
                        }}
                      >
                        Back
                      </MyButton>
                      <MyButton
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          textTransform: "capitalize",
                          height: 56,
                          width: 162,
                          borderRadius: 4,
                        }}
                        disabled={
                          !formValues?.email?.value || validationError?.email
                        }
                        onClick={
                          isForgetPassword
                            ? handlerGenerateOtp
                            : handlerVerifyOtp
                        }
                      >
                        {isForgetPassword ? "Reset password" : "Verify Otp"}
                      </MyButton>
                    </Stack>
                  ) : (
                    <>
                      <Typography
                        color={"primary"}
                        variant="h5"
                        padding={1}
                        fontWeight={500}
                        fontSize={14}
                        onClick={handlerForgetPassword}
                        mt={2}
                        sx={{ lineHeight: "var(--Label-Large-Line-Height, 20px)", letterSpacing: "var(--Label-Large-Tracking, 0.1px)", cursor: "pointer" }}
                      // borderBottom={"1px solid #8F3A98"}
                      >
                        I forgot my password
                        <Divider
                          sx={{ borderColor: "primary.main" }}
                        // component="div"
                        // role="presentation"
                        />
                      </Typography>
                      {/* // <> */}
                      <Typography
                        padding={1}
                        textAlign="center"
                        letterSpacing={"-0.01em"}
                        fontSize={24}
                        fontWeight={600}
                        color="primary"
                        sx={{ lineHeight: "var(--Label-Large-Line-Height, 20px)", letterSpacing: "var(--Label-Large-Tracking, 0.1px)" }}

                      >
                        Or login with
                      </Typography>
                      <Stack direction="row" spacing={2} style={{
                        marginTop: "5px"
                      }}>
                        <InstagramIcon
                          color="#8F3A98"
                          onClick={handlerInstagramLogin}
                          sx={{ cursor: "pointer", color: "primary.main" }}
                        />
                        <FacebookIcon
                          sx={{ cursor: "pointer", color: "primary.main" }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} marginTop={"auto"}>
                        <Button
                          onClick={() => {
                            setStep("selectUserType");
                            setFormValues(initialFormValue);
                            setValidationError(initialValidationErrorValue);
                            dispatch(setIsNewUser(true));
                          }}
                          variant="contained"
                          sx={{
                            fontSize: "16px",
                            fontWeight: 400,
                            textTransform: "none",
                            height: 56,
                            width: 162,
                            borderRadius: 4,
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={handlerSignIn}
                          sx={{
                            fontSize: "16px",
                            fontWeight: 400,
                            textTransform: "none",
                            color: "primary.main",
                            height: 56,
                            width: 162,
                            borderRadius: 4,
                            bgcolor: "white.main",
                          }}
                          variant="contained"
                          disabled={
                            !formValues?.email?.value ||
                            !formValues?.password?.value ||
                            formValues.email.error ||
                            formValues.password.error ||
                            validationError?.email
                          }
                        >
                          Sign in
                        </Button>
                      </Stack>
                    </>
                  )}
                </Box>
              )}
              {step === "selectUserType" && (
                <>
                  <Stack
                    sx={{ width: "100%" }}
                    alignItems={"center"}
                    height={" calc(100% - 240px)"}
                  >
                    <Typography
                      variant="h5"
                      padding={1}
                      sx={{ height: "63px" }}
                      fontWeight={500}
                      fontSize={16}
                      color={"primary"}
                      mt={8}
                    >
                      Create account
                    </Typography>
                    <Stack
                      sx={{ height: "147px", padding: "10px", gap: "15px" }}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <MyButton
                        sx={{
                          height: "56px",
                          width: "312px",
                          borderRadius: "16px",
                          textTransform: "none",
                        }}
                        onClick={() => handleSetTabView("influencer")}
                      >
                        I am a influencer
                      </MyButton>
                      <MyButton
                        color="secondary"
                        sx={{
                          height: "56px",
                          width: "312px",
                          borderRadius: "16px",
                          textTransform: "none",
                        }}
                        onClick={() => handleSetTabView("company")}
                      >
                        I am a company
                      </MyButton>
                    </Stack>
                    <Typography
                      color={"primary"}
                      sx={{ cursor: "pointer" }}
                      variant="h5"
                      padding={1}
                      fontWeight={500}
                      fontSize={14}
                      onClick={handlerBackToSignIn}
                      mt={2}

                    // borderBottom={"1px solid #8F3A98"}
                    >
                      I am already a member
                      <Divider
                        sx={{ borderColor: "primary.main" }}
                      // component="div"
                      // role="presentation"
                      />
                    </Typography>
                  </Stack>
                </>
              )}
              {step === "signUp" && (
                <SignUp
                  {...{
                    inputFieldList: inputField(),
                    formValues,
                    setFormValues,
                    handleSetTabView,
                    tabView,
                    handlerBackToSignIn,
                    setStep,
                    validationError,
                    handlerSignUp,
                  }}
                />
              )}
              {step === "resetPassword" && (
                <ResetPassword
                  inputField={inputField()}
                  formValues={formValues}
                  validationError={validationError}
                  handlerResetPassword={handlerResetPassword}
                  handleBack={handlerBackToSignIn}
                />
              )}
              {step === "uploadProfile" && <UploadProfile setStep={setStep} />}
              {step === "otpVerify" && (
                <EmailOtpVerification
                  otp={otp}
                  setOtp={setOtp}
                  email={formValues?.email?.value || tempUserDtls?.email?.value}
                  handlerVerifyOtp={handlerVerifyOtp}
                  handleBack={handlerBackToSignIn}
                />
              )}
              {step === "connectSocialMedia" && (
                <ConnectSocialMedia
                  socialMediaOpenId={socialMediaOpenId}
                  userName={`${formValues?.firstName?.value} ${formValues?.lastName?.value}`}
                  setStep={setStep}
                  handlerInstagramLogin={handlerInstagramLogin}
                  handlerSignUp={handlerSignUp}
                  handlerGenerateOtp={handlerGenerateOtp}
                  {...{
                    formValues,
                    setFormValues,
                  }}
                />
              )}
              {step === "accountConfirm" && (
                // <AccountConfirm
                //   name={
                //     formValues?.userName?.value || tempUserDtls?.userName?.value
                //   }
                // />
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  height={" calc(100% - 240px)"}
                >
                  <Typography
                    variant="h5"
                    padding={1}
                    sx={{ height: "63px" }}
                    fontWeight={500}
                    fontSize={16}
                    color={"primary"}
                    mt={1}
                  >
                    Pending member
                  </Typography>
                  {/* <ContractPendingIcon /> */}
                  <img
                    width={175}
                    height={150}
                    src={ContractPendingIcon}
                    alt="loading"
                  />
                  <Typography
                    variant="h5"
                    // padding={1}
                    fontWeight={400}
                    fontSize={14}
                    color={"primary"}
                    textAlign={"center"}
                    mt={4}
                  >
                    Thank you for your application.
                  </Typography>
                  <Typography
                    variant="h5"
                    // padding={1}
                    fontWeight={400}
                    fontSize={14}
                    color={"primary"}
                    textAlign={"center"}
                  >
                    If you are approved, you will be notified.
                  </Typography>
                  <Button
                    endIcon={<materialIcons.rightArrow />}
                    variant="contained"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      textTransform: "capitalize",
                      height: 56,
                      minWidth: 336,
                      borderRadius: 4,
                      mt: "auto",
                    }}
                    size="large"
                    onClick={handlerBackToSignIn}
                  >
                    Next
                  </Button>
                </Box>
              )}
              {step === "welcomePage" && (
                <Box
                  height={" calc(100% - 240px)"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h5"
                    padding={1}
                    sx={{ height: "63px" }}
                    fontWeight={500}
                    fontSize={16}
                    color={"primary"}
                    mt={1}
                  >
                    Membership Approved
                  </Typography>
                  {/* <ContractPendingIcon /> */}
                  <Box width={75} height={75} mt={2}>
                    <MyIcon icon={checkBigIcon} alt="loading" />
                  </Box>

                  <Typography
                    variant="h5"
                    // padding={1}
                    fontWeight={400}
                    fontSize={14}
                    color={"primary"}
                    textAlign={"center"}
                    mt={5}
                  >
                    Welcome to SYME
                  </Typography>
                  <Button
                    endIcon={<materialIcons.rightArrow />}
                    variant="contained"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      textTransform: "capitalize",
                      height: 56,
                      minWidth: 336,
                      borderRadius: 4,
                      mt: "auto",
                    }}
                    size="large"
                    onClick={handleUpdateUserData}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </div>
    </div >
  );
};

export default SignIn;
