import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Stack,
  SvgIcon,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  EditIcon,
  emailIcon,
  globeIcon,
  passwordIcon,
  UserNameIcon,
} from "../../utils/materialIcon";
import MyButton from "../MyButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  useGenerateOtpMutation,
  useResetPasswordMutation,
  useUpdateUserMutation,
  useVerifyOtpMutation,
} from "../../services/apiService/userApiService";
import { setUserDetails } from "../../store/Slicers/authSlice";
import toaster from "../Toaster/toaster";
import useLoading from "../../hooks/useLoading";
import OTPInput from "../OTPInput/OTPInput";

const NewSetting = () => {
  const { userDtls, userType } = useSelector((state) => state?.auth);
  const navigate = useNavigate(); // To navigate
  const dispatch = useDispatch(); //dispatch the user details
  const [updateUserInfo] = useUpdateUserMutation(); // api for update user details
  const [resetPassword] = useResetPasswordMutation();
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const { startLoading, stopLoading } = useLoading();
  const [generateOtp] = useGenerateOtpMutation();
  const [verifyOtp] = useVerifyOtpMutation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [modalFields, setModalFields] = useState([]);
  const [showOTPValidator, setShowOTPValidator] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedEditField, setSelectedEditField] = useState("");
  const [otp, setOtp] = useState("");
  const initialFormValue = {
    firstName: {
      value: userDtls?.firstName || "",
      error: false,
      errorMessage: "You must enter first name",
    },
    lastName: {
      value: userDtls?.lastName || "",
      error: false,
      errorMessage: "You must enter last name",
    },
    email: {
      value: userDtls?.email || "",
      error: false,
      errorMessage: "You must enter email",
    },
    password: {
      value: "*****",
      error: false,
      errorMessage: "You must enter your password",
    },
    passwordAgain: {
      value: "",
      error: false,
      errorMessage: "You must enter your password",
    },
    companyWebsite: {
      value: userDtls?.companyWebsite || "",
      error: false,
    },
  };
  const initialValidationErrorValue = {
    email: false,
    passwordAgain: false,
    message: "",
  };
  const [validationError, setValidationError] = useState(
    initialValidationErrorValue
  );
  const [formValues, setFormValues] = useState({ ...initialFormValue });
  /**
   * To open edit modal
   */
  const handlerOpenEditModal = (key) => {
    setModalTitle(key);
    setModalFields(getFields(key));
    setOpenModal(true);
  };

  const inputStyle = {
    p: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 336,
    height: 56,
    boxShadow: 0,
    mt: 2,
  };

  useEffect(() => {
    setFormValues(initialFormValue);
  }, [userDtls]);
  /**
   * To handle sent email
   */
  const handlerGenerateOtp = async () => {
    try {
      startLoading();
      const response = await generateOtp({
        email: userDtls?.email,
        newEmail: formValues?.email.value,
      }).unwrap();
      if (response?.data) {
        toaster("success", "Email sent successfully");
      } else {
        toaster("error", response?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Email sent:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle verify otp
   */
  const handlerVerifyOtp = async () => {
    try {
      startLoading();
      const response = await verifyOtp({
        email: userDtls?.email,
        otp,
        isEmailVerification: true,
      }).unwrap();
      if (response?.data) {
        if (selectedEditField === "password") {
          setSelectedEditField("passwordAgain");
          setShowOTPValidator(false);
        } else {
          handlerUpdateUser();
        }
      } else {
        handlerCloseModal();
        setShowOTPValidator(false);
        toaster("error", "OTP verification failed");
      }
      setOtp("");
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to OTP verification:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };

  /**
   * To update the user details
   */
  const handlerUpdateUser = async () => {
    // const payload = {};
    try {
      startLoading();
      const resp = await updateUserInfo({
        userId: userDtls?.userId,
        toBeUpdated: {
          [selectedEditField === "passwordAgain"
            ? "password"
            : selectedEditField]: formValues[selectedEditField]?.value,
        },
      });
      if (resp?.data) {
        dispatch(setUserDetails({ ...resp.data, isLoggedIn: true }));
        toaster("info", "User details updated successfully");
      }
      handlerCloseModal();
      setShowOTPValidator(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toaster("error", "updating user details failed");
    }
    stopLoading();
    setOpenModal(false);
  };
  /**
   * To handle the forget password
   */
  const handlerResetPassword = async () => {
    try {
      startLoading();
      const response = await resetPassword({
        email: userDtls?.email,
        newPassword: formValues?.password?.value,
      }).unwrap();
      if (response?.data) {
        toaster("success", "Reset password successfully completed");
        // navigateToOffer(response.data);
      } else {
        toaster("error", "Reset password failed");
      }
    } catch (err) {
      console.error("Failed to Reset password:", err);
      toaster("error", "Something went wrong");
    } finally {
      stopLoading();
    }
  };

  const handlerUpdateUserDetails = () => {
    if (selectedEditField === "email" || selectedEditField === "password") {
      if (otp) {
        handlerVerifyOtp();
      } else {
        handlerGenerateOtp();
        setShowOTPValidator(true);
      }
    } else if (selectedEditField === "passwordAgain") {
      handlerResetPassword();
    } else {
      handlerUpdateUser();
    }
  };

  /**
   * To close the update modal
   */
  const handlerCloseModal = () => {
    // setSelectedFields("all");
    setOpenModal(false);
    setModalFields([]);
    setSelectedEditField("");
    setFormValues(initialFormValue);
    setShowOTPValidator(false);
    setValidationError(initialValidationErrorValue);
  };
  /**
   * To handle the input changes
   * @param {event}
   */
  const handleChangeFields = (e) => {
    const { name, value } = e.target;
    if (name === "email" || name === "passwordAgain") {
      setValidationError({});
    }
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value: name === "email" ? value.toLowerCase() : value,
        error: false,
      },
    });
  };
  /**
   * To validate email
   */
  const validateEmail = (e) => {
    console.log("e", e?.target?.value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validateEmail = emailRegex.test(e?.target?.value);

    if (validateEmail) {
      setValidationError({
        ...validationError,
        email: false,
        emailErrorMessage: "",
      });
    } else {
      setValidationError({
        ...validationError,
        email: true,
        emailErrorMessage: "Invalid email",
      });
    }
  };

  /**
   * To validate email
   */
  const validatePassword = (value1, value2) => {
    if (value1 !== value2) {
      setValidationError({
        ...validationError,
        passwordAgain: true,
        passwordErrorMessage: "Password is not match",
      });
    } else {
      setValidationError({
        ...validationError,
        passwordAgain: false,
        passwordErrorMessage: "",
      });
    }
  };
  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    if (!value && value === userDtls[name]) {
      setFormValues({
        ...formValues,
        [name]: {
          ...formValues[name],
          error: value ? false : true,
        },
      });
    }
  };
  const isUpdateDisabled = () => {
    if (selectedEditField === "passwordAgain") {
      return (
        !formValues?.password?.value ||
        !formValues?.passwordAgain?.value ||
        formValues?.password?.value !== formValues?.passwordAgain?.value
      );
    } else if (selectedEditField === "password") {
      return false;
    } else if (selectedEditField === "email") {
      const disable = validationError.email ? false : true;
      return !disable;
    }
    return (
      !formValues[selectedEditField]?.value ||
      formValues[selectedEditField]?.value === userDtls[selectedEditField]
    );
  };
  /**
   * To get require fields
   */
  const getFields = (key) => {
    const fields = [];
    if (key === "first name" || key === "firstName" || key === "all") {
      fields.push(
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 336,
            height: 56,
            boxShadow: 0,
          }}
        >
          <SvgIcon component={UserNameIcon} sx={{ mr: 1 }} />
          <InputBase
            onChange={handleChangeFields}
            onBlur={handleOnBlur}
            value={formValues?.firstName?.value}
            defaultValue={formValues?.firstName?.value || userDtls?.firstName}
            name="firstName"
            type={"text"}
            variant="outlined"
            size="small"
            placeholder="First name"
            required={true}
            error={formValues.firstName.error}
            helperText={
              formValues.firstName.error && formValues.firstName.errorMessage
            }
            sx={{ flex: 1 }}
            inputProps={{ "aria-label": "search google maps" }}
            readOnly={key === "all"}
          />
          {key === "all" && (
            <IconButton
              onClick={() => {
                setSelectedEditField("firstName");
                handlerOpenEditModal("first name");
              }}
            >
              <SvgIcon component={EditIcon} />
            </IconButton>
          )}
        </Paper>
      );
    }
    if (key === "last name" || key === "lastName" || key === "all") {
      fields?.push(
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 336,
            height: 56,
            boxShadow: 0,
          }}
        >
          <SvgIcon component={UserNameIcon} sx={{ mr: 1 }} />
          <InputBase
            onChange={handleChangeFields}
            //   onBlur={handleOnBlur}
            value={formValues?.lastName?.value}
            defaultValue={formValues?.lastName?.value || userDtls?.lastName}
            name="lastName"
            type={"text"}
            variant="outlined"
            size="small"
            placeholder="Last name"
            required={true}
            error={formValues?.lastName?.error}
            helperText={
              formValues?.lastName?.error && formValues?.lastName?.errorMessage
            }
            sx={{ flex: 1 }}
            inputProps={{ "aria-label": "search google maps" }}
            readOnly={key === "all"}
          />
          {key === "all" && (
            <IconButton
              onClick={() => {
                setSelectedEditField("lastName");
                handlerOpenEditModal("last name");
              }}
            >
              <SvgIcon component={EditIcon} />
            </IconButton>
          )}
        </Paper>
      );
    }
    if (key === "email" || key === "password" || key === "all") {
      fields?.push(
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 336,
            height: 56,
            boxShadow: 0,
          }}
        >
          <SvgIcon component={emailIcon} sx={{ mr: 1 }} />
          <InputBase
            onChange={(e) => {
              handleChangeFields(e);
              validateEmail(e);
            }}
            sx={{ flex: 1 }}
            value={formValues?.email?.value}
            defaultValue={formValues?.email?.value || userDtls?.email}
            name="email"
            type={"text"}
            variant="outlined"
            size="small"
            //   placeholder={tabView === "company" ? "Company email" : "Email"}
            required={true}
            onBlur={(e) => {
              handleOnBlur(e);
            }}
            error={formValues?.email?.error || validationError?.email}
            helperText={
              (formValues?.email?.error && formValues?.email?.errorMessage) ||
              (validationError?.email && validationError?.emailErrorMessage)
            }
            readOnly={key === "all"}
          />
          {key === "all" && (
            <IconButton
              onClick={() => {
                setSelectedEditField("email");
                handlerOpenEditModal("email");
              }}
            >
              <SvgIcon component={EditIcon} />
            </IconButton>
          )}
        </Paper>
      );
    }
    if ((key === "companyWebsite" || key === "all") && userType === "company") {
      fields?.push(
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 336,
            height: 56,
            boxShadow: 0,
          }}
        >
          <SvgIcon component={globeIcon} sx={{ mr: 1 }} />
          <InputBase
            onChange={handleChangeFields}
            //   onBlur={handleOnBlur}
            value={formValues?.companyWebsite?.value}
            defaultValue={
              formValues?.companyWebsite?.value || userDtls?.companyWebsite
            }
            name="companyWebsite"
            type={"text"}
            variant="outlined"
            size="small"
            placeholder="Company Website"
            required={true}
            error={formValues?.companyWebsite?.error}
            helperText={
              formValues?.companyWebsite?.error &&
              formValues?.companyWebsite?.errorMessage
            }
            sx={{ flex: 1 }}
            inputProps={{ "aria-label": "search google maps" }}
            readOnly={key === "all"}
          />
          {key === "all" && (
            <IconButton
              onClick={() => {
                handlerOpenEditModal("Company Website");
                setSelectedEditField("companyWebsite");
              }}
            >
              <SvgIcon component={EditIcon} />
            </IconButton>
          )}
        </Paper>
      );
    }
    if (key === "passwordAgain" || key === "all") {
      fields.push(
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 336,
            height: 56,
            boxShadow: 0,
          }}
        >
          <SvgIcon component={passwordIcon} sx={{ mr: 1 }} />
          <InputBase
            onChange={(e) => {
              handleChangeFields(e);
              validatePassword(e?.target?.value, formValues?.password?.value);
            }}
            value={formValues?.password?.value}
            defaultValue={
              key === "passwordAgain"
                ? ""
                : formValues?.password?.value || "*************"
            }
            name="password"
            type={"password"}
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
            //   placeholder={isForgetPassword ? "Confirm password" : "Password again"}
            onBlur={(e) => {
              handleOnBlur(e);
            }}
            error={
              formValues?.passwordAgain?.error || validationError?.passwordAgain
            }
            helperText={
              (formValues?.passwordAgain?.error &&
                formValues?.passwordAgain?.errorMessage) ||
              (validationError?.passwordAgain &&
                validationError?.passwordErrorMessage)
            }
            readOnly={key === "all"}
          />
          {key === "all" && (
            <IconButton
              onClick={() => {
                setSelectedEditField("password");
                handlerOpenEditModal("password");
              }}
            >
              <SvgIcon component={EditIcon} />
            </IconButton>
          )}
        </Paper>
      );
    }
    return fields;
  };
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={1}
        mt={1}
        className="hideOnWeb"
      >
        <MyButton type={"backButton"} onClick={() => navigate(-1)} />
        <Typography fontSize={22} fontWeight={400}>
          setting
        </Typography>
      </Stack>
      <Box className="newSettingCtn">
        <Box>
          <Box maxWidth={"360px"}>
            <Typography fontSize={16} fontWeight={400} mb={2}>
              Account settings
            </Typography>
            {getFields("all")?.map((ele) => {
              return ele;
            })}
          </Box>
          <Divider width={"288px"} height={"1px"} sx={{ mb: 3 }} />
          <Box maxWidth={"360px"}>
            <Typography fontSize={16} fontWeight={400} mb={2} color={"#49454F"}>
              Notification settings
            </Typography>
            <Stack direction={"row"}>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={"primary"}
                mt={1}
              >
                Email notification
              </Typography>
              <FormControlLabel
                color="primary"
                sx={{
                  color: "primary.main",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
                value="true"
                control={<Switch color="primary" />}
                label="On"
                labelPlacement="start"
              />
            </Stack>
          </Box>
        </Box>
      </Box>
      {openModal && (
        <Dialog
          fullScreen={fullScreen}
          open={openModal}
          onClose={handlerCloseModal}
          aria-labelledby="responsive-dialog-title"
          sx={{
            borderRadius: 50,
            display: "flex",
            justifyContent: "center",
            bgcolor: "transparent",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          className="modalSection"
        >
          <Box className="modalCtn">
            <DialogTitle
              id="responsive-dialog-title"
              textAlign={"center"}
              fontSize={20}
              fontWeight={400}
            >
              {`Update ${modalTitle}`}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handlerCloseModal}
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ width: 280 }}>
              {/* <DialogContentText> */}
              {!showOTPValidator ? (
                <>
                  {getFields(selectedEditField)?.map((ele) => {
                    return ele;
                  })}
                  {selectedEditField === "passwordAgain" && (
                    <Paper component="form" sx={inputStyle}>
                      <SvgIcon component={passwordIcon} sx={{ mr: 1 }} />
                      <InputBase
                        onChange={(e) => {
                          handleChangeFields(e);
                          validatePassword(
                            e?.target?.value,
                            formValues?.password?.value
                          );
                        }}
                        value={formValues.passwordAgain.value}
                        name="passwordAgain"
                        type={"password"}
                        placeholder={"Password again"}
                        onBlur={handleOnBlur}
                        sx={{ flex: 1 }}
                      />
                    </Paper>
                  )}
                </>
              ) : (
                <OTPInput otp={otp} setOtp={setOtp} />
              )}
              {/* </DialogContentText> */}
            </DialogContent>
            {selectedEditField === "email" && validationError.email && (
              <span
                style={{
                  display: "flex",
                  width: "100%",
                  color: "red",
                  justifyContent: "center",
                }}
              >
                {validationError.emailErrorMessage}
              </span>
            )}

            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  color: "primary.main",
                  height: 56,
                  width: { xs: 130, md: 162 },
                  borderRadius: 4,
                  bgcolor: "white.main",
                }}
                size="large"
                onClick={handlerCloseModal}
              >
                Cancel
              </Button>
              <Button
                onClick={handlerUpdateUserDetails}
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  color: "white.main",
                  height: 56,
                  width: { xs: 130, md: 162 },
                  borderRadius: 4,
                  // bgcolor: "white.main",
                }}
                variant="contained"
                disabled={isUpdateDisabled()}
              >
                {selectedEditField === "email"
                  ? "Update Email"
                  : selectedEditField === "password"
                    ? "Update Password"
                    : "Update"}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};
export default NewSetting;
