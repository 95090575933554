import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const socialMediaApiService = createApi({
  reducerPath: "apiService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_HOST || "http://localhost:3001/",
  }),
  endpoints: (builder) => ({
    getInstagramUserDataByFields: builder.mutation({
      query: (data) => ({
        url: `https://graph.instagram.com/v20.0/me?fields=${data.fields}&access_token=${data.access_token}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getInstagramDataByFields: builder.mutation({
      query: (data) => ({
        url: `https://graph.instagram.com/v20.0/me/media?fields=${data.fields}&access_token=${data.access_token}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getInstagramMediaList: builder.mutation({
      query: (data) => ({
        url: `https://graph.instagram.com/v20.0/${data?.id}/media?fields=${data.fields}&access_token=${data.access_token}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getInstagramMediaById: builder.mutation({
      query: (data) => ({
        url: `https://graph.instagram.com/v20.0/${data?.id}?fields=${data.fields}&access_token=${data.access_token}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});
export const {
  useGetInstagramUserDataByFieldsMutation,
  useGetInstagramDataByFieldsMutation,
  useGetInstagramMediaListMutation,
  useGetInstagramMediaByIdMutation,
} = socialMediaApiService;
