import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useGetHomeOfferMutation,
  useGetOffersMutation,
  useUpdateBookingStatusMutation,
} from "../../services/apiService/userApiService";
import useLoading from "../../hooks/useLoading";
import toaster from "../../components/Toaster/toaster";
import { useSelector } from "react-redux";
import PhotoView from "../../components/Influencer/Offers/PhotoView";
import { useNavigate } from "react-router-dom";
import MyButton from "../../components/MyButton";
import { formatDate } from "../../utils/common";
import { notificationCode, offerStatusCode } from "../../utils/constants";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import materialIcons, {
  BookMarkIcon,
  SavedBookMarkIcon,
} from "../../utils/materialIcon";
import CheckIcon from "@mui/icons-material/Check";
import completedIcon from "../../assets/Icons/completedIcon.png";
import useNotification from "../../hooks/usePushNotification";

const Home = () => {
  // const [getOffers] = useGetOffersMutation();
  const { startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  const { sendNotification } = useNotification();
  const [updateBookingStatus] = useUpdateBookingStatusMutation();
  const [getHomeViewOffer] = useGetHomeOfferMutation();
  const [homeOffersList, setHomeOffersList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { userDtls } = useSelector((state) => state?.auth);
  /**
   * To handler fetch offers list
   */
  const handlerFetchOffers = async () => {
    try {
      startLoading();
      const response = await getHomeViewOffer().unwrap();
      if (response?.data) {
        const formateData = response?.data?.map((ele) => {
          ele.details.isOpened = false;
          ele.details.isExpanded = false;
          ele.details.offerList = offersListFormatter(ele?.details?.offerList)
          return ele;
        });
        setHomeOffersList(formateData);
      } else {
        setHomeOffersList([]);
      }
    } catch (err) {
      setHomeOffersList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  const offersListFormatter = (data) => {
    const formattedData = data?.map((item) => {
      let ele = { ...item };
      if (ele?.isRequestToBusiness) {
        ele.offerStatus = "pending";
      } else if (ele?.isOfferedToInfluencer) {
        ele.offerStatus = "check";
      } else if (ele?.isCompletedByInfluencer) {
        ele.offerStatus = "complete";
      } else {
        ele.offerStatus = "accept";
      }
      return ele;
    });
    return formattedData
    // const arr = Array.from({ length: 20 }).fill(formattedData[0]);
    // setHomeOffersList(formattedData);
  };

  useEffect(() => {
    handlerFetchOffers();
  }, [userDtls?.userId]);

  /**
   * To update booking status
   */
  const handlerUpdateBookingStatus = async (e, data, links, postDetails) => {
    try {
      startLoading();
      e.stopPropagation();
      e.preventDefault();
      let payload = {
        offerId: data?._id,
        influencerId: userDtls?.userId,
      };
      const notificationPayload = { isRead: false, type: "remainder" };
      if (data?.offerStatus === "accept") {
        payload.newStatus = "requestToBusiness";
        notificationPayload.message = notificationCode?.offerRequested;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data?.createdBy[0]?.userId || data?._id;
      } else if (data?.offerStatus === "approved") {
        payload.newStatus = "offeredToInfluencer";
        notificationPayload.message = notificationCode?.offerAccepted;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data?.createdBy[0]?.userId || data?._id;
      } else {
        payload.newStatus = "completedByInfluencer";
        notificationPayload.message = notificationCode?.offerCompleted;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data.createdBy[0].userId || data.createdBy;
        payload.links = links;
        if (postDetails) payload.postDetails = postDetails;
      }
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        handlerFetchOffers(true);
        sendNotification(notificationPayload);
      } else {
        toaster("error", response?.data?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };

  const toggleReadMore = (key, value, idx) => {
    setHomeOffersList((prev) => {
      const dup = [...prev];
      dup[idx].details[key] = value;
      return dup;
    });
  };
  /**
   * To handle select offer card
   */
  const handleSelectOffersCard = (data) => {
    navigate(`/offers/${data?._id}`);
  };

  return (
    <Box sx={{ p: { md: "6px 59px", xs: "6px 12px" } }} className="homeCtn">
      <Typography
        height={39}
        width={"100%"}
        fontSize={14}
        fontWeight={700}
        sx={{
          textTransform: "none",
          color: "#1D192B",
          p: "10px 12px",
          textAlign: "center",
          borderBottom: "2px solid",
          borderColor: "#8F3A98",
          mb: 2,
        }}
      >
        Explore SYME
      </Typography>
      {homeOffersList?.map((ele, idx) => {
        return (
          <Box sx={{ mb: 2 }}>
            {/* label */}
            <Stack
              onClick={() =>
                toggleReadMore("isOpened", !ele?.details?.isOpened, idx)
              }
              direction={"row"}
            >
              <Typography
                height={39}
                // width={"100%"}
                textAlign={"center"}
                fontSize={18}
                fontWeight={700}
                sx={{
                  textTransform: "capitalize",
                  color: "#1D192B",
                  // ml: 1,
                }}
              >
                {ele?.details?.label}
              </Typography>
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  bgcolor: "#f4ecf5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ml: "auto",
                }}
              >
                {ele?.details?.isOpened ? (
                  <ExpandLessOutlinedIcon
                    sx={{
                      color: "#8F3A98",
                    }}
                    onClick={() => toggleReadMore("isOpened", false, idx)}
                  />
                ) : (
                  <ExpandMoreOutlinedIcon
                    sx={{
                      color: "#8F3A98",
                    }}
                    onClick={() => toggleReadMore("isOpened", true, idx)}
                  />
                )}
              </Box>
            </Stack>
            {/* {ele?.details?.isOpened && (
              <> */}
            <Box
              maxWidth={"600px"}
              minWidth={"320px"}
              sx={{
                // p: { xs: 2 },
                minHeight: 60,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                multiline
                rows={4}
                variant="body1"
                component="p"
                noWrap={!ele?.details?.isExpanded}
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: ele?.details?.isExpanded ? "initial" : 4,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  alignSelf: "stretch",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#49454F",
                }}
              >
                {ele?.details?.description}
              </Typography>
              <Button
                variant="text"
                onClick={() =>
                  toggleReadMore("isExpanded", !ele?.details?.isExpanded, idx)
                }
                sx={{
                  color: "#49454F",
                  width: "fitContent",
                  fontSize: "14px",
                  fontWeight: 1000,
                  mr: "auto",
                  textTransform: "capitalize",
                  p: 0,
                  minWidth: "fit-content",
                }}
              >
                {ele?.details?.isExpanded ? "Less" : "More"}
              </Button>
            </Box>
            <div
              className={
                ele?.details?.isOpened
                  ? "photoViewContainer"
                  : "HomePageViewContainer"
              }
            >
              {ele?.details?.offerList?.map((data, index) => {
                return (
                  <PhotoView
                    style={{ overFlow: "auto" }}
                    data={data}
                    isSimilarView={!ele?.details?.isOpened}
                    noOption={!ele?.details?.isOpened}
                    handleSelectOffersCard={handleSelectOffersCard}
                    handlerFetchOffers={handlerFetchOffers}
                    handlerUpdateBookingStatus={handlerUpdateBookingStatus}
                    isSavedOffer={data?.isOfferSaved || false}
                    isHome={true}
                    key={index}
                    HomePagePhotoCardCtn={
                      !ele?.details?.isOpened && "HomePagePhotoCardCtn"
                    }
                  ></PhotoView>
                );
              })}

              {/* for no data display */}
              {ele?.offerList?.length === 0 && (
                <Box
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography fontWeight="700" variant="body1" color="primary">
                    No data found.
                  </Typography>
                </Box>
              )}
            </div>
            <Divider sx={{ borderColor: "#CAC4D0", mt: 2 }} />
            {/* </>
            )} */}
          </Box>
        );
      })}
    </Box>
  );
};
export default Home;
