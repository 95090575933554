import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import materialIcons from "../../utils/materialIcon";

const SignUp = (props) => {
  const {
    inputFieldList,
    formValues,
    setFormValues,
    handleSetTabView,
    handlerBackToSignIn,
    tabView,
    validationError,
    setStep,
    handlerSignUp,
  } = props;

  return (
    <Box
      height={" calc(100% - 220px)"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      mt={1}
      mb={1}
    >
      <Typography
        sx={{ color: "#8F3A98", mb: 1 }}
      >{`Create an ${tabView} account`}</Typography>
      <Box
        component="section"
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
        gap={"15px"}
        sx={{ width: "100%" }}
        mt={1}
        pl={2}
      >
        {inputFieldList?.map((ele, index) => {
          return ele;
        })}
      </Box>
      <Stack direction={"row"} gap={2} marginTop={"auto"}>
        <>
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "capitalize",
              color: "primary.main",
              height: 56,
              width: 162,
              borderRadius: 4,
              bgcolor: "white.main",
            }}
            size="large"
            onClick={() => {
              setStep("selectUserType");
            }}
          >
            Back
          </Button>
          <Button
            endIcon={<materialIcons.rightArrow />}
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              textTransform: "capitalize",
              height: 56,
              width: 162,
              borderRadius: 4,
            }}
            size="large"
            onClick={() => {
              handlerSignUp();
            }}
            disabled={
              !formValues?.email?.value ||
              !formValues?.password?.value ||
              !formValues?.firstName?.value ||
              !formValues?.lastName?.value ||
              !formValues?.passwordAgain?.value ||
              validationError?.email ||
              validationError?.passwordAgain
            }
          >
            Next
          </Button>
        </>
      </Stack>
    </Box>
  );
};

export default SignUp;
