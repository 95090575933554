import {
  Box,
  Button,
  CardMedia,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MyButton from "../../MyButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetOfferByIdMutation,
  useGetOffersMutation,
  useReportOfferMutation,
  useSaveOfferMutation,
  useUpdateBookingStatusMutation,
} from "../../../services/apiService/userApiService";
import useNotification from "../../../hooks/usePushNotification";
import useLoading from "../../../hooks/useLoading";
import { useEffect, useState } from "react";
import toaster from "../../Toaster/toaster";
import { formatDate } from "../../../utils/common";
import {
  CommentsIcon,
  CrossIcon,
  LikeIcon,
  NotesIcon,
  OfferIcon,
  VisibilityIcon,
} from "../../../utils/materialIcon";
import InstagramIcon from "@mui/icons-material/Instagram";
import defaultProfileImg from "../../../assets/image/defaultProfileImg.png";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import PostDetails from "../../postDetails/PostDetails";
import { notificationCode } from "../../../utils/constants";

const BookingDetails = ({ isCard }) => {
  const { offerId } = useParams(); // To get the offer id from URL
  const navigate = useNavigate(); // To navigate
  const isMobile = useMediaQuery("(max-width:657px)");
  const { userType, userDtls, instagramData } = useSelector(
    (state) => state?.auth
  ); //To get user details from store
  const [postCountObject, setPostCountObject] = useState({});
  const [getOffers] = useGetOffersMutation();
  const [getOfferById] = useGetOfferByIdMutation(); // API to fetch the offer by user and offer id
  const [updateBookingStatus] = useUpdateBookingStatusMutation(); // API to update the booking status
  const { sendNotification } = useNotification(); //To sent notification
  const { startLoading, stopLoading } = useLoading(); // To loader
  const [offerDtls, setOfferDtls] = useState({}); // Offer details
  const [fromCompanyList, setFromCompanyList] = useState([]);
  const [similarOfferList, setSimilarOfferList] = useState([]);
  const [reportDtls, setReportDtls] = useState({
    reason: [],
    explanation: "",
    status: "Pending",
  }); // To get the report details
  const [postDetails, setPostDetails] = useState([]);
  const [userData, setUserData] = useState()

  const handleExpand = (key, value, idx) => {
    setPostDetails((prev) => {
      const dup = [...prev];
      dup[idx][key] = value;
      return dup;
    });
  };

  /**
   * To fetch offer by id
   */
  const fetchOfferById = async (id) => {
    try {
      startLoading();
      const response = await getOfferById({
        offerId: id,
        influencerId: userDtls?.userId,
      }).unwrap();
      if (response?.data) {
        setUserData(response?.data[0]?.completedByInfluencerUsers)
        console.log(offerDtls?.completedByInfluencer?.[0]?.isCompanyApproved);
        handlerFetchOffers(
          setFromCompanyList,
          response.data?.[0]?.createdBy?.[0].userId
        ); // from company
        handlerFetchOffers(setSimilarOfferList, null, userDtls?.userId); //Similar view
        const test = response.data?.[0]?.completedByInfluencer?.filter(
          (ele) =>
            ele?.id ===
            response?.data?.[0]?.completedByInfluencerUsers?.[0]?.userId
        )?.[0]?.postDetails;
        setPostDetails(
          test?.map((ele) => {
            ele.opened = !isCard || false;
            return ele;
          })
        );
        const totalsByType = {
          post: {
            totalLikes: 0,
            totalComments: 0,
            totalShares: 0,
            totalViews: 0,
          },
          story: {
            totalLikes: 0,
            totalComments: 0,
            totalShares: 0,
            totalViews: 0,
          },
          reels: {
            totalLikes: 0,
            totalComments: 0,
            totalShares: 0,
            totalViews: 0,
          },
        };

        // Loop through each post type (post, story, reel, etc.)
        test?.forEach((post) => {
          const { name, details } = post;

          // Sum counts only for the respective type
          details?.forEach((count) => {
            totalsByType[name?.toLowerCase()].totalLikes +=
              count?.like_count || 0;
            totalsByType[name?.toLowerCase()].totalComments +=
              count?.comments_count || 0;
            totalsByType[name?.toLowerCase()].totalShares +=
              count?.share_count || 0;
            totalsByType[name?.toLowerCase()].totalViews +=
              count?.view_count || 0;
          });
        });
        setPostCountObject(totalsByType);

        offersListFormatter(response.data, setOfferDtls, true);
        // setOfferDtls(response.data[0]);
      }
    } catch (err) {
      // setOfferDtls([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle report offer
   */
  useEffect(() => {
    if (offerId) {
      fetchOfferById(offerId);
    }
  }, [offerId]);

  /**
   * To handler fetch offers list
   */
  const handlerFetchOffers = async (setList, businessId, userId) => {
    try {
      startLoading();
      let filter = {};
      filter.status = "active";
      if (businessId) {
        filter.businessId = businessId;
      }
      if (userId) {
        filter.influencerId = userId;
      }
      filter.followerCount = instagramData?.followers_count1 || 0;
      const response = await getOffers({
        id: userDtls?.userId,
        filter,
      }).unwrap();
      if (response?.data) {
        if (businessId) {
          offersListFormatter(
            response?.data?.filter((ele) => ele?._id !== offerId),
            setList
          );
        } else {
          const arr = response?.data?.filter((ele) => ele?._id !== offerId);
          if (arr.length > 3) {
            offersListFormatter(arr.slice(0, 3), setList);
          }
        }
      } else {
        setList([]);
      }
    } catch (err) {
      setList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  const offersListFormatter = (data, setList, isObject) => {
    const formattedData = data?.map((item) => {
      let ele = { ...item };
      if (ele?.isRequestToBusiness) {
        ele.offerStatus = "pending";
      } else if (ele?.isOfferedToInfluencer) {
        ele.offerStatus = "check";
      } else if (ele?.isCompletedByInfluencer) {
        ele.offerStatus = "complete";
      }
      if (ele?.isCancelledByInfluencer) {
        ele.offerStatus = "cancelled";
      } else {
        ele.offerStatus = "accept";
      }
      return ele;
    });
    if (isObject) {
      setList(formattedData[0]);
    } else {
      setList(formattedData);
    }
  };

  /**
   * To check the approve content is enable
   */
  const isApproveContentDisabled = () => {
    const requireCount =
      offerDtls?.requirement?.reduce((acc, item) => acc + item.count, 0) || 0;
    const presentCount =
      offerDtls?.completedByInfluencer?.filter(
        (ele) => ele?.id === offerDtls?.completedByInfluencerUsers?.[0]?.userId
      )?.[0]?.links?.length || 0;
    return requireCount === presentCount ? false : true;
  };

  /**
   * To handle the approve the content
   */
  const handlerApproveContent = async (e) => {
    try {
      startLoading();
      e.stopPropagation();
      e.preventDefault();
      let payload = {
        offerId: offerDtls?._id,
        influencerId: offerDtls?.completedByInfluencerUsers?.[0]?.userId,
        newStatus: "completedByCompany",
        message: notificationCode?.offerCompletedByCompany,
      };
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        fetchOfferById(offerId);
        sendNotification({
          senderId: userDtls?.userId,
          recipientId: offerDtls?.completedByInfluencerUsers?.[0]?.userId,
          message: notificationCode?.offerCompletedByCompany,
          isRead: false,
          type: "remainder",
        });
        toaster("info", "Approved the content successfully");
      } else {
        toaster("error", response?.data?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to approve the content:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  return (
    <Box height={"100vh"} position={"relative"} overflow={"scroll"}>
      {!isCard && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            height: { sm: 148, xs: 92 },
          }}
        >
          <MyButton
            // className="w-ColorBlack"
            type="backButton"
            onClick={() => {
              navigate(-1);
            }}
          />

          <Box
            className={"dateCard"}
            p={1}
            borderRadius={"8px"}
            border={"1px solid #CAC4D0"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gridArea={"span 2 / 1"}
          // bgcolor={"#f4ebf5"}
          // color={tabValue === "offeredToInfluencer" && "#f4ebf5"}
          >
            <Typography
              title={offerDtls?.title}
              // width={"80px"}
              fontWeight="400"
              fontSize={"14px"}
              variant="body1"
              color={"#625B71"}
            >
              {
                formatDate(offerDtls?.dateRequest?.isDataRequestAccepted ?
                  offerDtls?.dateRequest?.date : offerDtls?.availableDates?.from, "ddd DD/M").split(
                    " "
                  )[0]
              }
            </Typography>
            <Typography
              title={offerDtls?.title}
              // width={"80px"}
              fontWeight="700"
              fontSize={"24px"}
              variant="body1"
              color={"#625B71"}
            >
              {
                formatDate(offerDtls?.availableDates?.from, "ddd DD/M").split(
                  " "
                )[1]
              }
            </Typography>
          </Box>
          <Box
            width={"409px"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <CardMedia
              className="c-bookingCardMedia"
              component="img"
              alt="green iguana"
              image={offerDtls?.offerImages?.[0] || defaultProfileImg}
              sx={{ borderRadius: "50%", width: { md: 80, xs: 32 } }}
            />
            <Typography
              display={"flex"}
              className={"textEllipsis mobile-WidthFitContent "}
              width={"fitContent "}
              height={32}
              color={"text.main"}
              fontWeight="400"
              // fontSize={"20px"}
              sx={{ fontSize: { sm: 20, xs: 14 } }}
              variant="body1"
              alignItems={"center"}
              // justifyContent={"center"}
              // borderRadius={20}
              // bgcolor={"#f4f3f6"}
              mx={1}
              textTransform={"capitalize"}
            >
              {offerDtls?.completedByInfluencerUsers?.[0]?.firstName || "test"}
            </Typography>
            <InstagramIcon
              className="hideOnMobile"
              color="#8F3A98"
              // onClick={handlerInstagramLogin}
              sx={{ cursor: "pointer", color: "primary.main", mr: 1 }}
            />

            <Typography
              display={"flex"}
              className={"textEllipsis mobile-WidthFitContent followersCount "}
              minWidth={"52px"}
              // width={"110px"}
              height={32}
              color={"text.main"}
              fontWeight="500"
              fontSize={"12px"}
              variant="body1"
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={20}
              bgcolor={"#f4f3f6"}
              mx={1}
              textTransform={"capitalize"}
            >
              {`${offerDtls?.influencerDetails?.socialLogins?.instagram
                ?.followers_count || 0
                } `}
              <Typography
                fontWeight="500"
                fontSize={"12px"}
                className="hideOnMobile"
                ml={1}
              >
                followers
              </Typography>
            </Typography>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            className="hideOnMobile"
            ml={"auto"}
            borderRadius={20}
            bgcolor={"#F7F2FA"}
            px={1}
          >
            <SvgIcon
              component={OfferIcon}
              sx={{ mr: 1, color: "primary.main" }}
            />
            <Typography
              display={"flex"}
              className={"textEllipsis mobile-WidthFitContent c-offerTitle "}
              // width={"242px"}
              height={32}
              color={"text.main"}
              fontWeight="900"
              fontSize={"12px"}
              variant="body1"
              alignItems={"center"}
              justifyContent={"center"}
              // mx={1}
              // ml={"auto"}
              textTransform={"capitalize"}
            >
              {offerDtls?.title}
            </Typography>
          </Box>
          {
            <Typography
              className="completeOffer hideOnMobile"
              padding={"6px 12px"}
              display={"flex"}
              variant="body1"
              fontSize={14}
              fontWeight={400}
              color="#36A162"
              height={"32px"}
              width={"147px"}
              minWidth={"147px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={20}
              bgcolor={isApproveContentDisabled() ? "#d3d3d3" : "#D3F5E1"}
              sx={{ cursor: "pointer" }}
              role={"button"}
              disabled={isApproveContentDisabled()}
              onClick={handlerApproveContent}
            >
              Approve content
            </Typography>
          }
          <IconButton
            className="hideOnMobile"
            sx={{
              "&:hover": {
                backgroundColor: "#8F3A98",
              },
              borderRadius: "16px",
              width: 56,
              height: 56,
              bgcolor: "#8F3A98",
              boxShadow:
                "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)",
              display: "flex",
              mx: "16px",
            }}
            // sx={{ height: "32px" }}
            color="white"
            onClick={() => {
              // handlerChat(column);
            }}
          >
            <NotesIcon />
          </IconButton>
        </Box>
      )}
      <Box
        overflow={"auto"}
        sx={{
          height: { xs: "calc(100% - 92px)", sm: "calc(100% - 150px)" },
          p: { xs: 1, sm: 0 },
        }}
      // p={1}
      >
        {postDetails?.map((ele, idx) => {
          console.log("ele", ele);

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // minHeight: 80,
                p: { sm: "5px 40px", xs: "8px" },
                mb: 1,
                minHeight: { xs: 24, sm: 92 },
                // boxShadow: {
                //   xs: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                //   sm: "none",
                // },
                borderRadius: "12px",
              }}
            >
              <Stack
                direction={"row"}
                gap={2}
                // alignItems={"center"}
                sx={{
                  display: { sm: "flex", xs: "grid" },
                  mb: { xs: 1, sm: 4 },
                  gridTemplateColumns: "80%  20% ",
                  cursor: "pointer",
                }}
                onClick={() =>
                  !isMobile && handleExpand("opened", !ele?.opened, idx)
                }
              >
                <Stack direction={"column"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <InstagramIcon
                      sx={{
                        cursor: "pointer",
                        width: 24,
                        height: 24,
                        color: "#79747e",
                        mr: 1,
                      }}
                    />
                    <Typography
                      className={"textEllipsis mobile-WidthFitContent "}
                      width={96}
                      height={32}
                      color={"#1D1B20"}
                      fontWeight="700"
                      fontSize={"18px"}
                      variant="body1"
                      borderRadius={20}
                      // bgcolor={"#F7F2FA"}
                      // mx={1}
                      ml={"10px"}
                      pt={"3px"}
                      textTransform={"capitalize"}
                    >
                      {ele?.name === "post" ? "hard post" : ele?.name}
                    </Typography>
                  </Stack>
                </Stack>
                {/* {ele?.details?.map((item) => ( */}
                <Box
                  display={"flex"}
                  gap={2}
                  sx={{ ml: { sm: "auto", xs: "none" } }}
                  gridArea={"2 / 1 / span 2"}
                >
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    sx={{
                      width: { sm: 100, xs: 76 },
                      height: { xs: 32, sm: 20 },
                      justifyContent: { xs: "center", sm: "none" },
                      px: 1,
                      bgcolor: { xs: "#f4ebf5", sm: "#ffffff" },
                      borderRadius: "20px",
                    }}
                  // width={100}
                  // height={20}
                  >
                    <VisibilityIcon className="visibilityIcon" />
                    <Typography
                      className="hideOnMobile"
                      color={"#8F3A98"}
                      fontSize={12}
                      fontWeight={400}
                    >
                      view
                    </Typography>
                    <Typography
                      color={"#8F3A98"}
                      fontSize={12}
                      fontWeight={700}
                    >
                      {postCountObject?.[ele?.name]?.totalViews || 0}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    sx={{
                      width: { sm: 109, xs: 76 },
                      height: { xs: 32, sm: 20 },
                      justifyContent: { xs: "center", sm: "none" },
                      px: 1,
                      bgcolor: { xs: "#f4ebf5", sm: "#ffffff" },
                      borderRadius: "20px",
                    }}
                  >
                    <CommentsIcon sx={{ color: "#8F3A98" }} />
                    <Typography
                      className="hideOnMobile"
                      color={"#8F3A98"}
                      fontSize={12}
                      fontWeight={400}
                    >
                      Comments
                    </Typography>
                    <Typography
                      color={"#8F3A98"}
                      fontSize={12}
                      fontWeight={700}
                    >
                      {postCountObject?.[ele?.name]?.totalComments || 0}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    sx={{
                      width: { sm: 84, xs: 76 },
                      height: { xs: 32, sm: 20 },
                      justifyContent: { xs: "center", sm: "none" },
                      px: 1,
                      bgcolor: { xs: "#f4ebf5", sm: "#ffffff" },
                      borderRadius: "20px",
                    }}
                  >
                    {/* <LikeIcon
                // sx={{ color: "#8F3A98", width: 18, height: 18 }}
                width={18}
                height={18}
                mt={1}
              /> */}
                    <SvgIcon
                      component={LikeIcon}
                      sx={{ mt: 1, color: "#8F3A98" }}
                    />
                    <Typography
                      className="hideOnMobile"
                      color={"#8F3A98"}
                      fontSize={12}
                      fontWeight={400}
                    >
                      Likes
                    </Typography>
                    <Typography
                      color={"#8F3A98"}
                      fontSize={12}
                      fontWeight={700}
                    >
                      {postCountObject?.[ele?.name]?.totalLikes || 0}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    sx={{
                      width: { sm: 88, xs: 76 },
                      height: { xs: 32, sm: 20 },
                      justifyContent: { xs: "center", sm: "none" },
                      px: 1,
                      bgcolor: { xs: "#f4ebf5", sm: "#ffffff" },
                      borderRadius: "20px",
                    }}
                  >
                    <VisibilityIcon className="visibilityIcon" />
                    <Typography
                      className="hideOnMobile"
                      color={"#8F3A98"}
                      fontSize={12}
                      fontWeight={400}
                    >
                      Shared
                    </Typography>
                    <Typography
                      color={"#8F3A98"}
                      fontSize={12}
                      fontWeight={700}
                    >
                      {postCountObject?.[ele?.name]?.totalShares || 0}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    sx={{
                      height: { xs: 32, sm: 20 },
                      justifyContent: { xs: "center", sm: "none" },
                      px: 1,
                      bgcolor: { xs: "#f4ebf5", sm: "#ffffff" },
                      borderRadius: "20px",
                    }}
                  >
                    {ele?.opened ? (
                      <ExpandLessOutlinedIcon
                        onClick={() => {
                          handleExpand("opened", false, idx);
                        }}
                      />
                    ) : (
                      <ExpandMoreOutlinedIcon
                        onClick={() => {
                          handleExpand("opened", true, idx);
                        }}
                      />
                    )}
                  </Box>
                  {/* <Stack direction={"row"} alignItems={"center"} my={2} gap={1}>
            <LinkIcon />
            <Typography
              title={data?.postLink}
              className="textEllipsis"
              color={"#8F3A98"}
              //   height={12}
              fontSize={12}
              fontWeight={700}
              width={"80%"}
            >
              {data?.postLink}
            </Typography>
          </Stack> */}
                </Box>
                {/* // ))} */}
                {!isCard && (
                  <Stack className="hideOnMobile">
                    {ele?.opened ? (
                      <ExpandLessOutlinedIcon
                        onClick={() => {
                          handleExpand("opened", false, idx);
                        }}
                      />
                    ) : (
                      <ExpandMoreOutlinedIcon
                        onClick={() => {
                          handleExpand("opened", true, idx);
                        }}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
              {!ele?.opened && (
                <Box
                  display={"flex"}
                  // flexWrap={"wrap"}
                  gap={1}
                  // sx={{ flexDirection: { xs: "column", sm: "row" } }}
                  overflow={"auto"}
                  mb={1}
                  p={"3px"}
                >
                  {ele?.details?.map((count) => {
                    return (
                      // <Box>
                      <PostDetails data={count} name={ele?.name} userData={userData} />
                      // </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      {!isCard && !offerDtls?.completedByInfluencer?.[0]?.isCompanyApproved && (
        <Box
          className="hideOnWeb"
          sx={{
            position: "absolute",
            zIndex: 999,
            bottom: 10,
            width: "100%",
            display: "flex",
            alignItems: "center",
            p: 2,
          }}
        >
          <IconButton
            className="c-ignoreIcon"
            sx={{ width: 40, height: 40, mx: 1 }}
          >
            <CrossIcon />
          </IconButton>
          <Button
            onClick={(e) => handlerApproveContent(e)}
            sx={{
              textTransform: "none",
              borderRadius: "16px",
              fontWeight: 400,
              fontSize: "14px",
              height: "52px",
              minWidth: "155px",
              maxWidth: "218px",
              backgroundColor: "#8F3A98",
              boxShadow: 3,
              ml: "auto",
              color: "white.main",
              "&.Mui-disabled": {
                backgroundColor: "gray.main", // Change to your desired color
              },
            }}
          >
            Approve
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default BookingDetails;
