import {
  Box,
  CardMedia,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Profile from "../../components/profile/Profile";
import { setClearUserDtls } from "../../store/Slicers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { MailIcon, SettingsIcon } from "../../utils/materialIcon";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import defaultProfileImg from "../../assets/image/defaultProfileImg.png";
import axios from "axios";
import { useEffect, useState } from "react";

const Account = () => {
  const navigate = useNavigate(); //navigate to the path of the sidebar
  const location = useLocation(); //for highlight the path
  const dispatch = useDispatch(); //here used to dispatch the notification unread message and logout
  const { userDtls } = useSelector((state) => state?.auth);
  /**
   * logout the user
   * @returns empty
   * */
  const handleLogout = () => {
    dispatch(setClearUserDtls());
  };
  /**
   * navigate to the path
   * @returns path
   * */
  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <Box p={2} height={"100%"} display={"flex"} flexDirection={"column"}>
      {/* <Profile /> */}
      <Box
        height={"84px"}
        // width={"312px"}
        display={"flex"}
        alignContent={"center"}
        alignItems={"center"}
        gap={1}
        sx={{
          boxShadow:
            "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
          borderRadius: "16px",
          bgcolor: "#f4ebf5",
          mb: 2,
        }}
        onClick={() => navigate("/profile")}
      >
        <CardMedia
          className="test"
          component="img"
          alt="green iguana"
          height={"60px"}
          width={"60px"}
          image={userDtls?.profileImage || defaultProfileImg}
          sx={{
            width: "60px",
            borderRadius: "50%",
            minWidth: "60px",
            ml: 2,
          }}
        />
        <Typography
          display={"flex"}
          className={"textEllipsis mobile-WidthFitContent"}
          // width={"140px"}
          height={20}
          color={"#4A4459"}
          fontWeight="600"
          fontSize={"14px"}
          variant="body1"
          alignItems={"center"}
          justifyContent={"center"}
        >
          {`${userDtls?.firstName} ${userDtls?.lastName}`}
        </Typography>
      </Box>
      <List
        className="component-list"
        sx={{ color: "black.main", fontSize: "14px" }}
      >
        {[
          {
            text: "Setting",
            icon: <SettingsIcon />,
            path: "/settings",
          },
          {
            text: "Help & Support",
            icon: <HelpCenterOutlinedIcon />,
            path: "/help",
          },
          {
            text: "Terms & conditions",
            icon: <DescriptionOutlinedIcon />,
            path: "/Terms",
          },
        ]
          .filter((ele) => ele)
          .map((item) => (
            <ListItem
              key={item.text}
              disablePadding
              sx={{ display: "block", color: "white", height: "56px" }}
            >
              <ListItemButton
                className={`list-item-button ${location.pathname.includes(item?.path)
                  ? "navActive active"
                  : "navInActive"
                  }`}
                sx={{
                  paddingLeft: {
                    xs: "0px", ms: "16px"
                  }
                }}
                onClick={() => handleNavigation(item.path)}
              >
                <ListItemIcon
                  className="itemicon"
                  sx={{
                    color: location.pathname.includes(item?.path)
                      ? "primary.main"
                      : "'",

                    minWidth: 0,
                    // mr: open ? 1 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                  {/* {messageCount > 0 && (
                    <Badge
                      sx={{ marginLeft: 1 }}
                      badgeContent={item.badge}
                      color="warning"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    ></Badge>
                  )} */}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                //   sx={{ opacity: open ? 1 : 0, color: "#1D1B20" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>

      {/* For sidebar bottom profile */}
      <Box width={312} height={40} display={"flex"} gap={2} px={2} mt={"auto"}
        paddingLeft={"8px"}
      >
        <MailIcon sx={{ mr: 1 }} />
        <Typography
          display={"flex"}
          className={"textEllipsis mobile-WidthFitContent"}
          // width={"140px"}
          height={20}
          color={"#8F3A98"}
          fontWeight="500"
          fontSize={"16px"}
          variant="body1"
          alignItems={"center"}
          justifyContent={"center"}
        >
          Contact support
        </Typography>
      </Box>
      <Box
        width={"336px"}
        height={"56px"}
        display={"flex"}
        alignItems={"center"}
        gap={1}
        px={2}
        onClick={handleLogout}
        sx={{ cursor: "pointer" }}
        paddingLeft={"8px"}
      >
        <LogoutOutlinedIcon color="#79747e" />
        <Typography
          display={"flex"}
          className={"textEllipsis mobile-WidthFitContent"}
          // width={"140px"}
          height={20}
          color={"#4A4459"}
          fontWeight="400"
          fontSize={"15px"}
          variant="body1"
          alignItems={"center"}
          justifyContent={"center"}
        >
          Logout
        </Typography>
      </Box>
    </Box >
  );
};
export default Account;
