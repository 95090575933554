import {
  Box,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import { useEffect, useState } from "react";
import { ResetTvRounded } from "@mui/icons-material";
import MyButton from "../../MyButton";
import { useSelector } from "react-redux";
import { LibraryIcon, MobileShareIcon } from "../../../utils/materialIcon";
import CheckIcon from "@mui/icons-material/Check";
import { retry } from "@reduxjs/toolkit/query";
import { getShortCodeFromUrl } from "../../../utils/common";
import {
  useGetInstagramMediaByIdMutation,
  useGetInstagramMediaListMutation,
} from "../../../services/apiService/socialMediaApiService";
import toaster from "../../Toaster/toaster";
const CompanyOffersRequest = ({
  data,
  handlerUpdateBookingStatus,
  selectedInfluencer,
}) => {
  const [getMediaList] = useGetInstagramMediaListMutation();
  const [getMediaById] = useGetInstagramMediaByIdMutation();
  const { userDtls, instagramData } = useSelector((state) => state?.auth);
  const [showDetails, setShowDetails] = useState({});
  const [requirement, setRequirement] = useState([]);
  useEffect(() => {
    if (selectedInfluencer?.userId || userDtls.type === "influencer") {
      const link = data?.completedByInfluencer?.find((ele) =>
        ele?.id === selectedInfluencer?.userId
          ? selectedInfluencer?.userId
          : userDtls?.userId
      );
      const tempData = JSON.parse(JSON.stringify(data || {}));
      if (link?.links?.length > 0) {
        const requirementList =
          tempData?.requirement.map((ele, index) => {
            if (ele?.count > 0) {
              ele.link = link?.links?.slice(index, index + ele?.count);
            }
            return ele;
          }) || [];
        setRequirement(requirementList);
      } else {
        setRequirement(tempData?.requirement);
      }
    } else {
      setRequirement(data?.requirement);
    }
  }, []);

  const handlerUpdateStatus = async (e) => {
    try {
      const linkList = requirement?.reduce((links, ele) => {
        return links.concat(...(ele?.link || []));
      }, []);
      // const response = await getMediaList(shortCode);
      const mediaList = await getMediaList({
        id: instagramData?.id,
        access_token: userDtls.socialLogins.instagram.access_token,
        fields: "id,permalink",
      });
      if (!mediaList?.data?.data) {
        toaster("error", "fetch instagram api failed");
      }
      const postDetails = [];
      for (const details of requirement) {
        if (details?.count) {
          const data = { name: details?.name, details: [] };
          for (const link of details?.link) {
            const originalUrl = link.split(",")[0];
            const instagramLink = mediaList?.data?.data.find(
              (ele) =>
                getShortCodeFromUrl(ele?.permalink) ===
                getShortCodeFromUrl(originalUrl)
            );
            if (instagramLink?.id) {
              const mediaDetails = await getMediaById({
                id: instagramLink?.id,
                access_token: userDtls.socialLogins.instagram.access_token,
                fields:
                  "id,permalink,media_url,username,like_count,comments_count,timestamp",
              });
              if (mediaDetails?.data?.id) {
                data.details.push({
                  name: originalUrl[1],
                  opened: false,
                  id: mediaDetails?.data?.id,
                  permalink: mediaDetails?.data?.permalink,
                  like_count: mediaDetails?.data?.like_count,
                  comments_count: mediaDetails?.data?.comments_count,
                  timestamp: mediaDetails?.data?.timestamp,
                  media_url: mediaDetails?.data?.media_url,
                });
              }
            }
          }
          postDetails?.push(data);
        }
      }

      handlerUpdateBookingStatus(e, data, linkList, postDetails);
    } catch (err) {
      console.log(err);
    }
  };
  /**
   * To handle onchange
   */
  const handlerOnchange = (value, key, id) => {
    const dup = [...requirement];
    let index = dup
      .find((ele) => ele.name === key)
      ?.link?.findIndex((ele) => ele?.includes(key + id));
    if (index === undefined) {
      index = -1;
    }
    const temp = dup?.map((ele) => {
      if (ele?.name === key) {
        if (index === -1) {
          ele.link = ele.link || [];
          ele.link.push(`${value},${key + id}`);
        } else {
          if (value) {
            ele.link[index] = `${value},${key + id}`;
          } else {
            ele.link.splice(index, 1);
          }
        }
      }
      return ele;
    });
    setRequirement(temp);
  };
  /**
   * To check offer is completed or not
   */
  const isOfferCompleted = () => {
    if (
      data?.completedByInfluencer?.find((ele) => ele?.id === userDtls?.userId)
    ) {
      return true;
    }
    return false;
  };

  /**
   * To show the link details
   */
  const handlerShowDetails = (key) => {
    const tempData = JSON.parse(JSON.stringify(showDetails));
    if (tempData[key]) {
      delete tempData[key];
    } else {
      tempData[key] = true;
    }
    setShowDetails(tempData);
  };
  return (
    <Box width={"100%"}>
      <Typography marginBottom={3} color={"primary"}>
        <Stack
          direction={"row"}
          gap={2}
          mt={2}
          fontSize={18}
          fontWeight={700}
          color={"#1D1B20"}
        >
          <InstagramIcon color="gray" />
          Instagram
        </Stack>
      </Typography>
      {requirement?.map((ele) => {
        if (!ele.description?.length) {
          return null;
        }
        return (
          <>
            <div role="button" className="requestBtn">
              <Stack
                onClick={() => handlerShowDetails(ele?.name)}
                direction={"row"}
                gap={2}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <Stack
                  direction={"row"}
                  gap={2}
                  sx={{
                    color:
                      ele?.count === ele?.link?.length
                        ? "#6FCF97"
                        : "gray.main",
                  }}
                >
                  {ele?.name === "story" && <MobileShareIcon />}
                  {ele?.name === "post" && <LibraryIcon />}

                  <span>{ele?.name}</span>
                </Stack>
                <CheckIcon
                  sx={{
                    color:
                      ele?.count === ele?.link?.length
                        ? "#6FCF97"
                        : "gray.main",
                  }}
                />
              </Stack>
              {showDetails[ele?.name] && (
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                  p={1}
                >
                  {ele?.description?.map((text, idx) => {
                    return (
                      <>
                        <Typography fontSize="subtitle2">{`${ele?.name} ${
                          idx + 1
                        } Description`}</Typography>
                        <Typography fontSize="body2">{text}</Typography>
                        {(isOfferCompleted() && false) ||
                        (ele?.link &&
                          ele?.link[idx]?.split(",")[0] &&
                          false) ? (
                          <>
                            <Link
                              target="_blank"
                              onClick={(e) => {
                                e.preventDefault();
                                const url =
                                  ele?.link && ele?.link[idx]?.split(",")[0];
                                if (url) {
                                  window.location.href = url;
                                }
                              }}
                              className="border"
                              href={
                                `"${
                                  ele?.link && ele?.link[idx]?.split(",")[0]
                                }"` || "#"
                              }
                              underline="none"
                            >
                              {`Link : ${
                                (ele?.link && ele?.link[idx]?.split(",")[0]) ||
                                "test"
                              }`}
                            </Link>
                          </>
                        ) : (
                          <>
                            {(data?.isOfferedToInfluencer ||
                              data?.isCompletedByInfluencer) && (
                              <TextField
                                // disabled={
                                //   isOfferCompleted() ||
                                //   userDtls?.type === "company" ||
                                //   !data?.isOfferedToInfluencer
                                // }
                                id="outlined-multiline-static"
                                // multiline
                                rows={1}
                                variant="outlined"
                                fullWidth
                                placeholder=" Add link..."
                                defaultValue={
                                  (ele?.link &&
                                    ele?.link[idx]?.split(",")[0]) ||
                                  null
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  handlerOnchange(
                                    e?.target?.value,
                                    ele?.name,
                                    idx + 1
                                  );
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
                </Box>
              )}
            </div>
          </>
        );
      })}
      {
        // handlerUpdateBookingStatus &&

        (data?.isOfferedToInfluencer || data?.isCompletedByInfluencer) && (
          <MyButton
            sx={{ borderRadius: 3 }}
            disabled={
              !data?.isOfferedToInfluencer
              //  ||
              // isOfferCompleted()
            }
            onClick={(e) => handlerUpdateStatus(e)}
          >
            {"Save"}
          </MyButton>
        )
      }
    </Box>
  );
};
export default CompanyOffersRequest;
