import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import "./InfluencerOffers.css";
// import { InfoIcon } from "../../../utils/materialIcon";

import { useNavigate } from "react-router-dom";

const InfluencerOffersList = ({
  width,
  data,
  module,
  children,
  className,
  ctnClassName,
  height,
  bookingFilter,
}) => {
  const navigate = useNavigate();

  const truncateTitle = (title, maxLength = 20) => {
    if (!title) return '';
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    return capitalizedTitle.length > maxLength ? capitalizedTitle.slice(0, maxLength) + '...' : capitalizedTitle;
  };

  return (
    <>
      <Card
        className="card-ctn"
        sx={{
          padding: "6px",
          height: height || 96,
          boxShadow:
            "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
          width: width || "99%",
          borderRadius: "12px",
          margin: "2px",
          overflow: "unset",
          cursor: "pointer",
        }}
      >
        <Box
          onClick={() => navigate(`/offers/${data?._id}`)}
          className={ctnClassName}
          variant="contained"
          component="section"
          display="flex"
          alignItems="center"
          gap={"8px"}
          sx={{
            borderRadius: "2px",
            width: "100%",
            bgcolor: "white.main",
            position: "relative",
          }}
        >
          <CardMedia
            className="bookingCardMedia"
            component="img"
            alt="green iguana"
            height={"80px"}
            width={"80px"}
            image={data?.offerImages?.[0]}
            sx={{ width: "80px", borderRadius: "6px", minWidth: "80px" }}
          />

          <Typography
            title={data?.title}
            className={` textEllipsis offerTitle ${className}`}
            sx={{
              ml: { xs: "8px", md: "16px" },
              pb: {
                xs: "6px",
                md: "0px",
              },
            }}
            width={"250px"}
            maxWidth={"500px"}
            color={"text.main"}
            fontWeight="400"
            fontSize={module === "booking" ? "14px" : "20px"}
            variant="body1"
            display={"flex"}
            gap={"10px"}
            alignItems={"center"}
            textOverflow={"ellipsis"}
          >
            {truncateTitle(data?.title)}
            {data.spots &&
              data.spots.availableSpots === 5 &&
              data.spots.booked === 5 && (
                <Tooltip
                  title={
                    <Typography
                      sx={{
                        color:
                          "var(--M3-sys-light-inverse-on-surface, var(--Schemes-Inverse-On-Surface, #F5EFF7))",
                        fontSize: "var(--Body-Small-Size, 12px)",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "var(--Body-Small-Line-Height, 16px)",
                        letterSpacing: "var(--Body-Small-Tracking, 0.4px)",
                      }}
                    >
                      This offer is no longer visible for new influencers to
                      apply for. This is because it's either past its due date
                      or the total number of slots are filled. If you wish it to
                      be visible to more new influencers, please either change
                      the end date to a future one, or increase the number of
                      open slots.
                    </Typography>
                  }
                  arrow
                >
                  {/* <InfoIcon /> */}
                </Tooltip>
              )}
          </Typography>

          {children}
        </Box>
      </Card >
    </>
  );
};

export default InfluencerOffersList;
