import { Box, Button, Stack, Typography } from "@mui/material";
import MyButton from "../MyButton";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useEffect, useRef, useState } from "react";
import CompanyOffersRequest from "../Company/Offers/CompanyOffersRequest";
import toaster from "../Toaster/toaster";
import {
  useGetOfferByIdMutation,
  useSaveOfferMutation,
} from "../../services/apiService/userApiService";
import { useSelector } from "react-redux";
import useLoading from "../../hooks/useLoading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation, HashNavigation } from "swiper/modules";
import "swiper/css"; // Basic Swiper styles
import "swiper/css/virtual";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useMediaQuery } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocationAutoComplete from "../LocationAutoComplete/LocationAutoComplete";
import { formatDate } from "../../utils/common";
import { CalenderIcon } from "../../utils/materialIcon";
import { useNavigate } from "react-router-dom";

// import "./CompanyOffers.css";

const OfferDetails = (props) => {
  const { offerId, data, isInfluencerView, handlerUpdateBookingStatus } = props;
  const { userDtls } = useSelector((state) => state?.auth);
  const [saveOffer] = useSaveOfferMutation();
  const [getOfferById] = useGetOfferByIdMutation();
  const { startLoading, stopLoading } = useLoading();
  const [offerDtls, setOfferDtls] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [tabName, setTabName] = useState("info");
  const [index, setIndex] = useState(0);
  const navigate = useNavigate(); // To navigate
  // if (!offerDtls) {
  //   // sample data
  //   offerDtls = {
  //     categories: ["Activities", "Nightlife", "self care", "Entertainment"],
  //     description:
  //       " Experience the ultimate relaxation with our exclusive influencer bundle! Enjoy a luxurious 2-night stay in our elegant hotel, complete with access to our serene spa and rejuvenating massage services. Delight in gourmet dining with two delicious meals per person, perfect for you and your guest. Embrace this unique opportunity to unwind and indulge, while sharing your memorable moments with your followers.",
  //     imgList: [
  //       "https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
  //       "https://s3-alpha-sig.figma.com/img/d4ea/c504/9ca670b24ead9225b4fa0092e8bfdea6?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GgHW9O4u8ZwghFDuB2H6SmhV8QMm2IbtdIAH3tGCiE-E-4blXNBSSS2VjeTFIN8sXnqIopGM0mFFCwZiw44Yr2lvIaxUBsmO~23VloT66dp1Z2a5vtdGz9eckb9fggHoxlEQeylAJd8a1yUfMvRtQ-3kU-kQVVoaz0UwORD8GckjrPEFeEXx4~3hCQrcA2PhC6fjuc3dEds1zBZdW3xhaCQTb35qToCfp16BO0J7I2RPE~bJvI1ylfB4MmGe5ZfsB0Ada4BMrMIf13OsAaQg8TZAvEoe~IfFn4V5J8hxClrrAagWiK21ZyH8YETutnzUJ6uyFDRa2SiaVwfrTMUMcA__",
  //       "https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  //       "https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  //       "https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  //     ],
  //   };
  // }
  const containerRef = useRef(null);
  useEffect(() => {
    const handleWheel = (e) => {
      if (e.shiftKey) {
        e.preventDefault();
        const scrollAmount = e.deltaY > 0 ? 1 : -1;
        setIndex(
          (prevIndex) =>
            (prevIndex + scrollAmount + offerDtls?.offerImages?.length) %
            offerDtls?.offerImages?.length
        );
      }
    };

    const container = containerRef.current;

    if (container) {
      container.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);
  /**
   * To fetch offer by id
   */
  const fetchOfferById = async (id) => {
    try {
      startLoading();
      const response = await getOfferById({
        offerId: id,
        influencerId: userDtls?.userId,
      }).unwrap();
      if (response?.data) {
        setOfferDtls(response.data[0]);
      } else {
        setOfferDtls([]);
      }
    } catch (err) {
      // setIsCardSelected(false);
      setOfferDtls([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle the save offer function
   * @param {event}
   */
  const handlerSaveOffer = async (e) => {
    try {
      e?.stopPropagation();
      e?.preventDefault();
      const payload = {
        offer_id: offerDtls?._id,
        // newStatus: "offeredToInfluencer",
        user_id: userDtls?.userId,
      };
      const response = await saveOffer(payload).unwrap();
      if (response?.success) {
        fetchOfferById(offerDtls?._id);
        toaster("info", "Offer successfully saved");
      } else {
        toaster("info", "Offer failed to saved");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to save offer:", err);
        toaster("error", "Something went wrong");
      }
    }
  };
  /**
   * To handle the tab selection
   * @param (tabName)
   * @returns null
   */
  const handleFilter = (code) => {
    setTabName(code);
  };

  /**
   * Image List view
   * @returns jsx elements
   */

  const getSliderPreview = (value) => {
    if (value < 2) {
      return 2;
    } else if (value > 4) {
      return 4;
    } else {
      return value || 2;
    }
  };

  const isMobile = useMediaQuery("(max-width:768px)");
  const slidesPerView = isMobile
    ? 1
    : getSliderPreview(offerDtls?.offerImages?.length);

  const ImageListView = () => {
    return (
      <Box
        width={"100%"}
        sx={{
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
        }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        className="img-view-ctn"
        position={"relative"}
        height={isMobile ? 339 : 417}
      >
        <Swiper
          className="offerScrollCtn"
          modules={[Virtual, Navigation, HashNavigation]}
          spaceBetween={4}
          navigation={true}
          slidesPerView={slidesPerView}
          virtual
          hashNavigation={true}
        >
          {offerDtls?.offerImages?.map((ele, index) => (
            <SwiperSlide key={`offer-image-${index}`} virtualIndex={index}>
              <img
                src={typeof ele === "string" ? ele : URL.createObjectURL(ele)}
                alt={`Offer-${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "fill",
                  // borderRadius: "15px",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <CarouselView
            imageList={offerDtls?.offerImages}
            className="c-offers-Slider"
          ></CarouselView> */}
        {/* <CarouselView
          imageList={offerDtls?.offerImages}
          className="c-offers-Slider"
        ></CarouselView> */}
      </Box>
    );
  };
  const handlerCompanyProfile = (e, data) => {
    e?.preventDefault();
    e?.stopPropagation();
    navigate(`/profile/${data?.createdBy?.[0]?.userId}`);
  };
  useEffect(() => {
    if (offerId) {
      fetchOfferById(offerId);
    } else {
      setOfferDtls(data);
    }
  }, [offerId, data]);
  return (
    <Box
    // margin={1}
    // sx={{ overflow: "hidden auto" }}
    //   className="c-offers-content"
    >
      <Box
        position={"relative"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        height={"417px"}
        mb={2}
      >
        {showMap ? (
          <LocationAutoComplete
            lat={offerDtls?.location?.coordinates?.latitude}
            lng={offerDtls?.location?.coordinates?.longitude}
            showMapOnly={true}
          />
        ) : (
          <>
            <ImageListView />
          </>
        )}
        <Box
          className="hideOnWeb"
          height={66}
          width={"100%"}
          gap={2}
          mt={1}
          mb={2}
        >
          {/* <Box> */}
          <Typography
            title={offerDtls?.title}
            className={` textEllipsis offerTitle `}
            width={"250px"}
            maxWidth={"500px"}
            color={"text.main"}
            fontWeight="600"
            fontSize={"16px"}
            variant="body1"
            ml={2}
            textTransform={"capitalize"}
          >
            {offerDtls?.title}
          </Typography>
          {/* </Box> */}
          <Box display={"flex"} mb={1} p={1} gap={2}>
            <Typography
              display={"flex"}
              className={"textEllipsis mobile-WidthFitContent"}
              width={"130px"}
              height={32}
              color={"text.main"}
              fontWeight="500"
              fontSize={"12px"}
              variant="body1"
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={20}
              bgcolor={"#f4f3f6"}

              onClick={(e) => handlerCompanyProfile(e, offerDtls)}
            >
              {` by ${(offerDtls?.createdBy?.length &&
                offerDtls?.createdBy[0]?.username) ||
                offerDtls?.companyDetails?.username ||
                "Company "
                }`}
            </Typography>
            <Typography
              // className="hideOnMobile"
              display={"flex"}
              variant="body1"
              fontSize={12}
              fontWeight={700}
              color="primary"
              height={"32px"}
              width={"99px"}
              alignItems={"center"}
              justifyContent={"space-between"}
              ml={"auto"}
            // border={1}
            // borderColor={"#CAC4D0"}
            // borderRadius={20}
            >
              <CalenderIcon />
              {`${formatDate(
                offerDtls?.availableDates?.from,
                "DD/MM"
              )}-${formatDate(offerDtls?.availableDates?.to, "DD/MM")}`}
            </Typography>
            <Typography
              padding={"6px 12px"}
              display={"flex"}
              variant="body1"
              fontSize={14}
              fontWeight={500}
              color="text.secondary"
              height={"32px"}
              maxWidth={"97px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={20}
              bgcolor={
                offerDtls?.spots?.booked
                  ? "#feedad"
                  : "rgba(176, 220, 255, 0.50)"
              }
            >
              <span className="bold">
                {`${offerDtls?.spots?.booked}/${offerDtls?.spots?.availableSpots} `}
              </span>

              <span style={{ marginLeft: "2px" }}>booked</span>
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => setShowMap(!showMap)}
          display={"flex"}
          // width={187}
          maxWidth={"70%"}
          height={44}
          boxShadow={3}
          borderRadius={"54px"}
          // position={"absolute"}
          bottom={"20px"}
          bgcolor={"white.main"}
          zIndex={999}
          className="mapCtn"
        >
          <MyButton
            color="secondary"
            height={"32px"}
            sx={{
              color: "primary2.main",
              borderRadius: "54px",
              margin: "6px 6px 6px 12px",
              width: "fitContent",
            }}
            startIcon={
              <LocationOnOutlinedIcon sx={{ color: "primary2.main" }} />
            }
          >
            {" Map"}
          </MyButton>
          <Button
            title={offerDtls?.location?.locationName}
            sx={{
              textTransform: "capitalize",
              textWrap: "nowrap",
              width: "auto",
              height: "32px",
              marginTop: "6px",
              padding: "6px 2px",
            }}
            className="textEllipsis"
          >
            <Typography
              className="textEllipsis"
              width={"auto"}
              fontSize={"12px"}
              color={"gray.main"}
              fontWeight=" 500"
              margin={"6px 10px 0  0"}
            >
              {showMap
                ? offerDtls?.location?.locationName
                : offerDtls?.location?.locationName.split(",").at(-2)}
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* <Stack direction={"row"} mt={1} gap={1} alignItems={"center"}> */}

      {/* {userDtls?.type === "influencer" && (
          <IconButton
            sx={{ border: "none", height: "32px" }}
            color="primary"
            onClick={(e) => handlerSaveOffer(e)}
          >
            {offerDtls?.isOfferSaved ? <TurnedInIcon /> : <TurnedInNotIcon />}
          </IconButton>
        )} */}
      {/* </Stack> */}
      {isInfluencerView ? (
        <>
          {/* <TabContext
            value={selectedTab}
            sx={{ padding: "5px", width: "100%" }}
          > */}
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */}
          {/* <TabList
                onChange={handleOffersCardTab}
                aria-label="lab API tabs example"
                sx={{ textTransform: "capitalize", justifyContent: "center" }}
              >
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  // icon={<LocalOfferOutlinedIcon />}
                  label="Info"
                  value="content"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  // icon={<ListIcon />}
                  label="Brief"
                  value="request"
                />
              </TabList> */}
          <Stack
            direction={"row"}
            sx={{ marginRight: "auto" }}
            justifyContent={"center"}
            gap={2}
            mb={1}
          >
            <MyButton
              className={`filterBtn btn ${tabName === "info" ? "filterActiveBtn" : "inactive-btn"
                }`}
              onClick={() => handleFilter("info")}
              color={"black"}
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                textTransform: "capitalize",
                width: 121,
              }}
              variant="text"
            >
              Info
            </MyButton>
            <MyButton
              onClick={() => handleFilter("brief")}
              className={`filterBtn btn ${tabName === "brief" ? "filterActiveBtn" : "inactive-btn"
                }`}
              color={"black"}
              sx={{
                minWidth: "50px",
                fontSize: "16px",
                fontWeight: 700,
                width: 121,
              }}
              variant="text"
              font
            >
              Brief
            </MyButton>
          </Stack>
          {/* </Box> */}
          <Box
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
            mb={2}
            p={2}
          >
            {tabName === "info" ? (
              <Box className="offerViewDescription">
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={"#49454F"}
                  sx={{
                    "&::first-line": {
                      textTransform: "capitalize",
                    },
                  }}
                >
                  {offerDtls?.description}
                </Typography>
              </Box>
            ) : (
              <CompanyOffersRequest
                data={offerDtls}
                handlerUpdateBookingStatus={handlerUpdateBookingStatus}
              />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Typography mt={1} fontWeight={700} sx={{ fontSize: "1.2rem" }}>
              {"Description"}
            </Typography>
            <Typography
              marginLeft={3}
              color={"#49454F"}
              sx={{
                "&::first-line": {
                  textTransform: "capitalize",
                },
              }}
            >
              {offerDtls?.description}
            </Typography>
            <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
              {offerDtls?.categories?.map((ele) => {
                return (
                  <MyButton
                    color="secondary"
                    sx={{
                      height: "32px",
                      color: "primary",
                      marginTop: 2,
                      padding: 1,
                      borderRadius: 7,
                      fontWeight: 700,
                    }}
                    startIcon={<CheckCircleRoundedIcon />}
                  >
                    {ele?.value}
                  </MyButton>
                );
              })}
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};
export default OfferDetails;
