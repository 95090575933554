import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Divider,
  Stack,
  Paper,
  Switch,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  IconButton,
  SvgIcon,
  useMediaQuery,
} from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import CheckIcon from "@mui/icons-material/Check";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MyButton from "../../MyButton";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import OfferDetails from "../../OfferDetails/OfferDetails";
import InfluencerOffersList from "./InfluencerOffersList";
import SpeedIcon from "@mui/icons-material/Speed";
import { useSelector } from "react-redux";
import { offerStatusCode, reportOfferReason } from "../../../utils/constants";
import { formatDate } from "../../../utils/common";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import toaster from "../../Toaster/toaster";
import {
  useGetOfferByIdMutation,
  useGetOffersMutation,
  useReportOfferMutation,
  useSaveOfferMutation,
} from "../../../services/apiService/userApiService";
import useLoading from "../../../hooks/useLoading";
import { useNavigate, useParams } from "react-router-dom";
import { notificationCode } from "../../../utils/constants";
import { useUpdateBookingStatusMutation } from "../../../services/apiService/userApiService";
import useNotification from "../../../hooks/usePushNotification";
import PhotoView from "./PhotoView";
import materialIcons, {
  BookMarkIcon,
  bookMarkIconWhite,
  PriorityHighIcon,
  SavedBookMarkIcon,
} from "../../../utils/materialIcon";
import completedIcon from "../../../assets/Icons/completedIcon.png";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import "../../../style/CommonStyleNew.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Navigation, HashNavigation } from "swiper/modules";
import "swiper/css"; // Basic Swiper styles
import "swiper/css/virtual";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const InfluencerOfferView = ({ offerIdByModal, handleCloseOfferDetails }) => {
  let { offerId } = useParams(); // To get the offer id from URL
  if (offerIdByModal) {
    offerId = offerIdByModal;
  }
  const navigate = useNavigate(); // To navigate
  const isMobile = useMediaQuery("(max-width:657px)");
  const { userType, userDtls, instagramData } = useSelector(
    (state) => state?.auth
  ); //To get user details from store
  const array = [1, 2, 3, 4, 5, 6]; // for testing
  const [reportOffer] = useReportOfferMutation(); // API to report the offer
  const [getOffers] = useGetOffersMutation();
  const [getOfferById] = useGetOfferByIdMutation(); // API to fetch the offer by user and offer id
  const [updateBookingStatus] = useUpdateBookingStatusMutation(); // API to update the booking status
  const [saveOffer] = useSaveOfferMutation();
  const { sendNotification } = useNotification(); //To sent notification
  const { startLoading, stopLoading } = useLoading(); // To loader
  const [offerDtls, setOfferDtls] = useState({}); // Offer details
  const [isReportModalOpen, setIsReportModalOpen] = useState(false); // Report modal to open/close
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false); // cancel modal to open/close
  const [fromCompanyList, setFromCompanyList] = useState([]);
  const [similarOfferList, setSimilarOfferList] = useState([]);
  const [reportDtls, setReportDtls] = useState({
    reason: [],
    explanation: "",
    status: "Pending",
  }); // To get the report details

  console.log("offerDtls : ", offerDtls);
  /**
   * To handler fetch offers list
   */
  const handlerFetchOffers = async (setList, businessId, userId) => {
    try {
      startLoading();
      let filter = {};
      // if (noFilter) {
      filter.status = "active";
      // } else {
      //   filter = { ...filterDtls };
      //   if (dateList?.fromDate?.start || dateList?.fromDate?.start) {
      //     filter.fromDate = dateList?.fromDate;
      //   }
      //   if (dateList?.toDate?.start || dateList?.toDate?.start) {
      //     filter.toDate = dateList?.toDate;
      //   }
      //   filter.isCompany = userDtls.type === "company" ? true : false;
      //   filter.categories = filterDtls?.categories?.map((ele) => ele?.value);
      //   if (location?.locationName?.length > 0) {
      //     filter.location = filterLocation;
      //   }
      //   if (isCompany) {
      //     filter.influencerId = filterDtls?.name?.userId;
      //   } else {
      // filter.businessId = filterDtls?.name?.userId;
      //   }
      //   filter = Object.fromEntries(
      //     Object.entries(filter).filter(
      //       ([key, value]) =>
      //         value !== null &&
      //         value !== undefined &&
      //         value !== "" &&
      //         !(Array.isArray(value) && value.length === 0)
      //     )
      //   );
      // }
      // if (query?.length > 0) filter.search = query;
      if (businessId) {
        filter.businessId = businessId;
      }
      if (userId) {
        filter.influencerId = userId;
      }
      // if (userDtls?.type === "influencer") {
      //   filter.influencerId = userDtls?.userId;
      // }
      filter.followerCount = instagramData?.followers_count1 || 0;
      const response = await getOffers({
        id: userDtls?.userId,
        filter,
      }).unwrap();
      if (response?.data) {
        if (businessId) {
          offersListFormatter(
            response?.data?.filter((ele) => ele?._id !== offerId),
            setList
          );
        } else {
          const arr = response?.data?.filter((ele) => ele?._id !== offerId);
          if (arr.length > 3) {
            offersListFormatter(arr.slice(0, 3), setList);
          }
        }
      } else {
        setList([]);
      }
    } catch (err) {
      setList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  const offersListFormatter = (data, setList, isObject) => {
    const formattedData = data?.map((item) => {
      let ele = { ...item };
      if (ele?.isRequestToBusiness) {
        ele.offerStatus = "pending";
      } else if (ele?.isOfferedToInfluencer) {
        ele.offerStatus = "check";
      } else if (ele?.isCompletedByInfluencer) {
        ele.offerStatus = "complete";
      } else if (ele?.isCancelledByInfluencer) {
        ele.offerStatus = "cancelled";
      } else {
        ele.offerStatus = "accept";
      }
      return ele;
    });
    console.log("setList", formattedData);
    if (isObject) {
      setList(formattedData[0]);
    } else {
      setList(formattedData);
    }
  };
  /**
   * To update booking status
   */
  const handlerUpdateBookingStatus = async (e, data, links, postDetails) => {
    try {
      startLoading();
      e?.stopPropagation();
      e?.preventDefault();
      let payload = {
        offerId: data?._id,
        influencerId: userDtls?.userId,
      };
      const notificationPayload = { isRead: false, type: "remainder" };
      if (data?.offerStatus === "accept") {
        payload.newStatus = "requestToBusiness";
        notificationPayload.message = notificationCode?.offerRequested;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data?.createdBy[0]?.userId || data?._id;
      } else if (data?.offerStatus === "approved") {
        payload.newStatus = "offeredToInfluencer";
        notificationPayload.message = notificationCode?.offerAccepted;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data?.createdBy[0]?.userId || data?._id;
      } else if (data?.offerStatus === "cancelled") {
        payload.newStatus = "cancelledByInfluencer";
        notificationPayload.message = notificationCode?.offerCancelled;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId = offerDtls?.createdBy[0]?.userId;
      } else {
        payload.newStatus = "completedByInfluencer";
        notificationPayload.message = notificationCode?.offerCompleted;
        notificationPayload.senderId = userDtls?.userId;
        notificationPayload.recipientId =
          data.createdBy[0].userId || data.createdBy;
        payload.links = links;
        if (postDetails) payload.postDetails = postDetails;
      }
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        fetchOfferById(offerId);
        sendNotification(notificationPayload);
      } else {
        toaster("error", response?.data?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To fetch offer by id
   */
  const fetchOfferById = async (id) => {
    try {
      startLoading();
      const response = await getOfferById({
        offerId: id,
        influencerId: userDtls?.userId,
      }).unwrap();
      if (response?.data) {
        handlerFetchOffers(
          setFromCompanyList,
          response.data[0].createdBy[0].userId
        ); // from company
        handlerFetchOffers(setSimilarOfferList, null, userDtls?.userId); //Similar view
        offersListFormatter(response.data, setOfferDtls, true);
        // setOfferDtls(response.data[0]);
      }
    } catch (err) {
      // setOfferDtls([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle report offer
   */
  useEffect(() => {
    if (offerId) {
      fetchOfferById(offerId);
    }
  }, [offerId]);

  /**
   * To handle the report or cancel
   * @param {string} type
   */
  const handlerReportAndCancelOffer = async (type) => {
    try {
      startLoading();
      const response = await reportOffer({
        reason: reportDtls?.reason,
        explanation: reportDtls?.explanation,
        influencer_id: userDtls?.userId,
        isReport: type === "report",
        offer_id: offerDtls?._id,
        status: "pending",
      }).unwrap();
      if (response?.data) {
        if (type === "cancel") {
          handlerUpdateBookingStatus(undefined, {
            offerStatus: "cancelled",
            _id: offerDtls?._id,
          });
        } else {
          toaster("info", response?.data?.message);
        }
      } else {
        toaster("info", `${type} offer failed`);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to report offer:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      if (type === "report") {
        setIsReportModalOpen(false);
      } else {
        setIsCancelModalOpen(false);
      }
      stopLoading();
    }
  };
  /**
   * To handle select offer card
   */
  const handleSelectOffersCard = (data) => {
    navigate(`/offers/${data?._id}`);
  };
  const getChildren = (data, isSimilarView = false) => {
    return (
      <>
        <Typography
          display={"flex"}
          className={"textEllipsis mobile-WidthFitContent text-userName "}
          width={"140px"}
          height={32}
          color={"text.main"}
          fontWeight="500"
          fontSize={"12px"}
          variant="body1"
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={20}
          bgcolor={"#f4f3f6"}
          onClick={(e) => handlerCompanyProfile(e, offerDtls)}
        >
          {` by ${data?.createdBy[0]?.username ||
            data?.companyDetails?.username ||
            "Company "
            }`}
        </Typography>
        <Typography
          padding={"6px 12px"}
          display={"flex"}
          variant="body1"
          fontSize={14}
          fontWeight={500}
          color="text.secondary"
          height={"32px"}
          maxWidth={"97px"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={20}
          bgcolor={
            data?.spots?.booked ? "#feedad" : "rgba(176, 220, 255, 0.50)"
          }
          ml={"auto"}
          mr={1}
          // (isSimilarView && gridArea={" 2 / 3 / auto / span 2"})
          sx={{
            gridArea: isSimilarView ? "2 / 3 / auto / span 2" : undefined,
          }}
        >
          {`${data?.spots?.booked}/${data?.spots?.availableSpots} `}
        </Typography>
        {!isSimilarView && (
          <MyButton
            onClick={(e) => handlerUpdateBookingStatus(e, data)}
            className="checkIcon  updateStatusBtn"
            color={offerStatusCode[data?.offerStatus]?.bg}
            sx={{
              borderRadius: "50px",
              fontWeight: 600,
              fontSize: "16px",
              height: "52px",
              // width: "fi",
              bgcolor: "white.main",
              boxShadow: 0,
            }}
            minWidth={"40px"}
            disabled={!(data?.offerStatus === "accept")}
          >
            <Box m={"8px 5px 0 0"}>
              {data?.offerStatus === "accept" ? (
                <CheckIcon sx={{ color: "primary.main" }} />
              ) : data?.offerStatus === "pending" ? (
                <materialIcons.clockIcon />
              ) : (
                <img
                  alt="loading"
                  src={completedIcon}
                  className="mr-auto "
                ></img>
              )}
            </Box>
          </MyButton>
        )}
        <IconButton
          className="SimilarBookMarkIcon  "
          sx={{ height: "32px", position: "unset" }}
        // onClick={(e) => handlerSaveOffer(e, data)}
        >
          {data?.isOfferSaved ? (
            <SvgIcon
              viewBox="0 0 34 34"
              component={SavedBookMarkIcon}
              sx={{ mr: 1, color: "white" }}
            />
          ) : (
            // <img src={bookmarkIcon} style={{ color: "white" }} alt="loading" />
            <SvgIcon
              viewBox="0 0 34 34"
              component={BookMarkIcon}
              sx={{ mr: 1, color: "white.main" }}
            />
          )}
        </IconButton>
      </>
    );
  };

  /**
   * To handle input change
   */
  const handleChange = (e, key) => {
    setReportDtls({ ...reportDtls, [key]: e?.target?.value });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle modal open/close
  const handleReportClick = () => {
    setIsReportModalOpen(true);
    handleClose();
  };

  const handleCancelClick = () => {
    setIsCancelModalOpen(true);
    handleClose();
  };

  const handleReportModalClose = () => {
    setIsReportModalOpen(false);
    handleClose();
  };

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 500,
      },
    },
  };
  /**
   * To handle the save offer function
   * @param {event}
   */
  const handlerSaveOffer = async (e, data) => {
    try {
      e?.stopPropagation();
      e?.preventDefault();
      const payload = {
        offer_id: data?._id,
        // newStatus: "offeredToInfluencer",
        user_id: userDtls?.userId,
      };
      const response = await saveOffer(payload).unwrap();
      if (response?.success) {
        fetchOfferById(offerId);
        toaster("info", "Offer successfully saved");
      } else {
        toaster("info", response?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to save offer:", err);
        toaster("error", "Something went wrong");
      }
    }
  };
  const getSliderPreview = (value) => {
    if (value < 2) {
      return 2;
    } else if (value > 3) {
      return 3;
    } else {
      return value || 2;
    }
  };

  const slidesPerView = isMobile
    ? 1
    : getSliderPreview(offerDtls?.offerImages?.length);

  const isApproveContentDisabled = () => {
    const requireCount =
      offerDtls?.requirement?.reduce((acc, item) => acc + item.count, 0) || 0;
    const presentCount =
      (offerDtls?.completedByInfluencer?.length &&
        offerDtls?.completedByInfluencer?.filter(
          (ele) =>
            ele?.id === offerDtls?.completedByInfluencerUsers?.[0]?.userId
        )?.[0]?.links?.length) ||
      0;
    console.log(
      "count >> ",
      requireCount,
      presentCount,
      requireCount === presentCount
    );
    return requireCount === presentCount ? false : true;
  };
  /**
   * To handle the navigation of the company profile
   */
  const handlerCompanyProfile = (e, data) => {
    e?.preventDefault();
    e?.stopPropagation();
    navigate(`/profile/${data?.createdBy?.[0]?.userId}`);
  };

  /**
   * Custom update status button
   * @returns jsx element
   */
  const customUpdateStatusBtn = () => {
    const currentData = new Date();
    let status = "";
    let bgColor = "";
    let textColor = "white";
    if (offerDtls?.offerStatus === "accept") {
      status = "Accept offer";
      bgColor = "primary.main";
    } else if (offerDtls?.offerStatus === "pending") {
      status = "Pending approval";
      bgColor = "gray.main";
      textColor = "white";
    } else if (offerDtls?.offerStatus === "check") {
      if (new Date(offerDtls?.availableDates?.to) > currentData) {
        status = `Check-in ${formatDate(offerDtls?.availableDates?.to)}`;
        bgColor = "secondary.main";
        textColor = "primary.main";
      } else {
        status = "Complete Offer";
        bgColor = "#D3F5E1";
        textColor = "#6FCF97";
      }
    } else if (offerDtls?.offerStatus === "cancelled") {
      status = "Cancelled";
      bgColor = "#ebe8e8";
      textColor = "#4A4459";
    } else {
      status = `Completed  ${formatDate(
        offerDtls?.availableDates?.from,
        "DD/MM"
      )}-${formatDate(offerDtls?.availableDates?.to, "DD/MM")}`;
      bgColor = "#6FCF97";
      textColor = "white";
    }
    return (
      <MyButton
        onClick={(e) => handlerUpdateBookingStatus(e, offerDtls)}
        color={offerStatusCode[offerDtls?.offerStatus]?.bg}
        sx={{
          borderRadius: "16px",
          fontWeight: 400,
          fontSize: "14px",
          height: "52px",
          minWidth: "155px",
          maxWidth: "218px",
          backgroundColor: bgColor,
          boxShadow: 3,
          ml: 1,
          position: "absolute",
          zIndex: 999,
          "&.Mui-disabled": {
            backgroundColor: "#ebe8e8", // Change to your desired color
          },
        }}
        minWidth={"40px"}
        disabled={
          offerDtls?.offerStatus === "pending" ||
          offerDtls?.offerStatus === "cancelled" ||
          (offerDtls?.offerStatus !== "accept" && isApproveContentDisabled())
        }
      >
        <Box mr={1} mt={"3px"}>
          {offerDtls?.offerStatus === "accept" ||
            offerDtls?.offerStatus === "complete" ? (
            <CheckIcon color="white" />
          ) : offerDtls?.offerStatus === "pending" ? (
            <materialIcons.clockIcon color="white" />
          ) : (
            <img alt="loading" src={completedIcon} className="mr-auto"></img>
          )}
        </Box>
        <span style={{ color: textColor }}>{status}</span>
      </MyButton>
    );
  };
  const Header = () => {
    return (
      <Box
        className="offerViewHeader"
        gap={2}
        height={"108px"}
        width={"100%"}
        sx={{ padding: 1 }}
        alignContent={"center"}
      >
        <Box m={1} display="flex" alignItems={"center"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={1}
          >
            <MyButton
              className="w-ColorBlack"
              type="backButton"
              onClick={() => {
                if (offerIdByModal) {
                  handleCloseOfferDetails();
                } else {
                  navigate(-1);
                }
              }}
            />
            <Typography
              title={offerDtls?.title}
              className={"textEllipsis offerTitle hideOnMobile"}
              width={"fitContent"}
              minWidth={"70px"}
              color={"text.main"}
              fontWeight="400"
              fontSize={"20px"}
              variant="body1"
            >
              {offerDtls?.title}
            </Typography>
            <Typography
              display={"flex"}
              className={
                "textEllipsis mobile-WidthFitContent text-userName hideOnMobile hideOnTab"
              }
              width={"140px"}
              height={32}
              color={"text.main"}
              fontWeight="500"
              fontSize={"12px"}
              variant="body1"
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={20}
              bgcolor={"#f4f3f6"}
              onClick={(e) => handlerCompanyProfile(e, offerDtls)}
            >
              {` by ${offerDtls?.createdBy[0]?.username ||
                offerDtls?.companyDetails?.username ||
                "Company "
                }`}
            </Typography>
          </Box>
          <Typography
            className="hideOnMobile"
            display={"flex"}
            variant="body1"
            fontSize={12}
            fontWeight={700}
            color="text.secondary"
            height={"32px"}
            width={"89px"}
            minWidth={67}
            alignItems={"center"}
            justifyContent={"center"}
            border={1}
            borderColor={"#CAC4D0"}
            borderRadius={20}
            ml={"auto"}
          >
            {`${formatDate(
              offerDtls?.availableDates?.from,
              "DD/MM"
            )}-${formatDate(offerDtls?.availableDates?.to, "DD/MM")}`}
          </Typography>
          <Typography
            className="hideOnMobile hideOnTab"
            padding={"6px 12px"}
            display={"flex"}
            variant="body1"
            fontSize={14}
            fontWeight={500}
            color="text.secondary"
            height={"32px"}
            maxWidth={"97px"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={20}
            ml={1}
            bgcolor={
              offerDtls?.spots?.booked ? "#feedad" : "rgba(176, 220, 255, 0.50)"
            }
          >
            <span className="bold">
              {`${offerDtls?.spots?.booked}/${offerDtls?.spots?.availableSpots} `}
            </span>
            <span style={{ marginLeft: "2px" }} className="hideOnMobile">
              booked
            </span>
          </Typography>
          {!offerIdByModal && (
            <>
              <MyButton
                onClick={(e) => handlerUpdateBookingStatus(e, offerDtls)}
                className="checkIcon btn list-btn updateStatusBtn hideOnMobile"
                color={offerStatusCode[offerDtls?.offerStatus]?.bg}
                sx={{
                  borderRadius: "50px",
                  fontWeight: 600,
                  fontSize: "16px",
                  height: "52px",
                  width: "124px",
                  bgcolor: "#e9d8ea",
                  boxShadow: 0,
                  ml: 1,
                }}
                minWidth={"40px"}
                disabled={!(offerDtls?.offerStatus === "accept")}
              >
                <Box mr={"8px"} mt={"8px"}>
                  {offerDtls?.offerStatus === "accept" ? (
                    <CheckIcon sx={{ color: "primary.main" }} />
                  ) : offerDtls?.offerStatus === "pending" ? (
                    <materialIcons.clockIcon />
                  ) : (
                    // <img
                    //   alt="loading"
                    //   src={completedIcon}
                    //   className="mr-auto"
                    // ></img>
                    <CheckIcon sx={{ color: "gray" }} />
                  )}
                </Box>
                <span
                  className="hideOnMobile textColor hideOnTab"
                  style={{
                    color: `${offerDtls?.offerStatus === "pending" ? "gray" : ""
                      }`,
                  }}
                >
                  {offerDtls?.offerStatus || "test"}
                </span>
              </MyButton>
              <IconButton
                className="offerBookMarkBtn"
                sx={{
                  height: "34px",
                  width: 34,
                  position: "unset",
                  p: 0,
                  // m: "8px",
                }}
                onClick={(e) => handlerSaveOffer(e, offerDtls)}
              >
                {offerDtls?.isOfferSaved ? (
                  // <TurnedInIcon color="secondary" />

                  <SvgIcon
                    sx={{ height: "34px", width: 34 }}
                    component={isMobile ? bookMarkIconWhite : SavedBookMarkIcon}
                  // sx={{ mr: 1 }}
                  />
                ) : (
                  // <img src={bookmarkIcon} style={{ color: "white" }} alt="loading" />
                  // <TurnedInNotIcon color="secondary" />

                  <SvgIcon
                    className="w-ColorBlack"
                    component={BookMarkIcon}
                  // sx={{ mr: 1 }}
                  />
                )}
              </IconButton>
              <Button
                onClick={handleClick}
                sx={{ height: "32px", padding: "2px" }}
                className="w-ColorBlack"
              >
                <MoreVertIcon onClick={handleClick} />
              </Button>
            </>
          )}
          <Popover
            id={id}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              horizontal: "right",
              width: "200px",
            }}
            sx={{ top: "30px" }}
          >
            <Typography
              sx={{ p: 1, width: "150px", cursor: "pointer" }}
              onClick={handleReportClick}
            >
              Report
            </Typography>
            {offerDtls?.isOfferedToInfluencer && (
              <Typography
                sx={{ p: 1, cursor: "pointer" }}
                onClick={handleCancelClick}
              >
                Cancel
              </Typography>
            )}
          </Popover>
        </Box>
      </Box>
    );
  };
  return (
    <div className="company-offer-ctn">
      {offerDtls?._id && <Header />}
      {!offerIdByModal && (
        <Box className="m-updateStatusBtn hideOnWeb">
          {customUpdateStatusBtn()}
        </Box>
      )}

      <Box className="offerViewContainer">
        <OfferDetails
          offerId={offerDtls?._id}
          isInfluencerView={true}
          handlerUpdateBookingStatus={handlerUpdateBookingStatus}
        />
        <Divider />
        {!offerIdByModal && (
          <>
            {" "}
            {fromCompanyList?.length > 0 && (
              <>
                {/* <Box
              height={80}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            > */}
                <Typography
                  variant="h5"
                  color="gray"
                  fontSize={18}
                  fontWeight={700}
                  mb={2}
                  textAlign={"center"}
                >
                  More by company
                </Typography>
                {/* </Box> */}
                <Box className="moreFromCompanyCtn" mb={2} p={1}>
                  {/* <Swiper
                className="offerScrollCtn"
                modules={[Virtual, Navigation, HashNavigation]}
                spaceBetween={4}
                navigation={true}
                slidesPerView={slidesPerView}
                virtual
                hashNavigation={true}
              > */}
                  {fromCompanyList?.map((ele, index) => {
                    return (
                      // <SwiperSlide
                      //   key={`offer-image-${index}`}
                      //   virtualIndex={index}
                      // >
                      <PhotoView
                        style={{ overFlow: "auto" }}
                        data={ele}
                        key={index}
                        isSimilarView={true}
                      ></PhotoView>
                      // </SwiperSlide>
                    );
                  })}
                  {/* </Swiper> */}
                </Box>
              </>
            )}
            {similarOfferList?.length > 0 && (
              <>
                {/* <Box
              height={80}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            > */}
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  variant="h5"
                  color="gray"
                  mb={2}
                  textAlign={"center"}
                >
                  Similar offers
                </Typography>
                {/* </Box> */}
                <Box
                  className="similarCtn"
                  display={"flex"}
                  gap={2}
                  mb={2}
                  p={1}
                  flexWrap={"wrap"}
                >
                  {similarOfferList.map((ele, index) => {
                    return (
                      <InfluencerOffersList
                        width={360}
                        data={ele}
                        style={{ overFlow: "auto" }}
                        handleSelectOffersCard={handleSelectOffersCard}
                        module={"offer"}
                        handlerUpdateBookingStatus={handlerUpdateBookingStatus}
                        handlerFetchOffers={handlerFetchOffers}
                        isSavedOffer={ele?.isOfferSaved || false}
                        children={getChildren(ele, true)}
                        className={"SimilarOfferTitle"}
                        ctnClassName={"similarOfferListView"}
                      />
                    );
                  })}
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      {/* Report Modal */}
      <Modal open={isReportModalOpen} onClose={handleReportModalClose}>
        <Box
          className="filter-modal"
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #e8def8",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "5px",
              mb: 2,
            }}
          >
            <ArrowBackIcon onClick={handleReportModalClose} />
            <Typography variant="h6" component="h2">
              Report offer
            </Typography>
            <CloseIcon
              onClick={handleReportModalClose}
              sx={{ marginLeft: "auto" }}
            />
          </Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              bgcolor: "#000",
              borderRadius: "28px",
              p: "12px",
              gap: 1,
              mb: 1,
            }}
          >
            <Box
              sx={{
                width: 48,
                minWidth: 48,
                height: 48,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#1d0c1e",
              }}
            >
              <PriorityHighIcon
                sx={{
                  width: 24,
                  // minWidth: 48,
                  height: 24,
                  color: "#8F3A98",
                }}
              />
            </Box>
            <Typography
              fontSize={14}
              fontWeight={400}
              sx={{ color: "#F5EFF7" }}
            >
              Reporting this offer means you will no longer be able to interact
              with it.
            </Typography>
          </Stack>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1 } }}
            noValidate
            autoComplete="off"
          >
            <Stack sx={{ gap: "10px" }}>
              <Typography component="h2">Reason for reporting</Typography>
              <FormControl sx={{ m: 1, width: 300, }} >
                <InputLabel id="demo-multiple-checkbox-label" >
                  Reason
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  // multiple
                  value={reportDtls?.reason}
                  onChange={(e) => handleChange(e, "reason")}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                  
                >
                  {reportOfferReason?.map((ele) => (
                    <MenuItem key={ele?.id} value={ele?.value}>
                      <Checkbox
                        checked={reportDtls?.reason.indexOf(ele?.value) > -1}
                      />
                      <ListItemText primary={ele?.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography component="h2">Please elaborate here</Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                defaultValue={reportDtls?.explanation}
                onChange={(e) => handleChange(e, "explanation")}
              />
            </Stack>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            mt={"auto"}
            justifyContent="space-between"
          >
            <MyButton
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
                color: "primary.main",
                bgcolor: "#ffff",
              }}
            >
              Help
            </MyButton>
            <MyButton
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
              }}
              disabled={
                !reportDtls?.reason?.length > 0 || !reportDtls?.explanation
              }
              onClick={() => handlerReportAndCancelOffer("report")}
            >
              Report offer
            </MyButton>
          </Stack>
        </Box>
      </Modal>

      {/* Cancel Modal */}
      <Modal open={isCancelModalOpen} onClose={handleCancelModalClose}>
        <Box
          className="filter-modal"
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #e8def8",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "5px",
              mb: 2,
            }}
          >
            <ArrowBackIcon onClick={handleCancelModalClose} />
            <Typography variant="h6" component="h2">
              Cancel Offer
            </Typography>
            <CloseIcon
              onClick={handleCancelModalClose}
              sx={{ marginLeft: "auto" }}
            />
          </Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              bgcolor: "#000",
              borderRadius: "28px",
              p: "12px",
              gap: 1,
              mb: 1,
            }}
          >
            <Box
              sx={{
                width: 48,
                minWidth: 48,
                height: 48,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#1d0c1e",
              }}
            >
              <PriorityHighIcon
                sx={{
                  width: 24,
                  // minWidth: 48,
                  height: 24,
                  color: "#8F3A98",
                }}
              />
            </Box>
            <Typography
              fontSize={14}
              fontWeight={400}
              sx={{ color: "#F5EFF7" }}
            >
              Cancelling this offer means you will no longer be able to interact
              with it.
            </Typography>
          </Stack>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1 } }}
            noValidate
            autoComplete="off"
          >
            <Stack sx={{ gap: "10px" }}>
              <Typography component="h2">Reason for cancelling</Typography>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Reason
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  // multiple
                  value={reportDtls?.reason}
                  onChange={(e) => handleChange(e, "reason")}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                >
                  {reportOfferReason?.map((ele) => (
                    <MenuItem key={ele?.id} value={ele?.value}>
                      <Checkbox
                        checked={reportDtls?.reason.indexOf(ele?.value) > -1}
                      />
                      <ListItemText primary={ele?.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography component="h2">Explain</Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                defaultValue={reportDtls?.explanation}
                onChange={(e) => handleChange(e, "explanation")}
              />
            </Stack>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            mt={"auto"}
            justifyContent="space-between"
          >
            <MyButton
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
              }}
            >
              Help
            </MyButton>
            <MyButton
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
              }}
              disabled={
                !reportDtls?.reason?.length > 0 || !reportDtls?.explanation
              }
              onClick={() => handlerReportAndCancelOffer("cancel")}
            >
              Cancel offer
            </MyButton>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};
export default InfluencerOfferView;
