import { Box, Button, Divider, Stack, Typography } from "@mui/material";

const ResetPassword = ({
  inputField,
  formValues,
  validationError,
  handlerResetPassword,
  handleBack,
}) => {
  return (
    <>
      <Typography
        variant="h5"
        padding={1}
        textAlign="center"
        letterSpacing={"-0.01em"}
        fontWeight={500}
        fontSize={16}
        color={"primary"}
      >
        New password
      </Typography>
      {/* <Divider sx={{ width: "80%" }} component="div" role="presentation" /> */}
      {/* <Typography
        variant="body2"
        padding={1}
        textAlign="center"
        letterSpacing={"-0.01em"}
        fontWeight={500}
        color={"primary"}
      >
        The password must be different than before
      </Typography> */}
      <Box
        component="section"
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
        gap={"15px"}
        sx={{ width: "100%" }}
        padding={1}
      >
        {inputField?.map((ele, index) => {
          return ele;
        })}
        <Stack marginTop={1} direction={"row"} gap={2} marginBottom={1}>
          <>
            <Button
              variant="contained"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "capitalize",
                color: "primary.main",
                height: 56,
                width: 162,
                borderRadius: 4,
                bgcolor: "white.main",
              }}
              size="large"
              onClick={handleBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                textTransform: "capitalize",
                height: 56,
                width: 162,
                borderRadius: 4,
              }}
              size="large"
              onClick={handlerResetPassword}
              disabled={
                !formValues?.password?.value ||
                !formValues?.passwordAgain?.value ||
                validationError?.passwordAgain
              }
            >
              Save password
            </Button>
          </>
        </Stack>
      </Box>
    </>
  );
};
export default ResetPassword;
