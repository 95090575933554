import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Stack,
  Typography,
  Modal,
  IconButton,
  TextField,
  Chip,
  Button,
  Dialog,
  useMediaQuery,
  DialogTitle,
} from "@mui/material";
import "./Bookings.css";
import InfluencerOffersList from "../Influencer/Offers/InfluencerOffersList";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import messageIcon from "../../assets/Icons/messageIcon.png";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import {
  useCreateChatMutation,
  useGetBookingListMutation,
  useUpdateBookingStatusMutation,
  useUpdateOfferMutation,
} from "../../services/apiService/userApiService";
import {
  categoryList,
  filterDtlsInitialValue,
  notificationCode,
} from "../../utils/constants";
import toaster from "../Toaster/toaster";
import useNotification from "../../hooks/usePushNotification";
import MyButton from "../MyButton";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useLoading from "../../hooks/useLoading";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import LocationAutoComplete from "../LocationAutoComplete/LocationAutoComplete";
import { formatDate } from "../../utils/common";
import materialIcons, { CalenderIcon } from "../../utils/materialIcon";
import MyIcon from "../MyIcon/MyIcon";
import { useNavigate } from "react-router-dom";
import CompanyBookings from "./CompanyBookings";
import { setBookingFilter } from "../../store/Slicers/filterSlice";
import CheckIcon from "@mui/icons-material/Check";
const Bookings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bookingFilter } = useSelector((state) => state?.filter);
  const [updateBookingStatus] = useUpdateBookingStatusMutation();
  const { sendNotification } = useNotification();
  const [updateOffer] = useUpdateOfferMutation();
  const { startLoading, stopLoading } = useLoading();
  const { userDtls, userType } = useSelector((state) => state?.auth);
  const [filterDtls, setFilterDtls] = useState(filterDtlsInitialValue);
  const [createChat] = useCreateChatMutation();
  const [requestedDate, setRequestedDate] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dateRequestDtls, setdateRequestDtls] = useState({});
  const [getBookingList] = useGetBookingListMutation();
  // const [query, setQuery] = useState("");
  const [location, setLocation] = useState({});
  const [filterLocation, setFilterLocation] = useState({});
  const [itemSize, setItemSize] = useState(100);
  const [dateList, setDateList] = useState({
    fromDate: { start: "", end: "" },
    toDate: { start: "", end: "" },
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const currentData = new Date();
  const [bookingList, setBookingList] = useState([]);
  const [isShowFilter, setIsShowFilter] = useState(false);
  /**
   * To update booking status
   */
  const handlerUpdateBookingStatus = async (e, data, type) => {
    try {
      startLoading();
      e.stopPropagation();
      e.preventDefault();
      let payload = {
        offerId: data?._id,
        influencerId: data?.influencerDetails?.userId,
        newStatus: type || "offeredToInfluencer",
        message: notificationCode?.offerAccepted,
      };
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        fetchBookingData();
        sendNotification({
          senderId: userDtls?.userId,
          recipientId: data.influencerDetails.userId,
          message: type
            ? notificationCode?.offerCompletedByCompany
            : notificationCode?.offerAccepted,
          isRead: false,
          type: "remainder",
        });
      } else {
        toaster("error", response?.data?.message);
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle the date request
   */
  const handlerDateRequest = async () => {
    startLoading();
    try {
      const payload = {
        influencerId: userDtls?.userId,
        date: requestedDate,
        isDateRequested: true,
        _id: dateRequestDtls?._id,
      };
      // need to update the body
      if (userDtls?.type === "company") {
        delete payload?.date;
        payload.isDataRequestAccepted = true;
        payload.isDateRequested = false;
        payload.influencerId = dateRequestDtls?.influencerDetails?.userId;
      }
      const response = await updateOffer(payload).unwrap(); // For update offer
      if (response?.data) {
        fetchBookingData();
        sendNotification({
          senderId: userDtls?.userId,
          recipientId: response?.data?.createdBy,
          message: notificationCode?.dateRequest,
          isRead: false,
          type: "remainder",
        });
        toaster(
          "success",
          userDtls?.userType === "company"
            ? "Date request approved successfully"
            : "Date request sent successfully"
        );
      } else {
        toaster("error", "Date request  failed");
      }
    } catch (err) {
      toaster("error", "Something went wrong");
    } finally {
      stopLoading();
      handlerCloseDateRequestModal();
    }
  };
  const handlerCloseDateRequestModal = () => {
    setShowDialog(false);
    setRequestedDate("");
    setdateRequestDtls({});
  };
  const handleTabChange = (event, newValue) => {
    // setTabValue(newValue);
    dispatch(setBookingFilter(newValue));
  };
  /**
   * To handle the chat navigation
   */
  const handlerChat = async (e, data) => {
    e?.preventDefault();
    e?.stopPropagation();
    try {
      startLoading();
      const payload = {
        isGroupChat: false,
        id: userDtls?.userId,
      };
      if (userDtls?.type === "influencer") {
        payload.userId = data?.createdBy;
      } else {
        payload.userId = data?.influencerDetails?.userId;
      }
      const response = await createChat(payload).unwrap();
      if (response?.data[0]?._id || response?.data?._id) {
        navigate(`/chat/${response?.data[0]?._id || response?.data?._id}`);
        // navigate("/message");
      } else {
        toaster("error", "Chat creation failed");
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };

  /**
   * To handle the navigation of the company profile
   */
  const handlerCompanyProfile = (e, data) => {
    e?.preventDefault();
    e?.stopPropagation();
    navigate(`/profile/${data?.createdBy?.[0]?.userId}`);
  };

  /**
   * @returns date change request button jsx element
   */
  const dateChangeRequestBtn = (data) => {
    if (bookingFilter !== "requestToBusiness") {
      return (
        <Button
          onClick={(e) => {
            e?.stopPropagation();
            e?.preventDefault();
            setShowDialog(true);
            setdateRequestDtls(data);
          }}
          sx={{
            textTransform: "none",
            p: 1,
            m: 1,
            borderRadius: 4,
            bgcolor: "#f4ecf5",
            width: "fit-content",
            gridArea: "2 / 3 ",
            ml: "auto",
            mr: 4,
            "&:hover": {
              backgroundColor: "#ffff !important",
            },
          }}
          disabled={data?.dateRequest?.isDateRequested}
        >
          <>
            <PublishedWithChangesIcon sx={{ mr: { md: 1 } }} />
            <span className="hideOnMobile">
              {data?.dateRequest?.isDateRequested ? "Pending" : "Date request"}
            </span>
          </>
        </Button>
      );
    }
    return null;
  };

  const getChildren = (data) => {
    return (
      <>
        {(bookingFilter === "requestToBusiness" ||
          bookingFilter === "completedByInfluencer") && (
            <>
              <Typography
                display={"flex"}
                className={"textEllipsis mobile-WidthFitContent"}
                width={"140px"}
                height={32}
                color={"text.main"}
                fontWeight="500"
                fontSize={"12px"}
                variant="body1"
                // marginLeft={"auto"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={20}
                gridArea={" 2 /2"}
                marginLeft={"16px"}
                onClick={(e) => handlerCompanyProfile(e, data)}
              >
                {` by ${data?.createdBy[0]?.username ||
                  data?.companyDetails?.username ||
                  "Company"
                  }`}
              </Typography>
              {bookingFilter === "requestToBusiness" && (
                <>
                  {dateChangeRequestBtn(data)}
                  <materialIcons.clockIcon
                    color="primary"
                    sx={{ marginLeft: "auto", gridArea: "2/3" }}
                  />
                </>
              )}
            </>
          )}
        {bookingFilter === "offeredToInfluencer" && (
          <Box className="bookingAction">
            {dateChangeRequestBtn(data)}
            {new Date(data?.availableDates?.to) > currentData ? (
              <Box display={"flex"} gap={"5px"}>
                <Typography
                  padding={"6px 12px"}
                  display={"flex"}
                  variant="body1"
                  fontSize={14}
                  fontWeight={500}
                  color="text.secondary"
                  height={"32px"}
                  width={"67px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={20}
                  bgcolor={
                    data?.spots?.booked
                      ? "#feedad"
                      : "rgba(176, 220, 255, 0.50)"
                  }
                >
                  booked
                </Typography>
                <Typography
                  display={"flex"}
                  variant="body1"
                  fontSize={14}
                  fontWeight={700}
                  color="text.secondary"
                  height={"32px"}
                  width={"100px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  border={1}
                  borderColor={"#CAC4D0"}
                  borderRadius={20}
                >
                  {`${formatDate(
                    data?.availableDates?.from,
                    "DD/MM"
                  )}-${formatDate(data?.availableDates?.to, "DD/MM")}`}
                </Typography>
              </Box>
            ) : (
              <Typography
                className="completeOffer"
                padding={"6px 12px"}
                display={"flex"}
                variant="body1"
                fontSize={14}
                fontWeight={400}
                color="#36A162"
                height={"32px"}
                width={"172px"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={20}
                bgcolor={"#D3F5E1"}
              >
                <CalenderIcon color="#6FCF97" />
                Complete offer
              </Typography>
            )}
          </Box>
        )}
        {bookingFilter === "completedByInfluencer" && (
          <Box className="bookingAction" ml={"auto"}>
            <Typography
              padding={"6px 12px"}
              display={"flex"}
              variant="body1"
              fontSize={14}
              fontWeight={400}
              color="#36A162"
              height={"32px"}
              width={"fitContent"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={20}
            // bgcolor={"#D3F5E1"}
            >
              <CheckIcon color="gray" style={{ marginRight: 5 }} />
              <span className="hideOnMobile" style={{ color: "gray" }}>
                Offer completed
              </span>
            </Typography>
          </Box>
        )}
        {bookingFilter !== "requestToBusiness" && (
          <Box
            className="topLeft"
            width={28}
            height={28}
            onClick={(e) => handlerChat(e, data)}
          >
            {bookingFilter === "offeredToInfluencer" ? (
              <MyIcon icon={messageIcon} />
            ) : (
              ""
            )}
          </Box>
        )}
      </>
    );
  };

  function samePageLinkNavigation(event) {
    if (
      event.defaultPrevented ||
      event.button !== 0 || // ignore everything but left-click
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    ) {
      return false;
    }
    return true;
  }

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          // Routing libraries handle this, you can remove the onClick handle when using them.
          if (samePageLinkNavigation(event)) {
            event.preventDefault();
          }
        }}
        aria-current={props.selected && "page"}
        {...props}
      />
    );
  }

  LinkTab.propTypes = {
    selected: PropTypes.bool,
  };

  const fetchBookingData = async () => {
    try {
      startLoading();
      const payload = {
        // status: "active",
      };
      if (userDtls?.type === "influencer") {
        payload.influencerId = userDtls?.userId;
      } else if (userDtls?.type === "company") {
        payload.businessId = userDtls?.userId;
      }
      const response = await getBookingList({
        id: userDtls?._id,
        filter: payload,
      });
      if (response?.data?.data) {
        if (userType === "influencer") {
          const bookingDtls = response?.data?.data[bookingFilter]?.filter?.(
            (item) => item?.influencerDetails?.userId === userDtls?.userId
          );
          const formattedData = bookingDtls?.map((ele) => {
            const index = ele?.dateRequest?.findIndex(
              (item) => item?.influencerId === userDtls?.userId
            );
            if (index !== -1 && index !== undefined) {
              ele.dateRequest = ele?.dateRequest?.[index];
            }
            return ele;
          });
          setBookingList(formattedData || []);
        } else {
          const formattedData = response?.data?.data[bookingFilter]?.map(
            (ele) => {
              const index = ele?.dateRequest?.findIndex(
                (item) => item?.influencerId === ele?.influencerDetails?.userId
              );
              if (index !== -1 && index !== undefined) {
                ele.dateRequest = ele?.dateRequest?.[index];
              }
              return ele;
            }
          );
          setBookingList(formattedData || []);
        }
      } else {
        setBookingList([]);
      }
    } catch (error) {
      toaster("error", "fetch booking list api failed");
      return;
    } finally {
      stopLoading();
    }
  };
  /**
   * To handle date onchange
   */
  const handlerDateOnchange = (key1, key2, value) => {
    setDateList((prev) => {
      return { ...prev, [key1]: { ...prev[key1], [key2]: value } };
    });
  };

  /**
   * To handle changes in input field
   */
  const handlerOnchange = (key1, value) => {
    if (key1 === "location") {
      setFilterDtls({ ...filterDtls, location: value });
      return;
    }
    setFilterDtls({
      ...filterDtls,
      [key1]: Array.isArray(filterDtls[key1])
        ? checkDuplicate(filterDtls[key1], value)
        : value?.value || value || "",
    });
  };

  const checkDuplicate = (array, obj) => {
    // Array.isArray(array) ? array : arra``
    const index = array?.findIndex((item) => item?.id === obj?.id);
    if (index !== -1) {
      return array?.filter((item) => item?.id !== obj?.id);
    } else {
      return [...array, obj];
    }
  };

  const handleClearFilters = () => {
    setFilterDtls({ filterDtlsInitialValue });
    setDateList({
      fromDate: { start: "", end: "" },
      toDate: { start: "", end: "" },
    });
    setLocation({});
    setFilterLocation({});
    // setSearchQuery("");
    // setSelectedTypes([]);
    // setSelectedOptions([]); // Clear location
    // setValue([null, null]); // Clear date range
    fetchBookingData(true);
  };

  useEffect(() => {
    fetchBookingData();
  }, [bookingFilter]);

  // Dynamically calculate item size based on screen width
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      // Adjust item size based on the screen width
      if (screenWidth < 768) {
        setItemSize(100); // Smaller item size for mobile screens
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setItemSize(180); // Medium size for tablets
      } else {
        setItemSize(120); // Larger size for desktops
      }
    };

    // Add resize event listener to handle screen resize
    window.addEventListener("resize", handleResize);

    // Set initial item size on mount
    handleResize();

    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Header = () => {
    return (
      <>
        <Box className="bookingHeader" sx={{ padding: "6px" }}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Tabs
              value={bookingFilter}
              onChange={handleTabChange}
              aria-label="nav tabs example"
              role="navigation"
              style={{
                padding: "0px 10px 0px 10px"
              }}
            >
              <Tab
                sx={{ textTransform: "capitalize" }}
                style={{
                  color: "black",
                  fontWeight: `${bookingFilter === "requestToBusiness" ? "bolder" : "500"}`
                }}
                fontWeight={700}
                color={"#1D192B"}
                label="Pending"
                value="requestToBusiness"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                style={{
                  color: "black",
                  fontWeight: `${bookingFilter === "offeredToInfluencer" ? "bolder" : "500"}`
                }}
                label="Active"
                value="offeredToInfluencer"
                color={"#1D192B"}
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                style={{
                  color: "black",
                  fontWeight: `${bookingFilter === "completedByInfluencer" ? "bolder" : "500"}`
                }}
                label="Completed"
                value="completedByInfluencer"
                color={"#1D192B"}
              />
            </Tabs>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <>
      <div className="booking">
        <Header />
        <div className="booking-list">
          <div className="card-booking-view">
            {userDtls?.type === "influencer" ? (
              <>
                {bookingList?.map((ele) => {
                  return (
                    <InfluencerOffersList
                      key={ele}
                      data={ele}
                      module={"booking"}
                      bookingFilter={bookingFilter}
                      showApproveReject={bookingFilter === "requestToBusiness"}
                      handlerUpdateBookingStatus={handlerUpdateBookingStatus}
                      handlerFetchOffers={fetchBookingData}
                      children={getChildren(ele)}
                      className={"bookingTitle"}
                      ctnClassName={"bookingOfferListView"}
                    ></InfluencerOffersList>
                  );
                })}
              </>
            ) : (
              <>
                {bookingList?.map((ele) => {
                  return (
                    <CompanyBookings
                      key={ele}
                      data={ele}
                      module={"booking"}
                      bookingFilter={bookingFilter}
                      showApproveReject={bookingFilter === "requestToBusiness"}
                      handlerUpdateBookingStatus={handlerUpdateBookingStatus}
                      handlerFetchOffers={fetchBookingData}
                      // children={getChildren(ele)}
                      setShowDialog={setShowDialog}
                      setdateRequestDtls={setdateRequestDtls}
                      className={"bookingTitle"}
                      ctnClassName={"bookingOfferListView"}
                    ></CompanyBookings>
                  );
                })}
              </>
            )}
          </div>
          {bookingList[bookingFilter]?.length === 0 && (
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography fontWeight="700" variant="body1" color="primary">
                No data found.
              </Typography>
            </Box>
          )}
        </div>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          borderRadius: 50,
          display: "flex",
          justifyContent: "center",
          bgcolor: "transparent",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
        className="modalSection"
      >
        <Box
          className="modalCtn"
          sx={{
            p: 1,
            minWidth: 320,
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            textAlign={"center"}
            fontSize={24}
            fontWeight={400}
          >
            Date change request
          </DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            {userDtls?.type === "influencer" ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    value={dateList?.fromDate?.start || null}
                    onChange={(e) => {
                      setRequestedDate(e);
                    }}
                    disablePast
                    label="Start date..."
                  />
                </DemoContainer>
              </LocalizationProvider>
            ) : (
              <Typography>{`Do you want to approve the date request on ${formatDate(
                dateRequestDtls?.dateRequest?.data,
                "DD/MM"
              )} for the ${dateRequestDtls?.influencerDetails?.firstName
                } ?`}</Typography>
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            mt={3}
            justifyContent="space-between"
          >
            <MyButton
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
              }}
              onClick={() => {
                setRequestedDate("");
                setShowDialog(false);
                setdateRequestDtls({});
              }}
            >
              cancel
            </MyButton>
            {userDtls?.type === "influencer" ? (
              <MyButton
                disabled={!requestedDate}
                onClick={() => {
                  setIsShowFilter(false);
                  handlerDateRequest();
                }}
                sx={{
                  width: "50%",
                  borderRadius: "15px",
                  borderColor: "text.secondary",
                }}
              >
                send
              </MyButton>
            ) : (
              <MyButton
                onClick={() => {
                  setIsShowFilter(false);
                  handlerDateRequest();
                }}
                sx={{
                  width: "50%",
                  borderRadius: "15px",
                  borderColor: "text.secondary",
                }}
              >
                Approve
              </MyButton>
            )}
          </Stack>
        </Box>
      </Dialog>
      {/* Filter modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isShowFilter}
        onClose={setIsShowFilter}
        closeAfterTransition
      >
        <Box
          className="filter-modal"
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #e8def8",
            borderRadius: "15px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Filter
            </Typography>
            <IconButton onClick={() => setIsShowFilter(false)}>
              <CloseIcon sx={{ color: "primary" }} />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={1} flexWrap="wrap" mb={2} gap={1}>
            {categoryList?.map((ele, index) => (
              <Chip
                key={index}
                label={ele?.value}
                clickable
                color={
                  filterDtls?.categories?.find((item) => item?.id === ele?.id)
                    ? "primary"
                    : "secondary"
                }
                onClick={() => handlerOnchange("categories", ele)}
                sx={{ border: "1px" }}
              // border={1}
              />
            ))}
          </Stack>
          <Typography
            id="transition-modal-title"
            variant="body1"
            component="h2"
            mt={1}
          >
            From Date
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  value={dateList?.fromDate?.start || null}
                  onChange={(e) => {
                    handlerDateOnchange("fromDate", "start", e);
                  }}
                  // disablePast
                  label="Start date..."
                />
                <DatePicker
                  value={
                    dateList?.fromDate?.end || dateList?.fromDate?.start || null
                  }
                  // disabled={!dateList.start}
                  minDate={dayjs(new Date(dateList?.fromDate?.start)).add(
                    2,
                    "day"
                  )}
                  label="End date..."
                  onChange={(e) => {
                    handlerDateOnchange("fromDate", "end", e);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
          <Typography
            id="transition-modal-title"
            variant="body1"
            component="h2"
          >
            To Date
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  value={dateList?.toDate?.start || null}
                  onChange={(e) => {
                    handlerDateOnchange("toDate", "start", e);
                  }}
                  disablePast
                  label="Start date..."
                />
                <DatePicker
                  value={
                    dateList?.toDate?.start || dateList?.toDate?.end || null
                  }
                  // disabled={!dateList.start}
                  minDate={dayjs(new Date(dateList?.toDate?.start)).add(
                    2,
                    "day"
                  )}
                  label="End date..."
                  onChange={(e) => {
                    handlerDateOnchange("toDate", "end", e);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
          <Box
            component="form"
            sx={{
              "& > :not(style)": {
                m: 1,
                width: "100%",
                borderBottom: "1px solid gray",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <LocationAutoComplete
              setLocation={setLocation}
              setFilterLocation={setFilterLocation}
            />
          </Box>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "100%" } }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-basic"
              label="Preference"
              variant="standard"
            />
          </Box>
          <Stack
            direction="row"
            spacing={2}
            mt={3}
            justifyContent="space-between"
          >
            <MyButton
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
              }}
              onClick={handleClearFilters}
            >
              Clear all filters
            </MyButton>
            <MyButton
              disabled={
                !dateList?.fromDate?.start &&
                !dateList?.toDate?.start &&
                !filterDtls?.location?.length > 0 &&
                !filterDtls?.categories?.length > 0 &&
                !location?.locationName?.length > 0
              }
              onClick={() => {
                // fetchBookingData();
                setIsShowFilter(false);
              }}
              sx={{
                width: "50%",
                borderRadius: "15px",
                borderColor: "text.secondary",
              }}
            >
              Apply
            </MyButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default Bookings;
