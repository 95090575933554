import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  IconButton,
  Modal,
  Typography,
  SvgIcon,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import MyButton from "../../MyButton";
import AddIcon from "@mui/icons-material/Add";
import { PlusIcon } from "../../../utils/materialIcon"
import DoneIcon from "@mui/icons-material/Done";
import Badge from "@mui/material/Badge";
import "./CompanyOffers.css";
import CompanyOffersList from "./CompanyOffersList";
import TodayIcon from "@mui/icons-material/Today";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ListIcon from "@mui/icons-material/List";
import influencerCardIcon from "../../../assets/image/InfluencerCardIcon.png";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import materialIcons, {
  BookMarkIcon,
  CalenderIcon,
  EditIcon,
  OfferIcon,
  RemoveIcon,
  SavedBookMarkIcon,
} from "../../../utils/materialIcon";
import "../../../style/CommonStyleNew.css";
import CompanyOffersRequest from "./CompanyOffersRequest";
import CreateOffers from "./CreateOffers";
import OfferDetails from "../../OfferDetails/OfferDetails";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  useGetOffersMutation,
  useUpdateBookingStatusMutation,
  useGetOfferByIdMutation,
  useGetCompanyOrInfluencerListMutation,
} from "../../../services/apiService/userApiService";
import toaster from "../../Toaster/toaster";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  categoryList,
  filterDtlsInitialValue,
  notificationCode,
  offerFilterCode,
  offerStatusCode,
} from "../../../utils/constants";
import { formatDate } from "../../../utils/common";
import useNotification from "../../../hooks/usePushNotification";
import useLoading from "../../../hooks/useLoading";
import TuneIcon from "@mui/icons-material/Tune";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import LocationAutoComplete from "../../LocationAutoComplete/LocationAutoComplete";
import { FixedSizeList as List } from "react-window";
import InfluencerOffersList from "../../Influencer/Offers/InfluencerOffersList";
import { setCompanyOfferFilter } from "../../../store/Slicers/filterSlice";
const currentData = new Date();

//
const CompanyOffer = () => {
  const navigate = useNavigate(); // To navigate
  const dispatch = useDispatch();
  const { userDtls, isCompany } = useSelector((state) => state?.auth);
  const { companyOfferFilter } = useSelector((state) => state?.filter);
  const { startLoading, stopLoading } = useLoading();
  const [getOffers] = useGetOffersMutation();
  const [getOfferById] = useGetOfferByIdMutation();
  const [getInfluencerOrCompanyList] = useGetCompanyOrInfluencerListMutation();
  const [offersCardTab, setOffersCardTab] = useState("status");
  const [query, setQuery] = useState("");
  // const [tabFilter, setTabFilter] = useState("all");
  const [tabCardFilter, setTabCardFilter] = useState("pending");
  const [offerType, setOfferType] = useState("Create");
  const [offersList, setOffersList] = useState([]);
  const [location, setLocation] = useState({});
  const [filterLocation, setFilterLocation] = useState({});
  const [nameList, setNameList] = useState([]);
  const [cardSelected, setCardSelected] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isShowCreateOffers, setIsShowCreateOffers] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [editOfferDtls, setEditOfferDtls] = useState({});
  const [itemSize, setItemSize] = useState(170);
  const [dateList, setDateList] = useState({
    fromDate: { start: "", end: "" },
    toDate: { start: "", end: "" },
  });
  const [filterDtls, setFilterDtls] = useState(filterDtlsInitialValue);
  const { sendNotification } = useNotification();
  const [categories, setCategories] = useState(categoryList);

  const [updateBookingStatus] = useUpdateBookingStatusMutation();

  /**
   * To handler fetch offers list
   */
  const handlerFetchOffers = async (noFilter, query, status) => {
    try {
      startLoading();
      let filter = {};
      if (noFilter) {
      } else {
        filter = { ...filterDtls };
        // filter.fromDate = dateList[0] ? new Date(dateList[0]) : "";
        // filter.toDate = dateList[1] ? new Date(dateList[1]) : "";
        // filter.date = dateList;
        if (dateList?.fromDate?.start || dateList?.fromDate?.start) {
          filter.fromDate = dateList?.fromDate;
        }
        if (dateList?.toDate?.start || dateList?.toDate?.start) {
          filter.toDate = dateList?.toDate;
        }
        filter.isCompany = userDtls.type === "company" ? true : false;
        filter.categories = filterDtls?.categories?.map((ele) =>
          ele?.value.replace("&", "-")
        );
        if (location?.locationName?.length > 0) {
          filter.location = filterLocation;
        }
        if (isCompany) {
          filter.influencerId = filterDtls?.name?.userId;
        } else {
          filter.businessId = filterDtls?.name?.userId;
        }
        filter = Object.fromEntries(
          Object.entries(filter).filter(
            ([key, value]) =>
              value !== null &&
              value !== undefined &&
              value !== "" &&
              !(Array.isArray(value) && value.length === 0)
          )
        );
      }
      if (query?.length > 0) filter.search = query;

      if (userDtls?.type === "influencer") {
        filter.influencerId = userDtls?.userId;
      }
      if (userDtls?.type !== "admin") {
        filter.businessId = userDtls?.userId;
        filter.isCompany = userDtls.type === "company";
      }

      filter.status = status || companyOfferFilter || "active";

      const response = await getOffers({
        id: userDtls?.userId,
        filter,
      }).unwrap();
      if (response?.data) {
        console.log("offer List:", response?.data);
        setOffersList(response?.data);
      } else {
        setOffersList([]);
        toaster("info", response?.message);
      }
    } catch (err) {
      setOffersList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };

  /**
   * To fetch company or influencer dtls
   */
  const fetchCompanyOrInfluencerDtls = async () => {
    try {
      startLoading();
      const resp = await getInfluencerOrCompanyList(
        isCompany ? "influencer" : "company"
      );
      if (resp?.data?.data?.length) {
        setNameList(resp?.data?.data || []);
      } else {
        setNameList([]);
      }
    } catch (err) {
      setNameList([]);
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to  fetch name list:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    handlerFetchOffers(true);
    fetchCompanyOrInfluencerDtls();
  }, [userDtls]);

  // Dynamically calculate item size based on screen width
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      // Adjust item size based on the screen width
      if (screenWidth < 768) {
        setItemSize(220); // Smaller item size for mobile screens
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setItemSize(230); // Medium size for tablets
      } else {
        setItemSize(120); // Larger size for desktops
      }
    };

    // Add resize event listener to handle screen resize
    window.addEventListener("resize", handleResize);

    // Set initial item size on mount
    handleResize();

    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /**
   * To edit the offer
   */
  const handlerEditOffer = (dtls) => {
    // e?.defaultPrevented();
    // e?.stopPropagation();
    setOfferType("Edit");
    setIsShowCreateOffers(true);
    setEditOfferDtls({ ...dtls });
    // set;
  };
  /**
   * To duplicate the offer
   */
  const handlerDuplicateOffer = (dtls) => {
    setOfferType("Duplicate");
    setIsShowCreateOffers(true);
    setEditOfferDtls({ ...dtls });
  };
  /**
   * To handle set tab view
   * @param (tabName)
   * @returns null
   */
  const handleSetOffersTab = (event, tabName) => {
    dispatch(setCompanyOfferFilter(tabName));
    setQuery("");
    handlerFetchOffers("", "", tabName);
  };

  /**
   * To handle the tab filter
   * @param (tabName)
   * @returns null
   */
  // const handleTabFilter = (filterCode) => {
  //   setTabFilter(filterCode);
  // };

  /**
   * To handle the offers card tab
   * @param (tabName)
   * @returns null
   */
  const handleOffersCardTab = (event, tabName) => {
    setOffersCardTab(tabName);
  };

  /**
   * To handle the tab card filter
   * @param (tabName)
   * @returns null
   */
  const handlerTabCardFilter = (filterCode) => {
    setTabCardFilter(filterCode);
  };

  /**
   * To handle the offer card view
   * @param (data)
   * @returns null
   */
  const handleOffer = (data) => {
    navigate(`/offers/${data?._id}`);
  };

  /**
   * To handle close create offer modal
   */
  const handlerCloseCreateOfferModal = (bool) => {
    setIsShowCreateOffers(bool);
    if (bool) setEditOfferDtls({});
  };
  const handleOpen = () => {
    setIsShowFilter(true);
  };
  const handlerCloseFilterModal = () => {
    setIsShowFilter(false);
  };
  const handleClearFilters = () => {
    setFilterDtls({ ...filterDtlsInitialValue });
    setDateList({
      fromDate: { start: "", end: "" },
      toDate: { start: "", end: "" },
    });
    setLocation({});
    setFilterLocation({});
    // setSearchQuery("");
    // setSelectedTypes([]);
    // setSelectedOptions([]); // Clear location
    // setValue([null, null]); // Clear date range
    handlerFetchOffers(true);
  };

  /**
   * To handle date onchange
   */
  const handlerDateOnchange = (key1, key2, value) => {
    setDateList((prev) => {
      return { ...prev, [key1]: { ...prev[key1], [key2]: value } };
    });
  };

  /**
   * To handle changes in input field
   */
  const handlerOnchange = (key1, value) => {
    if (key1 === "categories") {
      setCategories(
        categories?.map((ele) => {
          if (ele?.id === value?.id) {
            ele.isSelected = !ele.isSelected;
          }
          return ele;
        })
      );
    }
    if (key1 === "location") {
      setFilterDtls({ ...filterDtls, location: value });
      return;
    }
    setFilterDtls({
      ...filterDtls,
      [key1]: Array.isArray(filterDtls[key1])
        ? checkDuplicate(filterDtls[key1], value)
        : value?.value || value || "",
    });
  };
  /**
   * for virtual scroll
   * */
  const VirtualizedCompanyOffersList = ({
    offersList,
    companyOfferFilter,
    handleOffer,
    handlerEditOffer,
    handlerDuplicateOffer,
    userDtls,
  }) => {
    // Filter the offers based on the selected tab
    const filteredOffersList = offersList?.filter(
      (ele) => ele?.status === companyOfferFilter
    );
    const renderRow = ({ index, style }) => {
      const ele = filteredOffersList[index];
      return (
        <div style={style}>
          <CompanyOffersList
            data={ele}
            handleOffer={handleOffer}
            handlerEditOffer={handlerEditOffer}
            handlerDuplicateOffer={handlerDuplicateOffer}
            isAdmin={userDtls?.type === "admin"}
          />
        </div>
      );
    };
    return (
      <List
        height={700} // The height of the virtualized list container
        itemCount={filteredOffersList.length} // Total number of items after filtering
        itemSize={itemSize} // Adjust based on the height of each item //! here need to set the phone view
        width={"100%"} // Width of the list
      >
        {renderRow}
      </List>
    );
  };

  const checkDuplicate = (array, obj) => {
    // Array.isArray(array) ? array : arra``
    const index = array?.findIndex((item) => item?.id === obj?.id);
    if (index !== -1) {
      return array?.filter((item) => item?.id !== obj?.id);
    } else {
      return [...array, obj];
    }
  };
  /**
   * Header view
   * @returns Jsx element
   */
  const Header = () => {
    return (
      <Box
        className="c-offer-header"
        display="flex"
        alignItems={"center"}
        height={"56px"}
        sx={{ padding: 5, margin: "5 10 5 10" }}
      >
        {cardSelected && (
          <MyButton
            type="backButton"
            onClick={() => {
              setSelectedData({});
              setCardSelected(false);
            }}
          />
        )}
        {/* <Typography
          className="CardTitle"
          fontWeight="800"
          variant="h4"
          color="gray"
        >
          {cardSelected ? selectedData?.title : "Offers"}
        </Typography> */}
        {cardSelected ? (
          <>
            <MyButton
              className="btn duteDate"
              variant="outlined"
              sx={{ height: "32px", boxShadow: 0 }}
              color="gray"
              startIcon={<TodayIcon color="primary" />}
            >
              <Typography fontWeight="700" variant="body1" color="primary">
                {`Due ${formatDate(
                  selectedData?.availableDates?.from,
                  "DD/MM"
                )}-${formatDate(selectedData?.availableDates?.to, "DD/MM")}`}
              </Typography>
            </MyButton>
            <IconButton
              sx={{ height: "32px" }}
              color="gray"
              className="moreVertIcon btn"
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ) : (
          <>
            {userDtls?.type !== "admin" && (
              <MyButton
                onClick={() => {
                  setOfferType("Create");
                  setIsShowCreateOffers(true);
                }}
                className="addIcon btn abs"
                color="secondary"
                sx={{
                  borderRadius: 4,
                  fontWeight: 700,
                  border: 1,
                  borderColor: "secondary",
                }}
                startIcon={<AddIcon margin={0} sx={{ margin: "0px" }} />}
              >
                <span className="checkIcon-text">Create offers</span>
              </MyButton>
            )}
          </>
        )}
      </Box>
    );
  };
  const getChildren = (data) => {
    const filterCode = false;
    return (
      <>
        {/* <Typography
          display={"flex"}
          className={"textEllipsis mobile-WidthFitContent text-userName "}
          width={"140px"}
          height={32}
          color={"text.main"}
          fontWeight="500"
          fontSize={"12px"}
          variant="body1"
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={20}
          // bgcolor={"#f4f3f6"}
        >
          {` By ${
            data?.createdBy[0]?.username ||
            data?.companyDetails?.username ||
            "Company "
          }`}
        </Typography> */}

        {companyOfferFilter !== "past" && (
          <Box className="c-offerSection1">
            {companyOfferFilter !== "past" && (
              <Typography
                display={"flex"}
                variant="body1"
                fontSize={14}
                fontWeight={700}
                color="text.secondary"
                height={"32px"}
                width={"89px"}
                alignItems={"center"}
                justifyContent={"center"}
                border={1}
                borderColor={"#CAC4D0"}
                borderRadius={20}
                gridArea={"2 / 2"}
              >
                {`${formatDate(
                  data?.availableDates?.from,
                  "DD/MM"
                )}-${formatDate(data?.availableDates?.to, "DD/MM")}`}
              </Typography>
            )}
            {companyOfferFilter === "active" && (
              <>
                <Typography
                  // className="influencerBooking"
                  padding={"6px 12px"}
                  display={"flex"}
                  variant="body1"
                  fontSize={14}
                  fontWeight={500}
                  color="text.secondary"
                  height={"32px"}
                  maxWidth={"97px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius={20}
                  bgcolor={
                    (data?.spots?.booked === 5) &
                      (data?.spots?.availableSpots === 5)
                      ? "#fed7ad"
                      : data?.spots?.booked
                        ? "#feedad"
                        : "rgba(176, 220, 255, 0.50)"
                  }
                >
                  {`${data?.spots?.booked}/${data?.spots?.availableSpots} `}
                  <span style={{ marginLeft: "2px" }}>booked</span>
                </Typography>
                <IconButton
                  className="BookMarkIcon"
                  sx={{ height: "32px", position: "unset", p: 0 }}
                // onClick={(e) => handlerSaveOffer(e, data)}
                >
                  {filterCode === "saved" || data?.isOfferSaved ? (
                    <SvgIcon
                      viewBox="0 0 34 34"
                      component={SavedBookMarkIcon}
                      sx={{ mr: 1, width: "34px", height: "34px" }}
                    />
                  ) : (
                    // <img src={bookmarkIcon} style={{ color: "white" }} alt="loading" />
                    <SvgIcon
                      onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        handlerEditOffer(data);
                      }}
                      viewBox="0 0 24 24"
                      component={EditIcon}
                      sx={{ mr: 2, ml: 1, width: "24px", height: "24px" }}
                    />
                  )}
                </IconButton>

                {filterCode === "saved" || data?.isOfferSaved ? (
                  <IconButton
                    className="BookMarkIcon"
                    sx={{
                      height: "32px",
                      position: "unset",
                      p: 0,
                      ml: { xs: "auto", sm: "0" },
                    }}
                  // onClick={(e) => handlerSaveOffer(e, data)}
                  >
                    <SvgIcon
                      viewBox="0 0 34 34"
                      component={SavedBookMarkIcon}
                      sx={{ mr: 1, width: "34px", height: "34px" }}
                    />
                  </IconButton>
                ) : (
                  // <img src={bookmarkIcon} style={{ color: "white" }} alt="loading" />
                  <IconButton
                    className=""
                    sx={{
                      height: "32px",
                      position: "unset",
                      p: 0,
                      ml: { xs: "auto", md: "0" },
                    }}
                  // onClick={(e) => handlerSaveOffer(e, data)}
                  >
                    <SvgIcon
                      onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        handlerDuplicateOffer(data);
                      }}
                      viewBox="0 0 24 24"
                      component={ContentCopyOutlinedIcon}
                      sx={{ mr: 2, ml: 1, width: "24px", height: "24px" }}
                    />
                  </IconButton>
                )}
              </>
            )}
            {companyOfferFilter === "draft" && (
              <Box width={"fitContent"} display={"flex"} alignItems={"center"}>
                <materialIcons.clockIcon sx={{ color: "#c0bdc6" }} />
                <Typography
                  // className="influencerBooking"
                  padding={"6px 12px"}
                  display={"flex"}
                  variant="body1"
                  fontSize={16}
                  fontWeight={400}
                  color="text.secondary"
                  height={"32px"}
                  maxWidth={"97px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  Draft
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {companyOfferFilter === "past" && (
          <Typography
            // className="influencerBooking"
            padding={"10px 24px"}
            display={"flex"}
            variant="body1"
            fontSize={14}
            fontWeight={400}
            color="#6FCF97"
            height={"32px"}
            // maxWidth={"97px"}
            width={"fitContent"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={
              new Date(data?.availableDates?.to) < currentData
                ? "#D3F5E1"
                : "white"
            }
            borderRadius={"100px"}
            sx={{
              ml: { xs: "8px", md: "auto" }, // Apply `ml: 'auto'` only on medium screens and up
              mr: 1,
            }}
          >
            {new Date(data?.availableDates?.to) < currentData
              ? "Completed"
              : formatDate(data?.availableDates?.to, "DD/MM/YYYY")}
          </Typography>
        )}
        {/* <MyButton
          // onClick={(e) => handlerUpdateBookingStatus(e, data)}
          className="checkIcon btn list-btn updateStatusBtn"
          color={offerStatusCode[data?.offerStatus]?.bg}
          sx={{
            borderRadius: "50px",
            fontWeight: 600,
            fontSize: "16px",
            height: "52px",
            width: "124px",
            bgcolor: "#e9d8ea",
            boxShadow: 0,
          }}
          minWidth={"40px"}
          disabled={!(data?.offerStatus === "accept")}
        >
          <Box m={"8px 5px 0 0"}>
            {data?.offerStatus === "accept" ? (
              <CheckIcon sx={{ color: "primary.main" }} />
            ) : data?.offerStatus === "pending" ? (
              <materialIcons.clockIcon />
            ) : (
              <img alt="loading" src={completedIcon} className="mr-auto "></img>
            )}
          </Box>
          <span className="hideOnMobile textColor">
            {data?.offerStatus || "test"}
          </span>
        </MyButton> */}
      </>
    );
  };

  /**
   * To view offer list
   * @returns Jsx element
   */
  const OffersListContainer = () => {
    return (
      <TabContext
        value={companyOfferFilter}
        sx={{ width: "100%" }}
      // className="booking-list"
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            m: "8px 49px ",
            border: 0,
            height: 52,
          }}
        >
          <TabList
            onChange={handleSetOffersTab}
            aria-label="lab API tabs example"
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                fontWeight: companyOfferFilter === "active" ? "bold" : "400",
                paddingX: "12px",
                paddingY: "10px",
              }}
              fontWeight={700}
              label="Active"
              value="active"
              color="white"
              style={{
                color: "var(--Schemes-On-Secondary-Container, #1D192B)",
              }}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                fontWeight: companyOfferFilter === "draft" ? "bold" : "400",
                paddingX: "12px",
                paddingY: "10px",
              }}
              label="Drafts"
              value="draft"
              style={{
                color: "var(--Schemes-On-Secondary-Container, #1D192B)",
              }}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                fontWeight: companyOfferFilter === "past" ? "bold" : "400",
                paddingX: "12px",
                paddingY: "10px",
              }}
              label="Completed"
              value="past"
              style={{
                color: "var(--Schemes-On-Secondary-Container, #1D192B)",
              }}
            />
            {userDtls?.type !== "admin" && (
              <MyButton
                onClick={() => {
                  setOfferType("Create");
                  setIsShowCreateOffers(true);
                }}
                className="addIcon btn hideOnMobile"
                bgcolor={"dark.main"}
                sx={{
                  borderRadius: "100px",
                  fontWeight: 700,
                  ml: "auto",
                  // border: 1,
                  // borderColor: "secondary",
                  width: 122,
                  height: 52,
                  boxShadow: "none",
                  display: "flex",
                  gap: "8px",
                  backgroundColor: "rgba(143, 58, 152, 0.20)",
                }}
              >
                <PlusIcon />
                <Typography
                  fontWeight="600"
                  fontSize={"16px"}
                  variant="body1"
                  color="#8F3A98"
                >
                  Create
                </Typography>
              </MyButton>
            )}
          </TabList>
        </Box>
        {/* <Box
          className="tab-view"
          margin={"5px"}
          display={"flex"}
          sx={{ gap: "5px" }}
        >
          <MyButton
            onClick={() => handleTabFilter("all")}
            className="checkIcon btn"
            color={`${tabFilter === "all" ? "secondary" : "white"}`}
            sx={{
              borderRadius: 1,
              height: "32px",
              boxShadow: "none",
              border: 1,
              borderColor: "#49454f",
            }}
            startIcon={<DoneIcon />}
          >
            All
          </MyButton>
          <MyButton
            onClick={() => handleTabFilter("intrusted")}
            className="checkIcon btn"
            color={`${tabFilter === "intrusted" ? "secondary" : "white"}`}
            sx={{
              borderRadius: 1,
              height: "32px",
              boxShadow: "none",
              border: 1,
              borderColor: "#49454f",
            }}
          >
            Interested
            <Badge
              sx={{ marginLeft: 2 }}
              badgeContent={99}
              color="warning"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            ></Badge>
          </MyButton>
          <MyButton
            onClick={() => handleTabFilter("updates")}
            className="checkIcon btn"
            color={`${tabFilter === "updates" ? "secondary" : "white"}`}
            sx={{
              borderRadius: 1,
              height: "32px",
              boxShadow: "none",
              border: 1,
              borderColor: "#49454f",
            }}
          >
            Updates
            <Badge
              sx={{ marginLeft: 2 }}
              badgeContent={99}
              color="warning"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            ></Badge>
          </MyButton>
        </Box> */}
        <div className="c-offerCard">
          {/* <> */}
          {offersList?.length > 0 ? (
            <>
              {/* <VirtualizedCompanyOffersList
                offersList={offersList}
                companyOfferFilter={companyOfferFilter}
                handleOffer={handleOffer}
                handlerEditOffer={handlerEditOffer}
                handlerDuplicateOffer={handlerDuplicateOffer}
                userDtls={userDtls}
              /> */}
              {offersList
                ?.filter((ele) => ele?.status === companyOfferFilter)
                .map((ele, index) => {
                  return (
                    // <CompanyOffersList
                    //   key={index}
                    //   data={ele}
                    //   handleOffer={handleOffer}
                    //   handlerEditOffer={handlerEditOffer}
                    //   handlerDuplicateOffer={handlerDuplicateOffer}
                    //   isAdmin={userDtls?.type === "admin"}
                    // ></CompanyOffersList>
                    <InfluencerOffersList
                      data={ele}
                      style={{ overFlow: "auto" }}
                      // handleSelectOffersCard={handleSelectOffersCard}
                      module={"offer"}
                      // handlerUpdateBookingStatus={handlerUpdateBookingStatus}
                      handlerFetchOffers={handlerFetchOffers}
                      isSavedOffer={ele?.isOfferSaved || false}
                      children={getChildren(ele)}
                      className={"offerTitle"}
                      ctnClassName={"offerListView"}
                      height={92}
                    />
                  );
                })}
            </>
          ) : (
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography fontWeight="700" variant="body1" color="primary">
                No data found.
              </Typography>
            </Box>
          )}
          {/* </> */}
        </div>
        {userDtls?.type !== "admin" && (
          <MyButton
            onClick={() => {
              setOfferType("Create");
              setIsShowCreateOffers(true);
            }}
            className="addIcon btn hideOnWeb createOfferBtn"
            color="secondary"
            sx={{
              color: "white",
              fontWeight: 700,
              ml: "auto",
              // border: 1,
              // borderColor: "secondary",
              borderRadius: "16px",
              width: 56,
              bgcolor: "white.main",
              height: 56,
              boxShadow:
                "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 6px -1px rgba(0, 0, 0, 0.30), 0px 0px 4px -1px rgba(0, 0, 0, 0.06)",
              position: "absolute",
              bottom: 17,
              right: 12,
            }}
            startIcon={
              <AddIcon margin={0} sx={{ margin: "0px", color: "#8F3A98" }} />
            }
          ></MyButton>
        )}
      </TabContext>
    );
  };
  /**
   * To company handle the approve influencer request
   */
  const handlerApproveOrIgnoreRequest = async (userId, isApproved) => {
    try {
      startLoading();
      const payload = {
        offerId: selectedData?._id,
        newStatus: isApproved ? "offeredToInfluencer" : "rejectedOffers",
        influencerId: userId,
      };
      const response = await updateBookingStatus(payload).unwrap();
      if (response?.data) {
        fetchOfferById(selectedData?._id);
        sendNotification({
          senderId: userDtls?.userId,
          recipientId: userId,
          message: isApproved
            ? notificationCode?.offerAccepted
            : notificationCode?.offerIgnored,
          isRead: false,
          type: "remainder",
        });
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  /**
   * To fetch offer by id
   */
  const fetchOfferById = async (id) => {
    try {
      startLoading();
      const response = await getOfferById({ offerId: id }).unwrap();
      if (response?.data) {
        setSelectedData(response.data[0]);
      } else {
      }
    } catch (err) {
      if (err?.data?.message) {
        toaster("error", err?.data?.message);
      } else {
        console.error("Failed to Sign up:", err);
        toaster("error", "Something went wrong");
      }
    } finally {
      stopLoading();
    }
  };
  return (
    <div className="company-offer-ctn">
      {/* <Header /> */}
      {cardSelected ? (
        <>
          <TabContext
            value={offersCardTab}
            sx={{ padding: "5px", width: "100%" }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleOffersCardTab}
                aria-label="lab API tabs example"
                sx={{ textTransform: "capitalize" }}
              >
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  icon={<QueryStatsIcon />}
                  fontWeight={700}
                  label="Status"
                  value="status"
                  color="white"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  icon={<LocalOfferOutlinedIcon />}
                  label="Content"
                  value="content"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  icon={<ListIcon />}
                  label="Request"
                  value="request"
                />
              </TabList>
            </Box>
            <TabPanel
              // className="offers-card-tab"
              padding="5"
              sx={{ width: "100%" }}
              value="status"
            >
              <Box
                className="tab-view"
                margin={"5px"}
                display={"flex"}
                sx={{ gap: "5px" }}
              >
                <MyButton
                  onClick={() => handlerTabCardFilter("pending")}
                  className="checkIcon btn"
                  color={`${tabCardFilter === "pending" ? "secondary" : "white"
                    }`}
                  sx={{
                    borderRadius: 1,
                    height: "32px",
                    boxShadow: "none",
                  }}
                  startIcon={<DoneIcon />}
                >
                  Requested
                  <Badge sx={{ marginLeft: 2 }}>
                    <span className="badge">
                      {selectedData?.requestToBusiness?.length || ""}
                    </span>
                  </Badge>
                </MyButton>
                <MyButton
                  onClick={() => handlerTabCardFilter("approved")}
                  className="checkIcon btn"
                  color={`${tabCardFilter === "approved" ? "secondary" : "white"
                    }`}
                  sx={{
                    borderRadius: 1,
                    height: "32px",
                    boxShadow: "none",
                  }}
                >
                  Booked
                  <Badge sx={{ marginLeft: 2 }}>
                    <span className="badge">{`${selectedData?.spots?.booked}/${selectedData?.spots?.availableSpots}`}</span>
                  </Badge>
                </MyButton>
                <MyButton
                  onClick={() => handlerTabCardFilter("rejected")}
                  className="checkIcon btn"
                  color={`${tabCardFilter === "rejected" ? "secondary" : "white"
                    }`}
                  sx={{
                    borderRadius: 1,
                    height: "32px",
                    boxShadow: "none",
                  }}
                // startIcon={<DoneIcon />}
                >
                  Rejected
                </MyButton>
              </Box>

              <div className="card-status">
                {selectedData[offerFilterCode[tabCardFilter]]?.map(
                  (ele, index) => {
                    return (
                      <Box
                        className="btn action-btn bg-color "
                        color="light2"
                        // border={"1px solid"}
                        // borderColor={"gray"}
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{
                          color: "secondary",
                          // height: "64px",
                          padding: "8px",
                          border: "1px",
                          // borderColor: "primary",
                          borderRadius: "7px",
                          margin: "5px",
                          // maxWidth: "380px",
                          // width: "380px",
                        }}
                      >
                        {/* <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={" space-between"}
                    className="profileBtn bg-color"
                  > */}
                        <Stack
                          direction={"row"}
                          marginBottom={2}
                          sx={{
                            gap: "7px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          gap={2}
                        >
                          <img
                            className="imageIcon"
                            src={influencerCardIcon}
                            alt="loading"
                          ></img>
                          <Stack
                            direction={"row"}
                            gap={2}
                            alignItems={"center"}
                            i
                          >
                            <Typography variant="body1" fontWeight=" 700">
                              {ele?.username}
                            </Typography>
                            <Typography
                              variant="body2"
                              fontWeight=" 500"
                              color={"gray.main"}
                            >
                              {ele?.email}
                            </Typography>
                          </Stack>
                        </Stack>
                        {/* <Stack>
                      {index % 2 === 0 && (
                        <MyButton
                          color="white"
                          sx={{
                            height: "32px",
                            padding: "8px",
                            boxShadow: "none",
                          }}
                          startIcon={<GroupAddIcon />}
                        >
                          {2}
                        </MyButton>
                      )}
                    </Stack> */}
                        <Stack
                          gap={2}
                          direction={"row"}
                          marginBottom={2}
                          justifyContent={"center"}
                        >
                          {tabCardFilter === "pending" && (
                            <MyButton
                              // disabled={selectedData?.rejectedOffers?.find(
                              //   (item) => item?.userId === ele?.userId
                              // )}
                              onClick={() =>
                                handlerApproveOrIgnoreRequest(ele?.userId)
                              }
                              sx={{
                                height: "48px",
                                boxShadow: 0,
                                borderRadius: 7,
                                width: "100%",
                              }}
                              color="white"
                              startIcon={<VisibilityOffIcon color="primary" />}
                            // className="btn"
                            >
                              {selectedData?.rejectedOffers?.find(
                                (item) => item?.userId === ele?.userId
                              )
                                ? "Request ignored"
                                : "Ignore"}
                            </MyButton>
                          )}
                          {/* {!selectedData?.rejectedOffers?.find(
                            (item) => item?.userId === ele?.userId
                          ) && ( */}
                          <MyButton
                            disabled={tabCardFilter === "approved"}
                            onClick={() =>
                              handlerApproveOrIgnoreRequest(ele?.userId, true)
                            }
                            className="checkIcon btn"
                            variant="outlined"
                            color="black"
                            sx={{
                              borderRadius: 7,
                              border: "none",
                              fontWeight: 700,
                              height: "40px",
                              boxShadow: "none",
                              width: "100%",
                              // borderColor: "secondary",
                              bgcolor:
                                tabCardFilter === "approved"
                                  ? "green.main"
                                  : "light.main",
                            }}
                            startIcon={<CheckIcon />}
                          >
                            {tabCardFilter === "approved"
                              ? `Check-in :${formatDate(
                                selectedData?.availableDates?.to,
                                "DD/MM"
                              )}`
                              : "Approve request"}
                          </MyButton>
                          {/* )} */}
                        </Stack>
                        {/* </Box> */}
                      </Box>
                    );
                  }
                )}
                {selectedData[offerFilterCode[tabCardFilter]]?.length === 0 && (
                  <Box
                    width={"100%"}
                    height={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontWeight="700"
                      variant="body1"
                      color="primary"
                    >
                      No data found.
                    </Typography>
                  </Box>
                )}
              </div>
            </TabPanel>
            <TabPanel
              className="c-offers-content"
              sx={{ width: "100%" }}
              value="content"
            >
              <OfferDetails offerId={selectedData?._id} />
            </TabPanel>
            <TabPanel
              sx={{
                width: "100%",
                height: "calc(100vh - 132px)",
                overflow: "hidden auto",
              }}
              value="request"
            >
              <CompanyOffersRequest data={selectedData} />
            </TabPanel>
          </TabContext>
        </>
      ) : (
        <>
          {/* <Box
            display={"flex"}
            alignItems={"end"}
            justifyContent={"end"}
            width={"100%"}
            padding={"8px"}
            paddingTop={"18px"}
          > */}
            {/* <SearchBar
              setQuery={setQuery}
              query={query}
              handlerFetchOffers={handlerFetchOffers}
              placeholder="search offer"
            /> */}
            {/* <TuneIcon
              onClick={() => handleOpen()}
              style={{
                cursor: "pointer",
              }}
            /> */}
            {/* {userDtls?.type !== "admin" && (
              <MyButton
                onClick={() => {
                  setOfferType("Create");
                  setIsShowCreateOffers(true);
                }}
                className="addIcon btn"
                color="secondary"
                sx={{
                  borderRadius: "100px",
                  fontWeight: 700,
                  // border: 1,
                  // borderColor: "secondary",
                  width:122,
                  height:52
                }}
                startIcon={<AddIcon margin={0} sx={{ margin: "0px", color:"#8F3A98" }} />}
              >
                 <Typography fontWeight="700"  fontSize={"16px"} variant="body1" color="#8F3A98">Create</Typography>
              </MyButton>
            )} */}
          {/* </Box> */}
          <OffersListContainer />
        </>
      )}
      {isShowCreateOffers && (
        <CreateOffers
          offerType={offerType}
          editOfferDtls={{ ...editOfferDtls }}
          open={isShowCreateOffers}
          setOpen={handlerCloseCreateOfferModal}
          handlerFetchOffers={handlerFetchOffers}
        />
      )}

      {/* Filter modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isShowFilter}
        onClose={handlerCloseFilterModal}
        closeAfterTransition
      >
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          overflow={"auto"}
        >
          <Box
            className="filter-modal"
            sx={{
              width: "588px",
              // position: "relative",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
              bgcolor: "#ece6f0",
              // border: "2px solid #e8def8",
              // borderRadius: "15px",
              boxShadow: 24,
              p: "5px 12px",
            }}
          >
            <Stack direction="row" justifyContent="space-between" mb={4}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                fontSize={"24px"}
                fontWeight={400}
              >
                Search Offers
              </Typography>
              <IconButton color="primary" onClick={handlerCloseFilterModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              <Stack width={"100%"} direction="row" alignItems={"center"}>
                <SvgIcon
                  component={OfferIcon}
                  sx={{ mr: 1, color: "primary.main" }}
                />
                <Typography
                  width={"250px"}
                  maxWidth={"500px"}
                  color={"text.main"}
                  fontWeight="700"
                  fontSize={"18px"}
                  variant="body1"
                >
                  Type
                </Typography>
              </Stack>
              <Box
                height={"244px"}
                width={"100%"}
                overflow={"auto"}
                display={"flex"}
                flexWrap={"wrap"}
                gap={1}
              >
                {categories?.map((ele, index) => {
                  return (
                    <Box
                      height={"52px"}
                      display={"flex"}
                      alignItems={"center"}
                      onClick={() => handlerOnchange("categories", ele)}
                      className={
                        ele?.isSelected
                          ? "selectedCategory"
                          : "unselectedCategory"
                      }
                      color={"primary.main"}
                      borderRadius={"30px"}
                    >
                      {ele?.icon}
                      {/* <Chip
                      key={index}
                      label={ele?.value}
                      sx={{ p: 0, bgcolor: "inherit" }}
                      p={0}
                      // clickable
                    /> */}
                      <Typography
                        width={"fitContent"}
                        color={"text.main"}
                        fontWeight="500"
                        fontSize={"16px"}
                        variant="body1"
                        mx={2}
                        sx={{ color: "primary" }}
                      >
                        {ele?.value}
                      </Typography>

                      {ele?.isSelected ? (
                        <RemoveIcon
                          className="hideOnMobile"
                          sx={{ width: 18, height: 18, mx: 1 }}
                        />
                      ) : (
                        <AddIcon
                          className="hideOnMobile"
                          sx={{ width: 18, height: 18, mx: 1 }}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Stack>
            {/* <Autocomplete
              sx={{ marginBottom: "15px" }}
              mb={"10px"}
              className="name-filter"
              // multiple
              id="tags-standard"
              options={nameList}
              getOptionLabel={(option) => option.username}
              value={filterDtls?.name || null}
              onChange={(event, newValue) => {
                handlerOnchange("name", newValue);
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box
                    marginLeft={1}
                    width={"30px"}
                    height={"30px"}
                    borderRadius={"50%"}
                    marginRight={1}
                  >
                    <img
                      src={option?.images[0] || defaultProfileImg}
                      alt="loading"
                      width={" 100%"}
                      height={" 100%"}
                    ></img>
                  </Box>
                  <Typography
                    marginLeft={2}
                    color={"dark.main"}
                    variant="body1"
                    fontWeight={500}
                  >
                    {option?.username}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="primary"
                  variant="standard"
                  label={isCompany ? "Company name" : "Influencer name"}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <>{params.InputProps.startAdornment}</>,
                  }}
                />
              )}
            /> */}
            <Stack>
              <Box display={"flex"} mt={1}>
                <CalenderIcon className="filterCalenderIcon" />
                <Typography
                  id="transition-modal-title"
                  variant="body1"
                  component="h2"
                  sx={{ fontWeight: "700", fontSize: "18px" }}
                >
                  Availability
                </Typography>
              </Box>

              {/* Date Range Buttons */}
              {/* <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  mb: 2,
                  padding: 1,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleSelectDateRange("today")}
                >
                  Today
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSelectDateRange("yesterday")}
                >
                  Yesterday
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSelectDateRange("lastWeek")}
                >
                  Last Week
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSelectDateRange("last15Days")}
                >
                  Last 15 Days
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSelectDateRange("lastMonth")}
                >
                  Last Month
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSelectDateRange("thisMonth")}
                >
                  This Month
                </Button>
              </Box> */}

              {/* From Date Section */}
              <Stack>
                {/* <Typography
                id="transition-modal-title"
                variant="body1"
                component="h2"
                mt={1}
              >
                From Date
              </Typography> */}
                {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={dateList?.fromDate?.start || null}
                  onChange={(e) => {
                    handlerDateOnchange("fromDate", "start", e);
                  }}
                      label="Start date..."
                    />
                    <DatePicker
                      value={
                    dateList?.fromDate?.end || dateList?.fromDate?.start || null
                      }
                      minDate={dayjs(new Date(dateList?.fromDate?.start)).add(
                        2,
                        "day"
                      )}
                      label="End date..."
                  onChange={(e) => {
                    handlerDateOnchange("fromDate", "end", e);
                  }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Stack> */}
                {/* <Typography
                id="transition-modal-title"
                variant="body1"
                component="h2"
                sx={{ marginTop: "5px" }}
              >
                To Date
              </Typography> */}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ flexDirection: "row" }}
                    >
                      <DatePicker
                        className="datePicker"
                        sx={{ minWidth: "136px", height: 40 }}
                        value={dateList?.toDate?.start || null}
                        onChange={(e) => {
                          handlerDateOnchange("toDate", "start", e);
                        }}
                        disablePast
                        label="Start date..."
                      />
                      <DatePicker
                        className="datePicker"
                        value={
                          dateList?.toDate?.end ||
                          dateList?.toDate?.start ||
                          null
                        }
                        minDate={dayjs(new Date(dateList?.toDate?.start)).add(
                          2,
                          "day"
                        )}
                        label="End date..."
                        onChange={(e) => {
                          handlerDateOnchange("toDate", "end", e);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack>
              </Stack>
            </Stack>

            <Box
              component="form"
              sx={{
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  height: "48px",
                  // borderBottom: "1px solid gray",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Box display={"flex"} mt={1} mb={2}>
                <LocationOnOutlinedIcon sx={{ color: "primary.main", mr: 1 }} />
                <Typography
                  id="transition-modal-title"
                  variant="body1"
                  component="h2"
                  sx={{ fontWeight: "700", fontSize: "18px" }}
                >
                  Place
                </Typography>
              </Box>
              <LocationAutoComplete
                height={"100%"}
                setLocation={setLocation}
                value={location}
                setFilterLocation={setFilterLocation}
              // placeholder={"Place"}
              />
            </Box>
            {/* <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1, width: "100%" } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="standard-basic"
                label="Preference"
                variant="standard"
              />
            </Box> */}
            <Stack
              direction="row"
              spacing={2}
              mt={3}
              justifyContent="space-between"
            >
              <MyButton
                sx={{
                  width: "50%",
                  borderRadius: "15px",
                  borderColor: "text.secondary",
                }}
                onClick={handleClearFilters}
              >
                Clear all
              </MyButton>
              <MyButton
                disabled={
                  !dateList?.fromDate?.start &&
                  !dateList?.toDate?.start &&
                  !filterDtls?.location?.length > 0 &&
                  !filterDtls?.categories?.length > 0 &&
                  !filterDtls?.name &&
                  !location?.locationName?.length > 0
                }
                onClick={() => {
                  handlerFetchOffers();
                  handlerCloseFilterModal();
                }}
                sx={{
                  width: "50%",
                  borderRadius: "15px",
                  borderColor: "text.secondary",
                }}
              >
                Search
              </MyButton>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default CompanyOffer;
