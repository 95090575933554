import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import InboxIcon from "@mui/icons-material/Inbox";
import TuneIcon from "@mui/icons-material/Tune";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import defaultProfileImg from "../../assets/image/defaultProfileImg.png";
import GroupIcon from "@mui/icons-material/Group";
import "./Sidebar.css";
import logosyme from "../../assets/image/logosyme.png";
import { Button, CardMedia, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import symeLogo from "../../assets/image/symeLogo1.png";
import {
  setClearUserDtls,
  setUserDetails,
} from "../../store/Slicers/authSlice";
import { useGetNotificationListMutation } from "../../services/apiService/userApiService";
import LogoutIcon from "@mui/icons-material/Logout";
import { setMessageCount } from "../../store/Slicers/messageSlice";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  HomeIcon,
  OfferIcon,
  BookMarkIcon,
  SavedBookMarkIcon,
  BookingIcon,
  MessageIcon,
  SettingsIcon,
  StickyNoteIcon,
  TermsAndConditionsIcon
} from "../../utils/materialIcon";
import HelpCenterOutlinedIcon from "../../assets/image/help.jpg";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const drawerWidth = 360;
const drawerWidthTablet = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

/**
 * @returns open or close
 * */
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  [theme.breakpoints.down("md")]: {
    width: drawerWidthTablet,
    "& .MuiDrawer-paper": {
      width: drawerWidthTablet,
    },
  },
}));

const Sidebar = ({ open, handleDrawerToggle }) => {
  const dispatch = useDispatch(); //here used to dispatch the notification unread message and logout
  const navigate = useNavigate(); //navigate to the path of the sidebar
  const location = useLocation(); //for highlight the path
  const [isReadList, setIsReadList] = React.useState([]); // get notification list
  const { messageCount } = useSelector((state) => state?.message); //get message count from redux
  const { userDtls } = useSelector((state) => state?.auth); //get user details from redux
  const [getNotification] = useGetNotificationListMutation(); //api for get notification

  /**
   * logout the user
   * @returns empty
   * */
  const handleLogout = () => {
    dispatch(setClearUserDtls());
  };
  /**
   * navigate to the path
   * @returns path
   * */
  const handleNavigation = (path) => {
    navigate(path);
  };

  /**
   * api call for get notification
   * @returns data
   * */
  const notificationData = async () => {
    try {
      const response = await getNotification({
        id: userDtls?.userId,
        // id:'3256686d-fdcc-4a45-b2ec-4471f13a6d92',   //for testing
        filter: { sort: "createdAt" },
      }).unwrap();

      if (response?.data?.length) {
        const unreadMessages = response?.data?.filter(
          (message) => !message.isRead
        );
        dispatch(setMessageCount(unreadMessages?.length || ""));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // call the notification funtion api
  useEffect(() => {
    notificationData();
  }, []);

  return (
    <Box sx={{ display: "flex", bgcolor: "#65558fad" }}>
      <CssBaseline />
      <Drawer
        className="side-bar-for-web"
        variant="permanent"
        open={open}
        sx={{
          color: "black.main",
          bgcolor: "white", // Replace with your desired background color
          "& .MuiDrawer-paper": {
            bgcolor: "white", // Ensure the paper inside the drawer also has the same background color
            border: "0px",
            boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
            maxWidth: "360px"
          },
        }}
      >
        {/* header icon for shirnk */}
        <DrawerHeader
          sx={{
            bgcolor: "secondary.soft",
            color: "white",
            width: "330px",
            height: "108px",
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        // onClick={handleDrawerToggle}
        // className="toggle-bar"
        >
          {/* <IconButton>
            {open ? (
              <div className="icon-syme">
                <img src={logosyme} alt="SYME" className="logo-img" />
                <Typography sx={{ color: "#ffffff" }}>SYME</Typography>
              </div>
            ) : (
              <MenuIcon />
            )}
          </IconButton> */}
          <Box
            height={"84px"}
            width={"312px"}
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            gap={1}
            sx={{
              boxShadow:
                "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
              borderRadius: "16px",
            }}
            onClick={() => navigate("/profile")}
          >
            <CardMedia
              className="test"
              component="img"
              alt="green iguana"
              height={"60px"}
              width={"60px"}
              image={userDtls?.profileImage || defaultProfileImg}
              sx={{
                width: "60px",
                borderRadius: "50%",
                minWidth: "60px",
                ml: 2,
              }}
            />
            <Typography
              display={"flex"}
              className={"textEllipsis mobile-WidthFitContent"}
              // width={"140px"}
              height={20}
              color={"#4A4459"}
              fontWeight="600"
              fontSize={"14px"}
              variant="body1"
              alignItems={"center"}
              justifyContent={"center"}
            >
              {`@${userDtls?.firstName}${userDtls?.lastName}`}
            </Typography>
          </Box>
        </DrawerHeader>

        {/* <Divider sx={{ background: "white" }} /> */}
        {/* sidebar choose path */}
        <List
          className="component-list"
          sx={{ color: "black.main", fontSize: "14px" }}
        >
          {[
            userDtls?.type === "influencer" && {
              text: "Home",
              icon: <HomeIcon />,
              path: "/home",
            },
            {
              text: "Offers",
              icon: <OfferIcon color="#625b71" />,
              path: "/offers",
            },
            {
              text: "Bookings",
              icon: <BookingIcon />,
              path: "/bookings",
            },
            {
              text: "Message",
              icon: <MessageIcon />,
              path: "/message",
              badge: messageCount || "",
            },
            // { text: "Settings", icon: <TuneIcon />, path: "/settings" },
            userDtls?.type === "admin" && {
              text: "Users",
              icon: (
                <GroupIcon sx={{ fill: "#CAC4D0", stroke: "currentcolor" }} />
              ),
              path: "/users",
            },
            userDtls?.type === "admin" && {
              text: "Reports",
              icon: (
                <StickyNoteIcon
                  sx={{ fill: "#CAC4D0", stroke: "currentcolor" }}
                />
              ),
              path: "/reports",
            },
          ]
            .filter((ele) => ele)
            .map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block", height: "56px", width: "80%" }}
              >
                <ListItemButton
                  className={`list-item-button ${location.pathname.includes(item?.path)
                    ? "active"
                    : "inactive"
                    }`}
                  onClick={() => handleNavigation(item.path)}
                >
                  <ListItemIcon
                    className="itemicon"
                    sx={{
                      // color: "#ffffff",
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                      paddingTop: `${item.text === "Home" ? "8px" : ""}`
                    }}
                  >
                    {item.icon}
                    {messageCount > 0 && (
                      <Badge
                        sx={{ marginLeft: 1 }}
                        badgeContent={item.badge}
                        color="warning"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      ></Badge>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>

        <Divider sx={{ background: "white", marginX: "20px" }} />

        {/* For logout and help, term is there  */}
        <List
          className="component-list"
          sx={{ color: "black.main", fontSize: "14px" }}
        >
          {[
            {
              text: "Settings",
              icon: <SettingsIcon />,
              path: "/settings",
            },
            {
              text: "Help & Support",
              icon: <HelpCenterOutlinedIcon />,
              path: "/help",
            },
            {
              text: "Terms & conditions",
              icon: <DescriptionOutlinedIcon />,
              path: "/Terms",
            },
          ]
            .filter((ele) => ele)
            .map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block", color: "white", height: "56px", width: "80%" }}
              >
                <ListItemButton
                  className={`list-item-button ${location.pathname.includes(item?.path)
                    ? "navActive active"
                    : "navInActive"
                    }`}
                  onClick={() => handleNavigation(item.path)}
                >
                  {item.text === "Help & Support" ?
                    <img
                      src={HelpCenterOutlinedIcon}
                      style={{
                        paddingRight: "15px"
                      }}
                    />
                    :
                    <>
                      <ListItemIcon
                        className="itemicon"
                        sx={{
                          // color: "#ffffff",
                          minWidth: 0,
                          mr: open ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                        {messageCount > 0 && (
                          <Badge
                            sx={{ marginLeft: 1 }}
                            badgeContent={item.badge}
                            color="warning"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          ></Badge>
                        )}
                      </ListItemIcon>
                    </>
                  }
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>

        {/* For sidebar bottom profile */}
        <Box
          width={"336px"}
          height={"56px"}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          onClick={handleLogout}
          sx={{ cursor: "pointer" }}
          mt={"auto"}
        >
          <LogoutOutlinedIcon color="#79747e" sx={{ ml: "22px" }} />
          <Typography
            display={"flex"}
            className={"textEllipsis mobile-WidthFitContent"}
            // width={"140px"}
            height={20}
            color={"#4A4459"}
            fontWeight="400"
            fontSize={"15px"}
            variant="body1"
            alignItems={"center"}
            justifyContent={"center"}
          >
            Log out
          </Typography>
        </Box>
      </Drawer>

      {/**
       *RENDER THIS WHEN MOBILE VIEW
       */}
      {!(location.pathname.includes('/offers/') && location.pathname.split('/offers/')[1]) && <div className="mobile-icons">
        {userDtls.type === "influencer" && (
          <IconButton
            onClick={() => handleNavigation("/home")}
            // sx={{ width: "80px" }}
            height={"100%"}
          >
            <Stack
              sx={{
                color: "#625B71",
                display: "flex",
                alignItems: "center",
                height: "30px",
                fontSize: "12px",
              }}
              className={
                location.pathname.includes("home")
                  ? "activeIcon"
                  : "inactiveIcon"
              }
            >
              <HomeIcon />
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ color: "#1D1B20" }}
              >
                Home
              </Typography>
            </Stack>
          </IconButton>
        )}
        {userDtls.type === "company" && (
          <IconButton
            onClick={() => handleNavigation("/bookings")}
          // sx={{ width: "80px" }}
          >
            <Stack
              sx={{
                color: "#625B71",
                display: "flex",
                alignItems: "center",
                height: "30px",
                fontSize: "12px",
              }}
              className={
                location.pathname.includes("bookings")
                  ? "activeIcon"
                  : "inactiveIcon"
              }
            >
              <BookingIcon />
              <Typography
                fontSize={14}
                fontWeight={location.pathname.includes("bookings") ? 700 : 400}
                sx={{ color: "#1D1B20" }}
              >
                Bookings
              </Typography>
            </Stack>
          </IconButton>
        )}
        <IconButton
          onClick={() => handleNavigation("/offers")}
          height={"100%"}
        // sx={{ width: "80px" }}
        >
          <Stack
            sx={{
              color: "#625B71",
              display: "flex",
              alignItems: "center",
              height: "30px",
              fontSize: "12px",
            }}
            className={
              location.pathname.includes("offers")
                ? "activeIcon"
                : "inactiveIcon"
            }
          >
            <OfferIcon color="#1D1B20" />
            <Typography
              fontSize={14}
              fontWeight={location.pathname.includes("offers") ? 700 : 400}
              sx={{ color: "#1D1B20", mb: 1 }}
            >
              Offers
            </Typography>
          </Stack>
        </IconButton>
        {userDtls.type !== "company" && (
          <IconButton
            onClick={() => handleNavigation("/bookings")}
          // sx={{ width: "80px" }}
          >
            <Stack
              sx={{
                color: "#625B71",
                display: "flex",
                alignItems: "center",
                height: "30px",
                fontSize: "12px",
              }}
              className={
                location.pathname.includes("bookings")
                  ? "activeIcon"
                  : "inactiveIcon"
              }
            >
              <BookingIcon />
              <Typography
                fontSize={14}
                fontWeight={location.pathname.includes("bookings") ? 700 : 400}
                sx={{ color: "#1D1B20" }}
              >
                Bookings
              </Typography>
            </Stack>
          </IconButton>
        )}
        <IconButton
          onClick={() => handleNavigation("/message")}
        // sx={{ width: "80px" }}
        >
          <Stack
            sx={{
              color: "#625B71",
              display: "flex",
              alignItems: "center",
              height: "30px",
              fontSize: "12px",
            }}
            className={
              location.pathname.includes("message")
                ? "activeIcon"
                : "inactiveIcon"
            }
          >
            {/* {messageCount && (
              <Badge
                sx={{ marginLeft: 2 }}
                badgeContent={messageCount}
                color="warning"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              ></Badge> */}
            {/* )} */}
            <MessageIcon />
            <Typography
              fontSize={14}
              fontWeight={location.pathname.includes("message") ? 700 : 400}
              sx={{ color: "#1D1B20" }}
            >
              Message
            </Typography>
          </Stack>
        </IconButton>
        <IconButton
          onClick={() => handleNavigation("/account")}
        // sx={{ width: "80px" }}
        >
          <Stack
            sx={{
              // color: "#ffffff",
              display: "flex",
              alignItems: "center",
              height: "31px",
              fontSize: "12px",
            }}
            className={
              location.pathname.includes("account")
                ? "activeIcon"
                : "inactiveIcon"
            }
          >
            <img
              alt="loading"
              src={symeLogo}
              style={{ width: 28, height: 25 }}
            ></img>
            <Typography
              fontSize={14}
              fontWeight={location.pathname.includes("account") ? 700 : 400}
              sx={{ color: "#1D1B20" }}
            >
              Account
            </Typography>
          </Stack>
        </IconButton>
      </div>}
    </Box>
  );
};

export default Sidebar;
